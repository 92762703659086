import React , {useState, useEffect, useContext} from 'react'
import Header from '../components/header/Header'
import UgesRapport from './UgesRapport'
import '../components/css/mobilReports.css'
import Dagens from './Dagens';
import Dagperiode from './Dagperiode'
import WorkPlan from './WorkPlan';
import { keyContext } from "../components/KeyContext";
import { useNavigate } from "react-router-dom";
import PeriodeSam from './PeriodeSam';


function PeriodeSamMobil() {
    const { open , isChecked} = useContext(keyContext)

    let key = sessionStorage.getItem("key");
    let orgID = sessionStorage.getItem("orgID");
    const navigate = useNavigate();
    const [rights, setRights] = useState([{}]);

    useEffect(() => {
        const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getaccessrights?Key=${key}&LoginNo=${orgID}`;
        if (key && orgID) {
          fetch(endpoint, {
            method: "GET",
            headers: {
              ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
            },
          })
            .then((res) => res.json())
            .then((result) => {
              // console.log(result)
              if (result.result === 0) {
                navigate("/");
              }
              setRights(result);
            });
        }
      }, [key, orgID]);
    
      const [pageKey, setPageKey] = React.useState("f");

      const Menu = (props) => {
        return (
          <div id="meu" className="has-scrollbar">
            <button
              style={{
                border: "none",
                background: "transparent",
                color: pageKey === "a" ? "#982f2f" : "black",
                marginBottom: 6 + 'px'
              }}
              onClick={() => {
                props.onClick("a");
              }}
            >
              Ugestatestik{" "}
            </button>
          </div>
        );
      };
    
      let page;
      switch (pageKey) {
        case "a":
          page = <UgesRapport />;
          break;
    
        case "c":
          page = <Dagperiode />;
          break;
    
        case "e":
          page = <Dagens />;
          break;

          case "f":
            page = <PeriodeSam />;
            break;

            case "x":
              page= <WorkPlan />
              break;
        default:
          page = "";
          break;
      }
    
      const DagsMenu = (props) => {
        return (
          <div>
            <button
              style={{
                border: "none",
                marginBottom: 6 + "px",
                background: "transparent",
                color: pageKey === "c" ? "#982f2f" : "black",
               
              }}
              onClick={() => {
                props.onClick("c");
              }}
            >
              {" "}
              Dagsperioder
            </button>
    
           
          </div>
        );
      };
      const DagensMenu = (props) => {
        return (
          <button
            style={{
              border: "none",
              marginBottom: 6 + "px",
             
              textAlign: "left",
              background: "transparent",
              color: pageKey === "e" ? "#982f2f" : "black",
            }}
            onClick={() => {
              props.onClick("e");
            }}
          >
            Dagens omsætning
          </button>
        );
      };
      const WorkplanMenu = (props) => {
        return (
          <button
            style={{
              border: "none",
              marginBottom: 6 + "px",
              textAlign: "left",
              background: "transparent",
              color: pageKey === "x" ? "#982f2f" : "black",
            }}
            onClick={() => {
              props.onClick("x");
            }}
          >
            Arbejdsplan
          </button>
        );
      };
      const PeriodeMenu = (props) => {
        return (
          <button
            style={{
              border: "none",
              marginBottom: 6 + "px",
              textAlign: "left",
              background: "transparent",
              color: pageKey === "f" ? "#982f2f" : "black",
            }}
            onClick={() => {
              props.onClick("f");
            }}
          >
            Periodesammenligninger
          </button>
        );
      };
  return (
    <div className='containers'>
        <Header isActive="active"/>
        <main className='rapport-mobil' style={{backgroundColor: open && isChecked === true ? 'rgba(0,0,0,0.5'  : '', display: isChecked === true ? 'block' : ''}}>
            <aside className='aside ugeaside' style={{display: isChecked === true ? 'none' : ''}}>
            {rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_COMPAREPERIODS")
              return (
                <div className="men" key={index}>
                  <PeriodeMenu onClick={setPageKey} />
                </div>
              );
          })}

            

        {rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_DAYPERIODS")
              return (
                <div className="men" key={index}>
                  <DagsMenu onClick={setPageKey} />
                </div>
              );
          })}
        {rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_DAYREVENUE")
              return (
                <div className="men" key={index}>
                  <DagensMenu onClick={setPageKey} />
                </div>
              );
          })}

{rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_WEEKSTAT")
              return (
                <div className="men" key={index}>
                  <Menu onClick={setPageKey} />
                </div>
              );
          })}


{rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_WORKPLAN")
              return (
                <div className="men" key={index}>
               
       <WorkplanMenu onClick={setPageKey} />
       </div>
      
       );
   })}
            </aside>
            <div className='rapporter' style={{backgroundColor: open && isChecked === false ? 'rgba(0,0,0,0.5'  : '' }}>
             {
              page ? page : <PeriodeSam propspageKey = "a"/>
             }
              

            </div>
             
        </main>
    </div>
  )
}

export default PeriodeSamMobil