import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import "../components/css/reports.css";
// import downArrow from "../images/arrow-down.svg";
import { Link } from "react-router-dom";
// import FooterMenu from "../components/FooterMenu/FooterMenu";
import { keyContext } from "../components/KeyContext";
import { useContext } from "react";
import Dagens from "./Dagens";
import UgesRapport from "./UgesRapport";
import Dagperiode from "./Dagperiode";
import PeriodeSam from "./PeriodeSam";
import WorkPlan from "./WorkPlan";
// import Test from "./Test";
import { useNavigate } from "react-router-dom";
// import BilletsalgPerFilm from "./BilletsalgPerFilm";
import AdminIncidents from "./AdminIncidents";
import SideMenus from "../components/SideMenus";

function Reports({ pageView }) {
  const { open } = useContext(keyContext);
  // const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [dagsIsActive, setDagsIsActive] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [dagsIsShown, setdagsIsShown] = useState(false);
  const [rights, setRights] = useState([{}]);
  let key = sessionStorage.getItem("key");
  let orgID = sessionStorage.getItem("orgID");

  //const [selectedMenu, setselectedMenu] = useState('');
  //const [noRights, setNoRights] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getaccessrights?Key=${key}&LoginNo=${orgID}`;
    if (key && orgID) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          if (result.result === 0) {
            //navigate("/");
          }
          setRights(result);
        });
    }
  }, [key, orgID]);

  const storedPageKey = sessionStorage.getItem("pageKey");
  // console.log(storedPageKey)

  function handlePageKey() {
    sessionStorage.setItem("pageKey", "a");
  }
  const [pageKey, setPageKey] = React.useState("x");

  useEffect(() => {
    rights &&
      rights.map((right, index) => {
        if (right.name === "ACC_DAYREVENUE") {
          sessionStorage.setItem("pageKey", pageKey);
        }
      });

    // sessionStorage.setItem('pageKey', pageKey)
  }, [pageKey]);

  useEffect(() => {
    rights &&
      rights.map((right, index) => {
        if (right.name === "ACC_DAYREVENUE") {
          return setPageKey(storedPageKey ? storedPageKey : "e");
        }
      });
  }, [rights]);

  const Menu = (props) => {
    return (
      <div id="meu" className="has-scrollbar">
        <button
          style={{
            border: "none",
            background: "transparent",
            color: pageKey === "a" ? "#982f2f" : "black",
            marginBottom: 6 + "px",
          }}
          onClick={() => {
            props.onClick("a");
          }}
        >
          Ugestatestik{" "}
        </button>
        {/* <button   style={{border:'none', marginBottom: 6 + 'px', background:'transparent', color: pageKey === 'b'  ? "#982f2f" : "black",  marginLeft:'8px'}} onClick={() => {props.onClick("b") }}>Billetsalg pr. forestilling</button>   */}
      </div>
    );
  };

  let page;
  switch (pageKey) {
    case "a":
      page = <UgesRapport />;
      break;

    case "b":
      page = <UgesRapport />;
      break;

    case "c":
      page = <Dagperiode />;
      break;

    case "d":
      page = <Dagperiode />;
      break;

    case "e":
      page = <Dagens />;
      break;

    case "f":
      page = <PeriodeSam />;
      break;

    case "x":
      page = <WorkPlan />;
      break;

    default:
      page =
        rights &&
        rights.map((right, index) => {
          if (right.name === "ACC_DAYREVENUE")
            return (
              <div className="men" key={index}>
                <Dagens />
              </div>
            );
        });

      break;
  }

  const DagsMenu = (props) => {
    return (
      <div>
        <button
          style={{
            border: "none",
            marginBottom: 6 + "px",
            background: "transparent",
            color: pageKey === "c" ? "#982f2f" : "black",
          }}
          onClick={() => {
            props.onClick("c");
          }}
        >
          {" "}
          Dagsperioder
        </button>

        {/* <button style={{border:'none',marginBottom: 6 + 'px',  background:'transparent' , color: pageKey === 'd'  ? "#982f2f" : "black", marginLeft:'8px'}} onClick={() => {props.onClick("d") } }> Dagsperioder pr. dag</button>  */}
      </div>
    );
  };

  const DagensMenu = (props) => {
    return (
      <button
        style={{
          border: "none",
          marginBottom: 6 + "px",
          textAlign: "left",
          background: "transparent",
          color: pageKey === "e" ? "#982f2f" : "black",
        }}
        onClick={() => {
          props.onClick("e");
        }}
      >
        Dagens omsætning
      </button>
    );
  };

  const PeriodeMenu = (props) => {
    return (
      <button
        style={{
          border: "none",
          marginBottom: 6 + "px",
          textAlign: "left",
          background: "transparent",
          color: pageKey === "f" ? "#982f2f" : "black",
        }}
        onClick={() => {
          props.onClick("f");
        }}
      >
        Periodesammenligninger
      </button>
    );
  };

  const WorkMenu = (props) => {
    return (
      <button
        style={{
          border: "none",
          marginBottom: 6 + "px",
          textAlign: "left",
          background: "transparent",
          color: pageKey === "x" ? "#982f2f" : "black",
        }}
        onClick={() => {
          props.onClick("x");
        }}
      >
        Arbejdsplan
      </button>
    );
  };

  return (
    <main className="containers">
      <Header isActive="active" />

      <div className="reports">
        <aside className="side-menu">
          
          {/* <Link to="/dagsperiode">gg</Link> */}
          {/* <Link
                            to="/rapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>
                              Ugestatestikrapporter
                            </p>{" "}
                          </Link>
                          <Link
                            to="/workplans"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>Arbejdsplan</p>{" "}
                          </Link>        */}
          {/* <SideMenus pages={page}/> */}

          {rights &&
            rights.map((right, index) => {
              if (right.name === "ACC_COMPAREPERIODS")
                return (
                  <div className="men" key={index}>
                    <PeriodeMenu onClick={setPageKey} />
                  </div>
                );
            })}

          {isShown && (
            <div className="side-submenu">
              <Menu onClick={setPageKey} />
            </div>
          )}

          {rights &&
            rights.map((right, index) => {
              if (right.name === "ACC_DAYPERIODS")
                return (
                  <div className="men" key={index}>
                    <DagsMenu onClick={setPageKey} />

                   
                  </div>
                );
            })}

          {dagsIsShown && (
            <div className="">
              <DagsMenu onClick={setPageKey} />
            </div>
          )}

          {rights &&
            rights.map((right, index) => {
              if (right.name === "ACC_DAYREVENUE")
                return (
                  <div className="men" key={index}>
                    <DagensMenu onClick={setPageKey} />
                  </div>
                );
            })}

          {rights &&
            rights.map((right, index) => {
              if (right.name === "ACC_WEEKSTAT")
                return (
                  <div className="men" key={index}>
                    <Menu onClick={setPageKey} />
                  </div>
                );
            })}

{rights &&
            rights.map((right, index) => {
              if (right.name === "ACC_WORKPLAN")
                return (
                  <div className="men" key={index}>
                   <WorkMenu onClick={setPageKey} />
                  </div>
                );
            })}
          
        </aside>

        <div
          className="main-menu"
          style={{ backgroundColor: open ? "rgba(0,0,0,0.5" : "" }}
        >
         
          {page}
         
        </div>

     
      </div>
    </main>
  );
}

export default Reports;
