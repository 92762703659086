import React, { useEffect, useState } from "react";
// INTERNAL IMPORTS
import "../components/css/dagens.css";
import '../components/css/calendar.css'

//EXTERNAL IMPORTS

//IMAGES 


import { keyContext } from "../components/KeyContext";

import moment from "moment";
import { useContext } from "react";
import DagenTable from "../components/DagenTable";
import { useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import calendar from "../images/Icon-Calendar.png";
import downArrow from "../images/arrow-down.svg";
import close from "../images/close.png";

import { format , isSameMonth } from "date-fns";
import { DayPicker } from "react-day-picker";
import { addMonths , addYears , subYears} from "date-fns";


import "react-day-picker/dist/style.css";
import da from "date-fns/locale/da";
import { weekdays } from "moment/moment";
import { weekdaysMin } from "moment/moment";


function Dagens() {

  const [film, setFilm] = useState([{}]);
  const key = sessionStorage.getItem("key");
  const [dagensFilm, setDagensFilm] = useState("");
  const [selected, setSelected] = useState(film);
// console.log(moment(new Date(), 1).format('yyyy'));
  const [selectedDate, setSelectedDate] = useState(new Date());
// console.log(moment(selectedDate).year() -  1)
  const [data, setData] = useState([]);

  const { dagensData, setDagensData } = useContext(keyContext);

  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const defaultFilmID = sessionStorage.getItem("default-filmID");

  

  

  const navigate = useNavigate();

  const today = new Date();
  const nextMonth = addMonths(new Date(), 1);
  const nextYear = addYears( new Date(), 1)
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState( nextYear );
  
  const [showCalendar, setShowCalendar] = useState(false);
  let theme = localStorage.getItem("theme")

  moment.updateLocale("da" , {
    weekdaysShort: ('Søn_Man_Tirs_Ons_Tors_Fre_Lør')

  });
 
  moment.updateLocale("da", {
    months:
      "Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December".split(
        "_"
      ),
  });
  moment.updateLocale("da", {
    weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
  });

  let footer;

  if (selectedDate) {
    footer = (
      <span style={{padding:0, paddingTop:-10,}}>
        {moment(selectedDate).locale("da").format("dddd")},{" "}
        <span style={{ paddingRight: 0, paddingLeft:10 }}>
          {format(selectedDate, "d. ")}{" "}
          {moment(selectedDate).locale("da").format("MMMM")}{" "}
          {format(selectedDate, "yyyy")}
        </span>
      </span>
    );
  }
// console.log(footer)
  function handleClick() {
    setShowCalendar(true);
  }

  function closeCalendar() {
    setShowCalendar(false);
   
  }

// console.log(dagensFilm)
  
  useEffect(() => {
    document.title = "Dagens omsætning - ebillet_admin";
  }, []);


  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getorganizers?Key=${key}`;
    if (key) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          if (result.result === 0) {
            //navigate("/");
          }
          setFilm(result);
          // if there is only bio then it will be selected by default
          if (result.length === 1) {
            sessionStorage.setItem("default-filmID", result[0].No);
          }
        });
    }
  }, [key]);

  const handleSelect = (e) => {
    let g = e.target.value;
    setDagensFilm(g.split(" ")[g.split(" ").length - 1]);
    setSelected(e.target.value);

    sessionStorage.setItem("dagensFilm", e.target.value);
  };

  let movies = sessionStorage.getItem("dagensFilm");
  // console.log(movies)
  const org = movies && movies.split(" ")[movies.split(" ").length - 1];

  const getNextDate = () => {
    setSelectedDate(moment(selectedDate).add(1,'days')._d)
};


const getPreviousDate = () => {
    setSelectedDate(moment(selectedDate).subtract(1,'days')._d)
};

  let date = moment(selectedDate).format().split("T")[0];
  // console.log(date, 'date')

  let newDate = sessionStorage.getItem("dagensNewDate"); 
  //let nn = moment(newDate)
 
 let nd = moment(new Date()).format().split("T")[0]
  //let checkDate = moment(date).isBefore(moment(nd))
  
  sessionStorage.setItem("selected-date", selectedDate);
  


  const getDatas = (check,runAgain) => {  
 
    setIsLoading(true);
    if(check && moment().format("HH")==="00" && moment().format("mm")>="00" && moment().format("mm")<="10" && moment(moment(new Date()).format("YYYY-MM-DD")).isAfter(date) === true )
    {
       date=moment().format("yy-MM-DD");
       setSelectedDate(new Date())
    }
      
    fetch(
      `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
        defaultFilmID ? defaultFilmID: org
      }&StartDate=${date}&EndDate=${date}&FromTime=00:00:00&ToTime=23:59:59`,
      {
        method: "GET", 

        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("ingen forstillinger i valgte biograf..");
        }
       
        return res.json();
      })
      .then((result) => {
        //  console.log(result)

// when app pool reloades so new KEY being feched here
         if(result.key === null && result.result === 0 && result.data === null){ 
          
          let userN = sessionStorage.getItem('user');
          let kodeN = sessionStorage.getItem('password')
          const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_login?User=${userN}&PW=${encodeURIComponent(kodeN)}`;
          fetch(endpoint, {
            method: "GET",
      
            headers: {
              ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
            },
          })
          .then((res) => res.json())
          .then((result) => { 
            // console.log(result, 'result from inside')
            sessionStorage.setItem("key", result.key);
            if(runAgain) {
              getDatas(true,false);
            }
            
          })
        }

             setIsLoading(false);
        if (result.Message) {
          setNoData(true);
          setData(null);
        } else {
          setIsLoading(false);
          setNoData(false);
          setDagensData(result);
          setData(result);
        }
      });
    
    }
   

    //  every 10 minutes a new api call been made
    useEffect(() => {
      getDatas();
    }, [defaultFilmID,date,selectedDate,dagensFilm, key]);

    

    
  moment.updateLocale("da", {
    weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
  });

  function handleYearMonthChange () {
          setMonth(date)
  }

  const o = document.querySelectorAll('option')
const lenMax = 35
o.forEach(e => {
  if (e.textContent.length > lenMax) {
    e.textContent = e.textContent.substring(0,lenMax) + '...';  
  }
  
})

//react countdown timer
const [minut, setMinut] = useState(false);

// const renderTime = ({ remainingTime }) => {
//   if (Math.round(remainingTime/60) === 1) {
//     setMinut(true)
//   }
//   else  setMinut(false)

//   return (
//     <div className="timer">
      
//       <div className="value">{Math.round(remainingTime/60)}</div>
//       <div className="text"> { minut === true ? 'minut' : 'minutter' } til</div>
//       <div className="text"> opdatering</div>
//     </div>
//   );
// };

const renderTime = ({ remainingTime }) => {
  setMinut(Math.round((remainingTime/600)*100)+'%')
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
     
    
    return (
     
        <p className="timeRemained" style={{color:'#800409', backgroundColor:'white'}}><i>
       {minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}
        </i></p>
       
    );
  };


  
  return (
    <div className="rapport">
      <div style={{display:'flex', justifyContent:'space-between'}}>
      <h4 style={{ fontWeight: "600" }}>Dagens omsætning</h4> 
      <div>
      {dagensData && dagensData.length ?
<>
      <div className="countdownContiner" > Opdateres om 

<div>
<div className="countdownDiv">
<CountdownCircleTimer isPlaying 
duration={600}
size={0}
colors={["#982f2f", "#F7B801", "#A30000", "#A30000"]}
colorsTime={[10, 6, 3, 0]}
onComplete={() =>                 
   { 
    getDatas(true , true)
     return {shouldRepeat: true, }
     }
    }
 >
 
  {renderTime}
 </CountdownCircleTimer> </div>
 
</div>


min.
 </div> 
 <div className="progressbarContainer" style={{display:'flex', justifyContent:'flex-end'}}>
           <div class="progress"  style={{ width:'180px', height:'8px', borderRadius:'0', backgroundColor:'white', border:'1px solid lightgrey', justifyContent:'end'}}>
  <div class="progress-bar" style={{width:minut, backgroundColor:'#982f2f', margin:'1px'}} role="progressbar" aria-label="Basic example"   aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>
</div>
</>
     : ''
}
</div>

 </div> <br />
      <div className="filmDate" style={{position:'relative'}}>
        <form className="form">
          <div className="biografBox">
            <p className="biograf"  style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>Biograf </p>
            <span className="custom-select-line"></span>

            <select
              className="form-select text-truncate"
              onChange={handleSelect}
              value={movies ? movies : selected}
            >
              {film &&
                film.map((item) => {
                  let bb = item.City + " - " + item.Name + " " + item.No;
                  return (
                    <option key={item.id} value={bb}  className="text-truncate"
                    style={{
                      maxWidth: 200,
                      marginLeft: '10px',
                      fontSize: "16px",
                    }}>
                      {item.City} - {item.Name}
                    </option>
                  );
                })}
            </select>
          </div>
        </form>
        
        <>
       
      <div className="calendar-container" >
        <img src={showCalendar? '' : downArrow} alt="" className="left-arrow arrows" onClick={getPreviousDate} />
        <span>{footer}</span>
        <img
          src={calendar} className="calendar-icon"
          
          alt=""
          onClick={handleClick}
        />

        <img src={showCalendar? '' : downArrow} alt="" className="right-arrow arrows" onClick={getNextDate}/>
     

      {showCalendar ? (
        <div
        className="Calendar"
          // className={showCalendar ? "Calendar slide-down" : "Calendar slide-up"}
        >
          <figure style={{ textAlign: "right", padding:10 }}>
            <img
              src={close}
              alt=""
              style={{ cursor: "pointer" , border:'1px solid white', backgroundColor:'white'}}
              onClick={closeCalendar}
            />
          </figure>
          <div className="weekdays" style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>
          <span className="days">Man</span>
          <span className="days">Tirs</span>
          <span  className="days">Ons</span>
          <span className="days" >Tors</span>
          <span className="days" >Fre</span>
          <span className="days" >Lør</span>
          <span className="days">Søn</span>
        </div>
          <DayPicker
            locale={da}
            mode="single"
            fromYear={2015} 
            toYear={2030} 
            selected={selectedDate}
            selectedDays 
            onSelect={setSelectedDate}
            month={month}
            year = {year}
            onYearChange = {setYear}
            onMonthChange={setMonth}
            captionLayout="dropdown" 
            weekdaysLong ={weekdaysMin}
            showWeekNumber = {weekdays}
            todayButton={'Gå til i dag'}
            showOutsideDays
            onDayClick={closeCalendar}
            fixedWeeks
             
           
          />
          <br />
          <div style={{ textAlign: "center" }}>
            <button
              disabled={isSameMonth(today, month)}
              onClick={() => {setMonth(today);setYear(today)}}
              
              style={{
                border: "none",
                backgroundColor:'white',
               
                fontSize: 12 + "px",
                fontWeight: "bold",
              }}
            >
              <u> GÅ TIL I DAG </u>
            </button>
          </div>
          <br />
        </div>
      ) : (
            ""
          )}
 </div>
    </>
    {/* <div className="countdown_container" style={{color: dagensData.length ? '' : 'white'}}>
     
      {dagensData.length && 

        <CountdownCircleTimer isPlaying
          duration={600}
          size={100}
          colors={["#982f2f", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[10, 6, 3, 0]}
          onComplete={() =>                 
            { 
               getDatas(true,true);
               return {shouldRepeat: true, }
               }
              }
           >
            {renderTime}
           </CountdownCircleTimer>
     } 
      </div> */}
    
      </div>
    

      <br />
      <br />
      {isLoading && <h2>Loading...</h2>}
      {noData && <h2>Ingen forestillinger i valgte biograf.</h2>}

      

      {dagensData.length && !noData ? (
        <DagenTable
          
          selectedDate={date.split("-").reverse().join("-")}
          dag={ moment(date).locale("da").format("dddd")}
        />
      ) : (
        ""
      )}
      
      <br />
    </div>
  );
}

export default Dagens;

