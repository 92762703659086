import React, { useEffect, useContext, useState } from "react";
import { keyContext } from "./KeyContext";
import { useNavigate } from "react-router-dom";


function BiografSelect() {
  const [film, setFilm] = useState([{}]);
  const { setMovieNumber, setFilmId, setDefaultFilm } = useContext(keyContext);
  const key = sessionStorage.getItem("key");
  const navigate = useNavigate();

  const [selected, setSelected] = useState(film);
  let theme = localStorage.getItem("theme")
  
  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getorganizers?Key=${key}`;
    if (key) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then(res => {
           if(res.status !== 200) {
            navigate('/')

           }
         return res.json();
        } )
        .then((result) => {
          // console.log(result)
          setFilm(result);
          // console.log('biograf length',result.length)
          if(result.length === 1) {
            // console.log('gg')
            // console.log(result[0].No)
            sessionStorage.setItem('default-filmID',result[0].No )
            setDefaultFilm(film[0].No)
          }
          // setMovieNumber(result.map((number) => number.No));
        });
    }
  }, [key, setMovieNumber]);
  
  const [selectedMovie, setSelectedMovie] = useState('');

  const handleSelect = (e) => {
    let g = e.target.value;
    setFilmId(g.split(" ")[g.split(" ").length - 1]);
    setSelected(e.target.value);
      //  setSelectedMovie(e.target.value)

    sessionStorage.setItem("film", e.target.value);
  };

  let movies = sessionStorage.getItem("film");

    useEffect(() => {
       setSelectedMovie(movies)
    
     }, [movies]);
  
     const o = document.querySelectorAll('option')
     const lenMax = 35
     o.forEach(e => {
       if (e.textContent.length > lenMax) {
         e.textContent = e.textContent.substring(0,lenMax) + '...';  
       }
       
     })
  return (
    <div className="bio" >
      <form className="form">
        <div className="movieListBox" >
          <span className="select-line" ></span>

          <p className="movie"  style={{color: theme === 'dark' ? '#fff' : '#46484c'}} >Biograf </p>
          <select
            className="form-select text-truncate"
            onChange={handleSelect}
            value={selectedMovie ? selectedMovie : selected}
          >
            
            {film &&
              film.map((item) => {
                let bb = item.City + " - " + item.Name + " " + item.No;
                return (
                  <option key={item.id} value={bb} className="text-truncate"  style={{
                    maxWidth: 200,
                    marginLeft: '10px',
                    fontSize: "16px",
                    
                    
                  }}>
                    {item.City} - {item.Name}
                  </option>
                );
              })}
          </select>
           

        </div>
      </form>{" "}
      {/* <br /> */}
    </div>
  );
  
}

export default BiografSelect;
