import React, { useEffect, useContext, useState } from "react";
import "../components/css/home.css";
import { registerLocale} from  "react-datepicker";
import { useNavigate } from "react-router-dom";
import {  addYears } from "date-fns";
import { keyContext } from "../components/KeyContext";
import Header from "../components/header/Header";



function Home(props) {
  const { errorMessage, networkError, open, isChecked } = useContext(keyContext);
  const [menu1IsActive, setmenu1IsActive] = useState(false);
  const [menu2IsActive, setmenu2IsActive] = useState(false);
  const [menu3IsActive, setmenu3IsActive] = useState(false);
  const [menu4IsActive, setmenu4IsActive] = useState(false);
  const [menu5IsActive, setmenu5IsActive] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);






  const [date, setDate] = useState(new Date());
  registerLocale('da, dk')

  // console.log(errorMessage)

  const handleClick1 = () => {
    setmenu1IsActive((current) => !current);
  };
  const handleClick2 = () => {
    setmenu2IsActive((current) => !current);
  }
  const handleClick3 = () => {
    setmenu3IsActive((current) => !current);
  }
  const handleClick4 = () => {
    setmenu4IsActive((current) => !current);
  }
  const handleClick5 = () => {
    setmenu5IsActive((current) => !current);
  }
  // const location = useLocation();
  // let history = useHistory();
  
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "admin - ebillet_admin";
  }, []);

  function Tilbage() {
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("user");

    //navigate("/");
    // window.location.reload(true);
  }
  let key = sessionStorage.getItem('key')

useEffect(() => {
  // window.location.reload(true);
  
}, [key]);
  
  return (
    <>
    {networkError ? 'Fejl i API...'
     : (
    <main className="containers">
      <Header homeActive='active'/>

    <div  style={{backgroundColor: open ? 'rgba(0,0,0,0.5'  : '', display: isChecked === true ? 'block' : '' }} className="home">

        <aside className="aside" style={{display : isChecked === true ? 'none' : ''}}>
          {/* <br />
          <br />
          <br />

          <div className="aside-menu">
            <p className="menu-title">menupunkt 1</p>
            <button className="img-btn" onClick={(e) => handleClick1(e)}>
              <p className={menu1IsActive ? "rotated" : ""}>
                {" "}
                <img src={downArrow} alt="" />{" "}
              </p>
            </button>
          </div>
          {menu1IsActive && (
            <div className="side-submenu">
              <p>submenu 1</p>
              <p>submenu 2</p>
              <p>submenu 3</p>
            </div>
          )}

          <div className="aside-menu">
            <p className="menu-title">menupunkt 2</p>
            <button className="img-btn" onClick={() => handleClick2()}>
              <p className={menu2IsActive ? "rotated" : ""}>
                {" "}
                <img src={downArrow} alt="" />{" "}
              </p>
            </button>
          </div>
          {menu2IsActive && (
            <div className="side-submenu">
              <p>submenu 1</p>
              <p>submenu 2</p>
              <p>submenu 3</p>
            </div>
          )}

          <div className="aside-menu">
            <p className="menu-title">menupunkt 3</p>
            <button className="img-btn" onClick={() => handleClick3()}>
              <p className={menu3IsActive ? "rotated" : ""}>
                {" "}
                <img src={downArrow} alt="" />{" "}
              </p>
            </button>
          </div>
          {menu3IsActive && (
            <div className="side-submenu">
              <p>submenu 1</p>
              <p>submenu 2</p>
              <p>submenu 3</p>
            </div>
          )}

          <div className="aside-menu">
            <p className="menu-title">menupunkt 4</p>
            <button className="img-btn" onClick={() => handleClick4()}>
              <p className={menu4IsActive ? "rotated" : ""}>
                {" "}
                <img src={downArrow} alt="" />{" "}
              </p>
            </button>
          </div>
          {menu4IsActive && (
            <div className="side-submenu">
              <p>submenu 1</p>
              <p>submenu 2</p>
              <p>submenu 3</p>
            </div>
          )}

          <div className="aside-menu">
            <p className="menu-title">menupunkt 5</p>
            <button className="img-btn" onClick={() => handleClick5()}>
              <p className={menu5IsActive ? "rotated" : ""}>
                {" "}
                <img src={downArrow} alt="" />{" "}
              </p>
            </button>
          </div>
          {menu5IsActive && (
            <div className="side-submenu">
              <p>submenu 1</p>
              <p>submenu 2</p>
              <p>submenu 3</p>
            </div>
          )} */}
        </aside>
   <div className="home-menu" style={{textAlign : isChecked === true  ? 'center' : ''}}>

        <h3> Velkommen til ebillet admin</h3>
        <br />
        <br /> 
       

   </div>
       
              
 

  </div>
      
     
    </main> 
    )
  }
    </>
  );
}

export default Home;

