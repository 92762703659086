import React, {useState, useEffect} from 'react'
// import "../components/css/messages.css";
import "./incidents.css";

import downArrow from "../../images/arrow-down.svg";
import { useNavigate } from "react-router-dom";


function Incidents({item}) {

    const [isShown, setIsShown] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const currentYear = new Date().getFullYear();
    // const navigate = useNavigate();
    const key = sessionStorage.getItem("key");
    const [messages, setMessages] = useState([]);
    // const [sendMSG, setSendMSG] = useState('');
    // const [solved, setSolved] = useState(false);
   const [incidentMsg, setIncidentMsg] = useState('');
   const [isChecked, setIsChecked] = useState(false);
   const [solved, setSolved] = useState(false);
   const [solvedTime, setSolvedTime] = useState('');
   const [smsAdded, setSmsAdded] = useState(false);
  //  const [addMoreMsg, setAddMoreMsg] = useState(false);


    const handleClick = (event) => {
        // 👇️ toggle shown state
        setIsShown((current) => !current);
        // setIcon((current) => !current);
        setIsActive(current => !current);
        // 👇️ or simply set it to true
        // setIsShown(true);
      };

      function handleOnChange(event) {
        const isChecked = !!event.target.checked;
        setIsChecked(isChecked);
        // Fire callback
        // if (onChange) onChange(isChecked);
      }
// console.log(isChecked)
     
    //   console.log(messages)

    function addIncientInfo(e) {
        e.preventDefault();
        const endpoint = `https://biograf.ebillet.dk/systemnative/incident.asmx/man_addincidentinfo?Key=${key}&IncidentNo=${item.No}&Information=${incidentMsg}&Solved=${isChecked}`;
        fetch(endpoint, {
          method: "GET",
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            // console.log(result);
            if (result.Result === true) {
              // setIsShown(false);
              setSmsAdded(true);
              setIncidentMsg('');
              setSolved(isChecked)
              setIsChecked(false)
              // isChecked === false
              // window.location.reload()
              // navigate('/messages')
            }
          });
      }
    
      useEffect(() => {
    
        fetch(`https://biograf.ebillet.dk/systemnative/incident.asmx/man_getincident?Key=${key}&IncidentNo=${item.No}`, {
          method: "GET",
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setSolvedTime(result.incidentItem.Solved.slice(11,16))
            setMessages(result.Messages);
            // if (!result) {
            //   navigate("/");
            // }
          });
           
      }, [key, item.No, smsAdded, item]);

  return (
    <>
    <div className="incidents-containe" >
        <div className="box">
         
          <div className="shown-menu">
                <div className="shown-left">
                  <p>{item.Name}</p>
                  <p style={{ textAlign:'right', width:50}}>{item.No}</p>
                 </div>

              <div className="shown-right">
                 
                 <p>{item.Created.slice(0,10).split("-").reverse().join("-")}</p>
                
                 <p>{item.Solved.slice(0,4) == currentYear || solved === true ?  `Løst kl. ${solvedTime}` : 'Ikke Løst'}</p>
              </div>

              </div>
          { isShown &&
              <main>
              <div style={{borderBottom:'1px solid lightgrey', position:'relative',borderTop:'1px solid lightgrey',width:95 + '%', display:'flex', justifyContent:'space-between', paddingBottom:15, paddingTop:15 }}>

                  <section className='besked-container'>    
                       <textarea className='besked' value={incidentMsg} onChange={(e) => setIncidentMsg(e.target.value)} cols="0" rows="2" placeholder='Skriv din besked'></textarea>
                        <button className='send-sms' onClick={addIncientInfo} style={{ width: "120px" }}>SEND SMS</button>   
                   </section>

                    <div className='solved-checkbox'>
                   <label htmlFor="" className='løst' >Hændelse løst    </label>
                   <input type="checkbox"   onChange={handleOnChange}
       
                      checked={isChecked} id='solved' /> 
                  
                  
                   </div>
                               
              </div>
              {messages &&
                     <div className="sent-smsHeader"  >
                           <p> Sendte SMS'er</p>
                           <p> Sendt   </p>
                          </div>
                          }
              { messages &&   messages.map((msg, index) => (
                    <>
                         
                          <div key={index} className="sent-sms" >
                            <p>"{msg.Message}."</p>
                            <p>{msg.Sendt.slice(0,10).split("-").reverse().join("-").slice(0,10)}  kl. {msg.Sendt.slice(11,16)}</p>
                          </div>
                        </>
                     ) ) } 
                     <br />
              </main>
               }
           
        <button className="img-btn" onClick={() => handleClick()}>
              <p className={isActive ? "rotated" : ""}>
                {" "}
                <img src={downArrow} alt="" />{" "}
              </p>
            </button>
        </div>  
      </div> 
    
      </>
      
  )
}

export default Incidents