import "./App.css";
import "./components/darkmode.css";
import { BrowserRouter, Routes,   Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import KeyContextProvider from "./components/KeyContext";
import Login from "./views/Login";
import UgesRapport from "./views/UgesRapport";

import Home from "./views/Home";
import Dagperiode from "./views/Dagperiode";
import Configuration from "./views/Cofiguration";
import Dagens from "./views/Dagens";
import Messages from "./views/Messages";
import Header from "./components/header/Header";
import Reports from "./views/Reports";
import PeriodeSam from './views/PeriodeSam'
import Incidents from "./components/Incidents/Incidents";
import AdminIncidents from "./views/AdminIncidents";
import UgesReportMobil from "./views/UgesReportMobil";
import DagsperiodeMobil from "./views/DagsperiodeMobil";
import DagensMobil from "./views/DagensMobil";
import PeriodeSamMobil from "./views/PeriodeSamMobil";
import WorkPlan from "./views/WorkPlan";
import WorkplanMobil from "./views/WorkplanMobil";


function App() {
  const key = sessionStorage.getItem("key");
  // console.log(key && key.length)
  const o = document.querySelectorAll('option')
  const lenMax = 35
  o.forEach(e => {
    if (e.textContent.length > lenMax) {
      e.textContent = e.textContent.substring(0,lenMax) + '...';  
    }
    
  })
  return (
    <>
      <div className="App">
        
        <KeyContextProvider>
          <BrowserRouter>
            <Routes>
             
              <Route path="/" element={<Login />}></Route>
             
              <Route exact path="/admin" element={ <Home /> }></Route>
             
              <Route path="/configuration" element={ <Configuration /> }></Route>
              
              <Route path="/messages" element={ <Messages/> }></Route>
              
              <Route path="/reports" element={ <Reports/> }></Route>

              <Route path="/rapport" element={<UgesRapport />}></Route>
              <Route path="/ugesrapport" element={ <UgesReportMobil />}></Route>

              <Route path="/dagsrapport" element={ <DagsperiodeMobil />}></Route>
              <Route path="/dagsperiode" element={<Dagperiode />}></Route>

              <Route path ="/incidents" element={<AdminIncidents/>}></Route>

              <Route path="/dagensrapport" element={<Dagens />}></Route>
              <Route path="/dagenrapport" element={<DagensMobil />}></Route>

              <Route path ="/periode" element={<PeriodeSam />}></Route>
              <Route path ="/perioder" element={<PeriodeSamMobil />}></Route>

              <Route path="/workplan" element={<WorkPlan />}></Route>
              <Route path="/workplans" element={<WorkplanMobil />}></Route>
              
             
            </Routes>
          </BrowserRouter>
          
        </KeyContextProvider>

        
      </div>
    </>
  );
}

export default App;
