import React, { useEffect, useState, CSSProperties, useRef, useContext } from "react";
// import BiografSelect from "../components/BiografSelect";
import "../components/css/perioder.css";
// import { keyContext } from "../components/KeyContext";
import moment from "moment";
import { format, isSameMonth } from "date-fns";
import { DayPicker } from "react-day-picker";
import da from "date-fns/locale/da";
import { addDays, addYears, subYears } from "date-fns";
import { useNavigate } from "react-router-dom";

import PulseLoader from "react-spinners/PulseLoader";
import calendar from "../images/Icon-Calendar.png";
import close from "../images/close.png";
// import is from "date-fns/esm/locale/is/index.js";
// import { getDefaultLocale } from "react-datepicker";

function PeriodeSam(props) {
  const [pillarsPicked, setPillarsPicked] = useState(false);

 
  
 let soldPiller = sessionStorage.getItem('antalSoldChecked')
 let ticketPiller = sessionStorage.getItem("ticketChecked")
 let totalPiller = sessionStorage.getItem("samletChecked")
 let otherPiller = sessionStorage.getItem("andenChecked")
 let kioskPiller = sessionStorage.getItem("kioskChecked")
 let kioskomsPiller = sessionStorage.getItem("kioskomsChecked")
 
useEffect(() => {
 if( soldPiller === 'true'  || ticketPiller === 'true' || totalPiller  === 'true'|| otherPiller === 'true' || kioskPiller === 'true' || kioskomsPiller === 'true')  {
  setPillarsPicked(true)
 }
 }, [soldPiller,totalPiller,kioskPiller,otherPiller,kioskomsPiller,ticketPiller]);

// console.log(pillarsPicked)
  let theme = localStorage.getItem("theme");
  // let [color, setColor] = useState("#ffffff");
  // const match = window.matchMedia("(min-width: 1220px)");
  const [showBtn, setShowBtn] = useState(false);

  const [perThisYear, setPerThisYear] = useState(false);
  const [selected, setSelected] = useState(0);
  const [yearsSelected, setYearsSelected] = useState("1");
  const [film, setFilm] = useState([{}]);
  const [defaultFilm, setDefaultFilm] = useState("");
  const [periodFilm, setPeriodsFilm] = useState("");
  const [selectedFilm, setSelectedFilm] = useState(film);
  const [period, setPeriod] = useState(0);
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState(false);
  const key = sessionStorage.getItem("key");
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const [btnsData, setBtnsData] = useState(false);
  const [periodData, setPeriodData] = useState(false);

  const dataFetchedRef = useRef(false);

  const override: CSSProperties = {
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "0 auto",
    textAlign: "center",
    borderColor: "#982f2f",
    transform: "translate(-50%, -50%)",
  };

  useEffect(() => {
    document.title = "Periodesammenligninger - ebillet_admin";
  }, []);

  const today = new Date();
  const nextYear = addYears(new Date(), 1);
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(nextYear);
  const [showFirstCal, setshowFirstCal] = useState(false);
  const [showSecondCal, setShowSecondCal] = useState(false);
  const [firstSelectedDay, setFirstSelectedDay] = useState(new Date());
  const [secondSelectedDay, setSecondSelectedDay] = useState(new Date());

  let firstDate;
  if (firstSelectedDay) {
    firstDate = (
      <span>
        {" "}
        {format(firstSelectedDay, "d.")}{" "}
        {moment(firstSelectedDay).locale("da").format("MMM")}{" "}
        {format(firstSelectedDay, "yyyy")}{" "}
      </span>
    );
  }
  let secondDate;
  if (secondSelectedDay) {
    secondDate = (
      <span>
        {" "}
        {format(secondSelectedDay, "d. ")}{" "}
        {moment(secondSelectedDay).locale("da").format("MMM")}{" "}
        {format(secondSelectedDay, "yyyy")}{" "}
      </span>
    );
  }
  let fraDato = moment(firstSelectedDay).format("yyyy-MM-DD");
  let tilDato = moment(secondSelectedDay).format("yyyy-MM-DD");
 

  // let anyDate = moment(today).format("yyyy-MM-DD");

  const [dateB4, setDateB4] = useState(true);
  const [secondDateB4, setSecondDateB4] = useState(true);

  function handleFirstClick() {
    setBtnsData(true);
    setshowFirstCal(true);
    setDateB4(false);
    setSelected("");
    setNoData(false);
    setShowBtn(true);
    setPeriodData(true);
    setLoading(false);
    
  }

  function handleSecondClick() {
    setShowSecondCal(true);
    setSecondDateB4(false);
    setSelected("");
    setShowBtn(true);
    
  }

  function closeCalendar() {
    setshowFirstCal(false);
  }

  function closeCalendar2() {
    setShowSecondCal(false);
  }

  const o = document.querySelectorAll("option");
  const lenMax = 35;
  o.forEach((e) => {
    if (e.textContent.length > lenMax) {
      e.textContent = e.textContent.substring(0, lenMax) + "...";
    }
  });

  const lists = [
    { id: 0, title: "I DAG", nr: "0" },
    { id: 1, title: "I GÅR" },
    { id: 2, title: "DENNE UGE" },
    { id: 3, title: "SIDSTE UGE" },
    { id: 4, title: "DENNE MÅNED" },
    { id: 5, title: "SIDSTE MÅNED" },
    { id: 6, title: "DETTE ÅR" },
    { id: 7, title: "VIS" },
  ];

  const handleColor = (row) => {
    setSelected(row.id);
    setPeriod(row.id);
    setLoading(true);
    setBtnsData(true);
    setNoData(false);
    setShowBtn(false);
  };

  const filmID = sessionStorage.getItem("Periodfilm");
  const orgNr = filmID && filmID.split(" ")[filmID.split(" ").length - 1];

  // console.log(period)

  function hentData() {
    setLoading(true);
    setData("");
    setNoData(false);

    fetch(
      `https://biograf.ebillet.dk/systemnative/report.asmx/man_periodCompare?Key=${key}&strOrganizers=${
        defaultFilm ? defaultFilm : orgNr
      }&period=${period}&DateFrom=${fraDato}&DateTo=${tilDato}&nYears=${yearsSelected}`,
      {
        method: "GET",

        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);

        
     setFirstSelectedDay(new Date(result[0].FromDate))
     setSecondSelectedDay(new Date(result[0].ToDate))
        setData(result);

        if (result) {
          setPeriodData(true);
          setLoading(false);
        }

        // if(loading === true) { setData('')}
        if (!result.data) {
          setNoData(true);
        } else setNoData(false);
      });
  }

  useEffect(() => {
    hentData();
  }, [period, key, orgNr, defaultFilm, yearsSelected]);

  // console.log(firstSelectedDay)

  function handleYearSelect(e) {
    // console.log(e.target.value);
    setYearsSelected(e.target.value);
    setLoading(true);
  }

  // console.log(data);
  const [selectedMovie, setSelectedMovie] = useState("");

  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getorganizers?Key=${key}`;
    if (key) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)

          setFilm(result);

          if (result.length === 1) {
            setDefaultFilm(result[0].No);
            sessionStorage.setItem("default-filmID", result[0].No);
          }
        });
    }
  }, [key]);

  const handleSelect = (e) => {
    let g = e.target.value;
    setPeriodsFilm(g.split(" ")[g.split(" ").length - 1]);
    setSelectedFilm(e.target.value);
    //  setSelectedMovie(e.target.value)

    sessionStorage.setItem("Periodfilm", e.target.value);
  };

  let movies = sessionStorage.getItem("Periodfilm");

  useEffect(() => {
    setSelectedMovie(movies);
  }, [movies]);
  let er = [];
  let bl = [];
  let ko = [];
  let kio = [];
  let ao = [];
  let so = [];

  data.length > 2 && data.map((item) => er.push(item.TicketCount));
  data.length > 2 &&
    data.map((item) => bl.push(Math.round(item.TicketRevenue)));
  data.length > 2 &&
    data.map((item) => ko.push(Math.round(item.ConcessionRevenue)));
  data.length > 2 &&
    data.map((item) =>
      kio.push(
        item.TicketCount <= 0 || item.ConcessionRevenue <= 0
          ? 0
          : Math.round(Math.round(item.ConcessionRevenue) / item.TicketCount)
      )
    );
  data.length > 2 && data.map((item) => ao.push(item.OtherRevenue));
  data.length > 2 &&
    data.map((item) =>
      so.push(item.OtherRevenue + item.TicketRevenue + item.ConcessionRevenue)
    );

  // console.log(kio, 'kiosk oms per..')
  let maxAntalBillter = Math.max(...er);
  let maxBilletoms = Math.max(...bl);
  let maxKioskoms = Math.max(...ko);
  let maxKioskPerBillet = Math.max(...kio);
  let maxAndenOms = Math.max(...ao);
  let maxSamletOms = Math.max(...so);

  let data0sam =
    data.length > 2 &&
    Math.round(
      data[0].ConcessionRevenue + data[0].TicketRevenue + data[0].OtherRevenue
    );
  let data1sam =
    data.length > 2 &&
    Math.round(
      data[1].ConcessionRevenue + data[1].OtherRevenue + data[1].TicketRevenue
    );
  let data2sam =
    data[2] &&
    Math.round(
      data[2].ConcessionRevenue + data[2].OtherRevenue + data[2].TicketRevenue
    );
  let data3sam =
    data[3] &&
    Math.round(
      data[3].ConcessionRevenue + data[3].OtherRevenue + data[3].TicketRevenue
    );
  let data4sam =
    data[4] &&
    Math.round(
      data[4].ConcessionRevenue + data[4].OtherRevenue + data[4].TicketRevenue
    );
  let data5sam =
    data[5] &&
    Math.round(
      data[5].ConcessionRevenue + data[5].OtherRevenue + data[5].TicketRevenue
    );
  let data6sam =
    data[6] &&
    Math.round(
      data[6].ConcessionRevenue + data[6].OtherRevenue + data[6].TicketRevenue
    );
  let data7sam =
    data[7] &&
    Math.round(
      data[7].ConcessionRevenue + data[7].OtherRevenue + data[7].TicketRevenue
    );
  let data8sam =
    data[8] &&
    Math.round(
      data[8].ConcessionRevenue + data[8].OtherRevenue + data[8].TicketRevenue
    );
  let data9sam =
    data[9] &&
    Math.round(
      data[9].ConcessionRevenue + data[9].OtherRevenue + data[9].TicketRevenue
    );
  let data10sam =
    data[10] &&
    Math.round(
      data[10].ConcessionRevenue +
        data[10].OtherRevenue +
        data[10].TicketRevenue
    );

  const [radioCheched, setRadioCheched] = useState("1");

  function handleProcentChange(e) {
    console.log(e.target.value, "value is");
    // e.preventDefault()

    if (e.target.value === "0") {
      setPerThisYear(true);
    } else setPerThisYear(false);
  }

  return (
    <>
      <main className="rapport periodesam">
        <section className="perioder">
          <div>
            <h4 style={{ fontWeight: 600 }}> Periodesammenligninger</h4>
            <p> Se omsætning og sammenlign med tidligere år.</p>
            <br />
            <form className="form">
              <div className="biografBox">
                <p
                  className="biograf"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Biograf{" "}
                </p>
                <span className="custom-select-line"></span>

                <select
                  className="form-select text-truncate"
                  onChange={handleSelect}
                  value={selectedMovie ? selectedMovie : selectedFilm}
                >
                  {film &&
                    film.map((item) => {
                      let bb = item.City + " - " + item.Name + " " + item.No;
                      return (
                        <option
                          key={item.id}
                          value={bb}
                          className="text-truncate"
                          style={{
                            maxWidth: 200,
                            marginLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          {item.City} - {item.Name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </form>{" "}
          </div>
          <div className="years-box">
            <div style={{ display: "flex", position: "relative" }}>
              <p
                style={{
                  color: "#46484c",

                  fontWeight: "600",
                  marginTop: 10,
                }}
              >
                Sammenlign med foregående{" "}
              </p>
              <span className="year-line"></span>
              <select
                className="year-select form-select"
                onChange={handleYearSelect}
              >
                <option value="1">1 år</option>
                <option value="2">2 år</option>
                <option value="3">3 år</option>
                <option value="4">4 år</option>
                <option value="5">5 år</option>
                <option value="6">6 år</option>
                <option value="7">7 år</option>
                <option value="8">8 år</option>
                <option value="9">9 år</option>
                <option value="10">10 år</option>
                <option value="11">11 år</option>
                <option value="12">12 år</option>
                <option value="13">13 år</option>
                <option value="14">14 år</option>
                <option value="15">15 år</option>
                <option value="16">16 år</option>
                <option value="17">17 år</option>
                <option value="18">18 år</option>
                <option value="19">19 år</option>
                <option value="20">20 år</option>
                <option value="21">21 år</option>
                <option value="22">22 år</option>
                <option value="23">23 år</option>
                <option value="24">24 år</option>
                <option value="25">25 år</option>
              </select>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: 200 }}>
                {/* <input
                  type="checkbox"
                  name=""
                  id=""
                  checked
                  style={{ marginRight: 8 }}
                /> */}
                <span
                  style={{
                    color: "#46484c",
                    fontWeight: "600",
                    width: "164px",
                  }}
                >
                  Vis udvikling i procent
                </span>
              </div>
              <div
                style={{
                  width: 210,
                  // border: "1px solid red",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex" }}
                  onClick={() => {
                    setRadioCheched("1");
                  }}
                >
                  <input
                    type="radio"
                    name="procent"
                    value="1"
                    checked={radioCheched === "1"}
                    id=""
                    // onClick={ (e) => handleProcentChange(e)}
                    style={{ marginRight: 10, marginTop: "6px" }}
                  />
                  <p
                    style={{
                      // border: "1px solid red",
                      width: "150px",
                      color: "#46484c",
                    }}
                  >
                    Procentvis udvikling ift. foregående år
                  </p>
                </div>
                <div
                  style={{ display: "flex", marginTop: "-10px" }}
                  onClick={() => {
                    setRadioCheched("0");
                  }}
                >
                  <input
                    type="radio"
                    name="procent"
                    id=""
                    value="0"
                    checked={radioCheched === "0"}
                    // onClick={ (e) => handleProcentChange(e)}
                    style={{ marginRight: 10, marginTop: "5px" }}
                  />
                  <p style={{ color: "#46484c" }}> Procentvis ift. i år</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <div className="datesContainer">
          <div className="btns-container">
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontWeight: "bold",
              }}
            >
              Vis omsætning for
            </p>

            <div className="buttons-group">
              {lists.map((list, index) => (
                <button
                  className={`butto butto_${index}`}
                  key={list.id}
                  onClick={() => handleColor(list)}
                  style={{
                    backgroundColor:
                      list.id === selected && showBtn === false
                        ? "#982f2f"
                        : "",
                    color:
                      list.id === selected && showBtn === false ? "#fff" : "",
                  }}
                >
                  {list.title}
                </button>
              ))}
            </div>
          </div>

          <br />
          {/* <div style={{textAlign:'end'}}> */}
          <article className="datoPerioder">
            <div>
              <span
                style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                className="fra"
              >
                Fra
              </span>
              <div
                className="date-box"
                style={{ marginLeft: "0px", marginRight: "9px" , width:'180px'}}
              >
                <span className="first-date">{firstDate}</span>
                <span className="date-box-line"></span>
                <img
                  src={calendar}
                  alt=""
                  className="date-box-calendar"
                  onClick={handleFirstClick}
                />
                {showFirstCal ? (
                  <div className="dagsCalendar " style={{ marginLeft: 0 }}>
                    <figure style={{ textAlign: "right", padding: 10 }}>
                      <img
                        src={close}
                        alt=""
                        style={{
                          cursor: "pointer",
                          border: "1px solid white",
                          backgroundColor: "white",
                        }}
                        onClick={closeCalendar}
                      />
                    </figure>
                    <div
                      className="weekdays "
                      style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                    >
                      <span className="days">Man</span>
                      <span className="days">Tirs</span>
                      <span className="days">Ons</span>
                      <span className="days">Tors</span>
                      <span className="days">Fre</span>
                      <span className="days">Lør</span>
                      <span className="days">Søn</span>
                    </div>
                    <DayPicker
                      locale={da}
                      mode="single"
                      fromYear={2015}
                      toYear={2030}
                      captionLayout="dropdown"
                      showOutsideDays
                      showWeekNumber
                      selected={firstSelectedDay}
                      month={month}
                      year={year}
                      onYearChange={setYear}
                      onMonthChange={setMonth}
                      onSelect={setFirstSelectedDay}
                      onDayClick={closeCalendar}
                    />
                    <br />
                    <div style={{ textAlign: "center" }}>
                      <button
                        disabled={isSameMonth(today, month)}
                        onClick={() => {
                          setMonth(today);
                          setYear(today);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          fontSize: 12 + "px",
                          fontWeight: "bold",
                        }}
                      >
                        <u> GÅ TIL I DAG </u>
                      </button>
                    </div>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <span
                style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                className="fra"
              >
                Til
              </span>

              <div
                className="date-box"
                style={{ marginLeft: "0px", marginRight: "5px", width:'180px' }}
              >
                <span className="first-date">{secondDate}</span>
                <span className="date-box-line"></span>
                <img
                  src={calendar}
                  alt=""
                  className="date-box-til-calendar"
                  onClick={handleSecondClick}
                />
                {showSecondCal ? (
                  <div className="dagsCalendar2 cal">
                    <figure style={{ textAlign: "right", padding: 10 }}>
                      <img
                        src={close}
                        alt=""
                        style={{
                          cursor: "pointer",
                          border: "1px solid white",
                          backgroundColor: "white",
                        }}
                        onClick={closeCalendar2}
                      />
                    </figure>
                    <div
                      className="weekdays"
                      style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                    >
                      <span className="days">Man</span>
                      <span className="days">Tirs</span>
                      <span className="days">Ons</span>
                      <span className="days">Tors</span>
                      <span className="days">Fre</span>
                      <span className="days">Lør</span>
                      <span className="days">Søn</span>
                    </div>
                    <DayPicker
                      locale={da}
                      mode="single"
                      fromYear={2015}
                      toYear={2030}
                      captionLayout="dropdown"
                      showOutsideDays
                      showWeekNumber
                      selected={secondSelectedDay}
                      month={month}
                      year={year}
                      onYearChange={setYear}
                      onMonthChange={setMonth}
                      onSelect={setSecondSelectedDay}
                      onDayClick={closeCalendar2}
                    />
                    <br />
                    <div style={{ textAlign: "center" }}>
                      <button
                        disabled={isSameMonth(today, month)}
                        onClick={() => {
                          setMonth(today);
                          setYear(today);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          // background: "transparent",
                          fontSize: 12 + "px",
                          fontWeight: "bold",
                        }}
                      >
                        <u> GÅ TIL I DAG </u>
                      </button>
                    </div>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <br />
          </article>
        </div>

        <br />

        {/* { !data ? <p>ingen data</p> : ""} */}

        <div style={{ height: data.length ? "" : 200, position: "relative" }}>
          {loading && film ? (
            <PulseLoader
              color="#982f2f"
              loading={loading}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            ""
          )}
        </div>

        {/* following layout is shown if the years selected is between 2 and 10 */}

        <section
          className={data.length < 6 ? "between2and10" : ""}
          style={{
            marginBottom: '50px',
            display: data.length > 2 && data.length < 12 ? "flex" : "none",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexDirection: data.length < 6 ? " " : "column",
          }}
        >
          {data.length && (
            <>
              {/* <div style={{marginRight: '30px'}}> */}
              { pillarsPicked === false || (pillarsPicked === true && soldPiller === 'true') ? ( 
              <div>
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#46484c",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Antal solgte billetter
                </p>

                <div
                  className="antalContainer"
                  style={{
                    justifyContent: data.length < 6 ? "" : "space-between",
                  }}
                >
                  {data[10] && (
                    <div className="antalBar">
                     
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[10].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[10].TicketCount.toLocaleString("da")}
                      </span>
                      {data[10].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "0" ? (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (data[10].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[10].TicketCount - data[0].TicketCount) *
                                  100) /
                                  data[0].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[10].TicketCount - data[0].TicketCount) *
                              100) /
                              data[0].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[10].TicketCount - data[0].TicketCount) *
                              100) /
                              data[0].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.35,
                          height:
                            Math.round(
                              (data[10].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[10].FromDate.slice(8, 10) +
                            "/" +
                            data[10].FromDate.slice(5, 7) +
                            "-" +
                            data[10].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[10].FromDate === data[10].ToDate
                            ? " "
                            : data[10].ToDate.slice(8, 10) +
                              "/" +
                              data[10].ToDate.slice(5, 7) +
                              "-" +
                              data[10].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[9] && (
                    <div className="antalBar">
                     
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[9] &&
                                data[9].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[9] && data[9].TicketCount.toLocaleString("da")}
                      </span>
                      {data[9] && data[9].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[10] &&
                        data[10].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[9].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[9].TicketCount - data[10].TicketCount) *
                                  100) /
                                  data[10].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[9].TicketCount - data[10].TicketCount) *
                              100) /
                              data[10].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[9].TicketCount - data[10].TicketCount) *
                              100) /
                              data[10].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[9].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[9].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[9].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[9].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.4,
                          height:
                            Math.round(
                              (data[9].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[9].FromDate.slice(8, 10) +
                            "/" +
                            data[9].FromDate.slice(5, 7) +
                            "-" +
                            data[9].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[9].FromDate === data[9].ToDate
                            ? " "
                            : data[9].ToDate.slice(8, 10) +
                              "/" +
                              data[9].ToDate.slice(5, 7) +
                              "-" +
                              data[9].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[8] && (
                    <div className="antalBar">
                      
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[8] &&
                                data[8].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[8] && data[8].TicketCount.toLocaleString("da")}
                      </span>
                      {data[8] && data[8].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[9] &&
                        data[9].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[8].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[8].TicketCount - data[9].TicketCount) *
                                  100) /
                                  data[9].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[8].TicketCount - data[9].TicketCount) *
                              100) /
                              data[9].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[8].TicketCount - data[9].TicketCount) *
                              100) /
                              data[9].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[8].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[8].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[8].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[8].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.45,
                          height:
                            Math.round(
                              (data[8].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[8].FromDate.slice(8, 10) +
                            "/" +
                            data[8].FromDate.slice(5, 7) +
                            "-" +
                            data[8].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[8].FromDate === data[8].ToDate
                            ? " "
                            : data[8].ToDate.slice(8, 10) +
                              "/" +
                              data[8].ToDate.slice(5, 7) +
                              "-" +
                              data[8].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[7] && (
                    <div className="antalBar">
                      
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[7] &&
                                data[7].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[7] && data[7].TicketCount.toLocaleString("da")}
                      </span>
                      {data[7] && data[7].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[8] &&
                        data[8].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[7].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[7].TicketCount - data[8].TicketCount) *
                                  100) /
                                  data[8].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[7].TicketCount - data[8].TicketCount) *
                              100) /
                              data[8].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[7].TicketCount - data[8].TicketCount) *
                              100) /
                              data[8].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[7].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[7].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[7].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[7].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.5,
                          height:
                            Math.round(
                              (data[7].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[7].FromDate.slice(8, 10) +
                            "/" +
                            data[7].FromDate.slice(5, 7) +
                            "-" +
                            data[7].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[7].FromDate === data[7].ToDate
                            ? " "
                            : data[7].ToDate.slice(8, 10) +
                              "/" +
                              data[7].ToDate.slice(5, 7) +
                              "-" +
                              data[7].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[6] && (
                    <div className="antalBar">
                      
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[6] &&
                                data[6].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[6] && data[6].TicketCount.toLocaleString("da")}
                      </span>
                      {data[6] && data[6].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[7] &&
                        data[7].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[6].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[6].TicketCount - data[7].TicketCount) *
                                  100) /
                                  data[7].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[6].TicketCount - data[7].TicketCount) *
                              100) /
                              data[7].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[6].TicketCount - data[7].TicketCount) *
                              100) /
                              data[7].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[6].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[6].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[6].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[6].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.55,
                          height:
                            Math.round(
                              (data[6].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[6].FromDate.slice(8, 10) +
                            "/" +
                            data[6].FromDate.slice(5, 7) +
                            "-" +
                            data[6].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[6].FromDate === data[6].ToDate
                            ? " "
                            : data[6].ToDate.slice(8, 10) +
                              "/" +
                              data[6].ToDate.slice(5, 7) +
                              "-" +
                              data[6].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[5] && (
                    <div className="antalBar">
                     
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[5] &&
                                data[5].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[5] && data[5].TicketCount.toLocaleString("da")}
                      </span>
                      {data[5] && data[5].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[6] &&
                        data[6].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[5].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[5].TicketCount - data[6].TicketCount) *
                                  100) /
                                  data[6].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[5].TicketCount - data[6].TicketCount) *
                              100) /
                              data[6].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[5].TicketCount - data[6].TicketCount) *
                              100) /
                              data[6].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[5].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[5].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[5].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[5].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.6,
                          height:
                            Math.round(
                              (data[5].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[5].FromDate.slice(8, 10) +
                            "/" +
                            data[5].FromDate.slice(5, 7) +
                            "-" +
                            data[5].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[5].FromDate === data[5].ToDate
                            ? " "
                            : data[5].ToDate.slice(8, 10) +
                              "/" +
                              data[5].ToDate.slice(5, 7) +
                              "-" +
                              data[5].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[4] && (
                    <div className="antalBar">
                    
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[4] &&
                                data[4].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[4] && data[4].TicketCount.toLocaleString("da")}
                      </span>
                      {data[4] && data[4].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[5] &&
                        data[5].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[4].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[4].TicketCount - data[5].TicketCount) *
                                  100) /
                                  data[5].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[4].TicketCount - data[5].TicketCount) *
                              100) /
                              data[5].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[4].TicketCount - data[5].TicketCount) *
                              100) /
                              data[5].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[4].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[4].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[4].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[4].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.65,
                          height:
                            Math.round(
                              (data[4].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[4].FromDate.slice(8, 10) +
                            "/" +
                            data[4].FromDate.slice(5, 7) +
                            "-" +
                            data[4].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[4].FromDate === data[4].ToDate
                            ? " "
                            : data[4].ToDate.slice(8, 10) +
                              "/" +
                              data[4].ToDate.slice(5, 7) +
                              "-" +
                              data[4].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[3] && (
                    <div className="antalBar">
                      
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[3] &&
                                data[3].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[3] && data[3].TicketCount.toLocaleString("da")}
                      </span>
                      {data[3] && data[3].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        (data[4] && data[4].TicketCount) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[3].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[3].TicketCount - data[4].TicketCount) *
                                  100) /
                                  data[4].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {" "}
                          {Math.round(
                            ((data[3].TicketCount - data[4].TicketCount) *
                              100) /
                              data[4].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[3].TicketCount - data[4].TicketCount) *
                              100) /
                              data[4].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[3].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[3].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[3].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[3].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.7,
                          height:
                            Math.round(
                              (data[3].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[3].FromDate.slice(8, 10) +
                            "/" +
                            data[3].FromDate.slice(5, 7) +
                            "-" +
                            data[3].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[3].FromDate === data[3].ToDate
                            ? " "
                            : data[3].ToDate.slice(8, 10) +
                              "/" +
                              data[3].ToDate.slice(5, 7) +
                              "-" +
                              data[3].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[2] && (
                    <div className="antalBar">
                     
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[2] &&
                                data[2].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[2] && data[2].TicketCount.toLocaleString("da")}
                      </span>

                      {data[2] && data[2].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[3] &&
                        data[3].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[2].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[2].TicketCount - data[3].TicketCount) *
                                  100) /
                                  data[3].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[2].TicketCount - data[3].TicketCount) *
                              100) /
                              data[3].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[2].TicketCount - data[3].TicketCount) *
                              100) /
                              data[3].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[2].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[2].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[2].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[2].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.75,
                          height:
                            Math.round(
                              (data[2].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[2].FromDate.slice(8, 10) +
                            "/" +
                            data[2].FromDate.slice(5, 7) +
                            "-" +
                            data[2].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[2].FromDate === data[2].ToDate
                            ? " "
                            : data[2].ToDate.slice(8, 10) +
                              "/" +
                              data[2].ToDate.slice(5, 7) +
                              "-" +
                              data[2].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[1] && (
                    <div className="antalBar">
                      <span
                        className="greenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[1] &&
                                data[1].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[1] && data[1].TicketCount.toLocaleString("da")}
                      </span>
                      {data[1] && data[1].TicketCount <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[2] &&
                        data[2].TicketCount > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[1].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[1].TicketCount - data[2].TicketCount) *
                                  100) /
                                  data[2].TicketCount
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[1].TicketCount - data[2].TicketCount) *
                              100) /
                              data[2].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[1].TicketCount - data[2].TicketCount) *
                              100) /
                              data[2].TicketCount
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].TicketCount > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[1].TicketCount /
                                    Math.round(maxAntalBillter * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[1] &&
                                    data[1].TicketCount - data[0].TicketCount) *
                                    100) /
                                    data[0].TicketCount
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[1] &&
                                data[1].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[1] &&
                                data[1].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="antalTal"
                        style={{
                          opacity: 0.8,
                          height:
                            Math.round(
                              (data[1].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[1].FromDate.slice(8, 10) +
                            "/" +
                            data[1].FromDate.slice(5, 7) +
                            "-" +
                            data[1].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[1].FromDate === data[1].ToDate
                            ? " "
                            : data[1].ToDate.slice(8, 10) +
                              "/" +
                              data[1].ToDate.slice(5, 7) +
                              "-" +
                              data[1].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="antalBar">
                    <span
                      className="greenNr"
                      style={{
                        bottom:
                          Math.round(
                            (data[0].TicketCount /
                              Math.round(maxAntalBillter * 1.25)) *
                              100 +
                              3
                          ) + "%",
                      }}
                    >
                      {data[0].TicketCount.toLocaleString("da")}
                    </span>

                    {data[0].TicketCount <= 0 ? (
                      " "
                    ) : radioCheched === "1" && data[1].TicketCount > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (data[0].TicketCount /
                                Math.round(maxAntalBillter * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data[0].TicketCount - data[1].TicketCount) *
                                100) /
                                data[1].TicketCount
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(
                          ((data[0].TicketCount - data[1].TicketCount) * 100) /
                            data[1].TicketCount
                        ) > 0
                          ? "+"
                          : ""}
                        {data[1].TicketCount > 0
                          ? Math.round(
                              ((data[0].TicketCount - data[1].TicketCount) *
                                100) /
                                data[1].TicketCount
                            ) + "%"
                          : ""}
                      </span>
                    ) : (
                      radioCheched === "0" && (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].TicketCount /
                                  Math.round(maxAntalBillter * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                    )}

                    <div
                      className="antalTal"
                      style={{
                        height:
                          Math.round(
                            (data[0].TicketCount /
                              Math.round(maxAntalBillter * 1.25)) *
                              100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
               ) : ' ' }
              {/* <br /> */}
              {/* <br /> */}
              {/* billetomsætning column starts here */}
              { pillarsPicked === false || (pillarsPicked === true && ticketPiller === 'true') ? ( 
              <div style={{ marginTop: data.length > 5 ? "25px" : "" }}>
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#46484c",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Billetomsætning
                </p>
                <div
                  className="antalContainer"
                  style={{
                    justifyContent: data.length < 6 ? "" : "space-between",
                  }}
                >
                  {data[10] && (
                    <div className="antalBar">
                      
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[10].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[10].TicketRevenue).toLocaleString(
                          "da"
                        )}
                      </span>
                      {Math.round(data[10].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "0" ? (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (Math.round(data[10].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[10].TicketRevenue -
                                  data[0].TicketRevenue) *
                                  100) /
                                  data[0].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[10] &&
                              Math.round(data[10].TicketRevenue) -
                                Math.round(data[0].TicketRevenue)) *
                              100) /
                              Math.round(data[0].TicketRevenue)
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[10] &&
                              Math.round(data[10].TicketRevenue) -
                                Math.round(data[0].TicketRevenue)) *
                              100) /
                              Math.round(data[0].TicketRevenue)
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.35,
                          height:
                            Math.round(
                              (Math.round(data[10].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[10].FromDate.slice(8, 10) +
                            "/" +
                            data[10].FromDate.slice(5, 7) +
                            "-" +
                            data[10].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[10].FromDate === data[10].ToDate
                            ? " "
                            : data[10].ToDate.slice(8, 10) +
                              "/" +
                              data[10].ToDate.slice(5, 7) +
                              "-" +
                              data[10].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[9] && (
                    <div className="antalBar">
                     
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[9] && data[9].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[9] && data[9].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[9] && data[9].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[10] &&
                        Math.round(data[10].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[9].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[9].TicketRevenue -
                                  data[10].TicketRevenue) *
                                  100) /
                                  data[10].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[9].TicketRevenue - data[10].TicketRevenue) *
                              100) /
                              data[10].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[9].TicketRevenue - data[10].TicketRevenue) *
                              100) /
                              data[10].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[9].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[9].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[9] &&
                                Math.round(data[9].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[9] &&
                                Math.round(data[9].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.4,
                          height:
                            Math.round(
                              (Math.round(data[9].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[9].FromDate.slice(8, 10) +
                            "/" +
                            data[9].FromDate.slice(5, 7) +
                            "-" +
                            data[9].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[9].FromDate === data[9].ToDate
                            ? " "
                            : data[9].ToDate.slice(8, 10) +
                              "/" +
                              data[9].ToDate.slice(5, 7) +
                              "-" +
                              data[9].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[8] && (
                    <div className="antalBar">
                     
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[8] && data[8].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[8] && data[8].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[8] && data[8].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[9] &&
                        Math.round(data[9].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[8].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[8].TicketRevenue -
                                  data[9].TicketRevenue) *
                                  100) /
                                  data[9].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[8].TicketRevenue - data[9].TicketRevenue) *
                              100) /
                              data[9].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[8].TicketRevenue - data[9].TicketRevenue) *
                              100) /
                              data[9].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[8].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[8].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[8] &&
                                Math.round(data[8].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[8] &&
                                Math.round(data[8].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.45,
                          height:
                            Math.round(
                              (Math.round(data[8].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[8].FromDate.slice(8, 10) +
                            "/" +
                            data[8].FromDate.slice(5, 7) +
                            "-" +
                            data[8].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[8].FromDate === data[8].ToDate
                            ? " "
                            : data[8].ToDate.slice(8, 10) +
                              "/" +
                              data[8].ToDate.slice(5, 7) +
                              "-" +
                              data[8].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[7] && (
                    <div className="antalBar">
                     
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[7] && data[7].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[7] && data[7].TicketRevenue
                        ).toLocaleString("da")}
                      </span>

                      {Math.round(data[7] && data[7].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[8] &&
                        Math.round(data[8].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[7].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[7].TicketRevenue -
                                  data[8].TicketRevenue) *
                                  100) /
                                  data[8].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[7].TicketRevenue - data[8].TicketRevenue) *
                              100) /
                              data[8].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[7].TicketRevenue - data[8].TicketRevenue) *
                              100) /
                              data[8].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[7].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[7].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[7] &&
                                Math.round(data[7].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[7] &&
                                Math.round(data[7].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.5,
                          height:
                            Math.round(
                              (Math.round(data[7].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[7].FromDate.slice(8, 10) +
                            "/" +
                            data[7].FromDate.slice(5, 7) +
                            "-" +
                            data[7].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[7].FromDate === data[7].ToDate
                            ? " "
                            : data[7].ToDate.slice(8, 10) +
                              "/" +
                              data[7].ToDate.slice(5, 7) +
                              "-" +
                              data[7].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[6] && (
                    <div className="antalBar">
                      
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[6] && data[6].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[6] && data[6].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[6] && data[6].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[7] &&
                        Math.round(data[7].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[6].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[6].TicketRevenue -
                                  data[7].TicketRevenue) *
                                  100) /
                                  data[7].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[6].TicketRevenue - data[7].TicketRevenue) *
                              100) /
                              data[7].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[6].TicketRevenue - data[7].TicketRevenue) *
                              100) /
                              data[7].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[6].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[6].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[6] &&
                                Math.round(data[6].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[6] &&
                                Math.round(data[6].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.55,
                          height:
                            Math.round(
                              (Math.round(data[6].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[6].FromDate.slice(8, 10) +
                            "/" +
                            data[6].FromDate.slice(5, 7) +
                            "-" +
                            data[6].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[6].FromDate === data[6].ToDate
                            ? " "
                            : data[6].ToDate.slice(8, 10) +
                              "/" +
                              data[6].ToDate.slice(5, 7) +
                              "-" +
                              data[6].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[5] && (
                    <div className="antalBar">
                     
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[5] && data[5].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[5] && data[5].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[5] && data[5].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[6] &&
                        Math.round(data[6].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[5].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[5].TicketRevenue -
                                  data[6].TicketRevenue) *
                                  100) /
                                  data[6].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[5].TicketRevenue - data[6].TicketRevenue) *
                              100) /
                              data[6].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[5].TicketRevenue - data[6].TicketRevenue) *
                              100) /
                              data[6].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[5].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[5].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[5] &&
                                Math.round(data[5].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[5] &&
                                Math.round(data[5].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.6,
                          height:
                            Math.round(
                              (Math.round(data[5].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[5].FromDate.slice(8, 10) +
                            "/" +
                            data[5].FromDate.slice(5, 7) +
                            "-" +
                            data[5].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[5].FromDate === data[5].ToDate
                            ? " "
                            : data[5].ToDate.slice(8, 10) +
                              "/" +
                              data[5].ToDate.slice(5, 7) +
                              "-" +
                              data[5].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[4] && (
                    <div className="antalBar">
                     
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[4] && data[4].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[4] && data[4].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[4] && data[4].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[5] &&
                        Math.round(data[5].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[4].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[4].TicketRevenue -
                                  data[5].TicketRevenue) *
                                  100) /
                                  data[5].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[4].TicketRevenue - data[5].TicketRevenue) *
                              100) /
                              data[5].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[4].TicketRevenue - data[5].TicketRevenue) *
                              100) /
                              data[5].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[4].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[4].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[4] &&
                                Math.round(data[4].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[4] &&
                                Math.round(data[4].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.65,
                          height:
                            Math.round(
                              (Math.round(data[4].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[4].FromDate.slice(8, 10) +
                            "/" +
                            data[4].FromDate.slice(5, 7) +
                            "-" +
                            data[4].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[4].FromDate === data[4].ToDate
                            ? " "
                            : data[4].ToDate.slice(8, 10) +
                              "/" +
                              data[4].ToDate.slice(5, 7) +
                              "-" +
                              data[4].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[3] && (
                    <div className="antalBar">
                      
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[3] && data[3].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[3] && data[3].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[3] && data[3].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[4] &&
                        Math.round(data[4].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[3].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[3].TicketRevenue -
                                  data[4].TicketRevenue) *
                                  100) /
                                  data[4].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[3].TicketRevenue - data[4].TicketRevenue) *
                              100) /
                              data[4].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[3].TicketRevenue - data[4].TicketRevenue) *
                              100) /
                              data[4].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[3].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[3].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[3] &&
                                Math.round(data[3].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[3] &&
                                Math.round(data[3].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.7,
                          height:
                            Math.round(
                              (Math.round(data[3].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[3].FromDate.slice(8, 10) +
                            "/" +
                            data[3].FromDate.slice(5, 7) +
                            "-" +
                            data[3].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[3].FromDate === data[3].ToDate
                            ? " "
                            : data[3].ToDate.slice(8, 10) +
                              "/" +
                              data[3].ToDate.slice(5, 7) +
                              "-" +
                              data[3].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[2] && (
                    <div className="antalBar">
                      
                      <span
                        className="blueNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[2] && data[2].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[2] && data[2].TicketRevenue
                        ).toLocaleString("da")}
                      </span>

                      {Math.round(data[2] && data[2].TicketRevenue) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[3] &&
                        Math.round(data[3].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[2].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[2].TicketRevenue -
                                  data[3].TicketRevenue) *
                                  100) /
                                  data[3].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[2].TicketRevenue - data[3].TicketRevenue) *
                              100) /
                              data[3].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[2].TicketRevenue - data[3].TicketRevenue) *
                              100) /
                              data[3].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[2].TicketRevenue) /
                                    Math.round(maxBilletoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[2].TicketRevenue -
                                    data[0].TicketRevenue) *
                                    100) /
                                    data[0].TicketRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[2] &&
                                Math.round(data[2].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[2] &&
                                Math.round(data[2].TicketRevenue) -
                                  Math.round(data[0].TicketRevenue)) *
                                100) /
                                Math.round(data[0].TicketRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="billetTal"
                        style={{
                          opacity: 0.75,
                          height:
                            Math.round(
                              (Math.round(data[2].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>

                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[2].FromDate.slice(8, 10) +
                            "/" +
                            data[2].FromDate.slice(5, 7) +
                            "-" +
                            data[2].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[2].FromDate === data[2].ToDate
                            ? " "
                            : data[2].ToDate.slice(8, 10) +
                              "/" +
                              data[2].ToDate.slice(5, 7) +
                              "-" +
                              data[2].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="antalBar">
                    <span
                      className="blueNr"
                      style={{
                        bottom:
                          Math.round(
                            (Math.round(data[1].TicketRevenue) /
                              Math.round(maxBilletoms * 1.25)) *
                              100 +
                              3
                          ) + "%",
                      }}
                    >
                      {Math.round(data[1].TicketRevenue).toLocaleString("da")}
                    </span>

                    {Math.round(data[1].TicketRevenue) <= 0 ? (
                      " "
                    ) : radioCheched === "1" &&
                      data[2] &&
                      Math.round(data[2].TicketRevenue) > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[1].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data[1].TicketRevenue - data[2].TicketRevenue) *
                                100) /
                                data[2].TicketRevenue
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(
                          ((data[1].TicketRevenue - data[2].TicketRevenue) *
                            100) /
                            data[2].TicketRevenue
                        ) > 0
                          ? "+"
                          : ""}
                        {Math.round(
                          ((data[1].TicketRevenue - data[2].TicketRevenue) *
                            100) /
                            data[2].TicketRevenue
                        ) + "%"}
                      </span>
                    ) : (
                      radioCheched === "0" && (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (Math.round(data[1].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[1].TicketRevenue -
                                  data[0].TicketRevenue) *
                                  100) /
                                  data[0].TicketRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[1] &&
                              Math.round(data[1].TicketRevenue) -
                                Math.round(data[0].TicketRevenue)) *
                              100) /
                              Math.round(data[0].TicketRevenue)
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[1] &&
                              Math.round(data[1].TicketRevenue) -
                                Math.round(data[0].TicketRevenue)) *
                              100) /
                              Math.round(data[0].TicketRevenue)
                          ) + "%"}
                        </span>
                      )
                    )}

                    <div
                      className="billetTal"
                      style={{
                        opacity: 0.8,
                        height:
                          Math.round(
                            (Math.round(data[1].TicketRevenue) /
                              Math.round(maxBilletoms * 1.25)) *
                              100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                  <div className="antalBar">
                    <span
                      className="blueNr"
                      style={{
                        bottom:
                          Math.round(
                            (Math.round(data[0].TicketRevenue) /
                              Math.round(maxBilletoms * 1.25)) *
                              100 +
                              3
                          ) + "%",
                      }}
                    >
                      {Math.round(data[0].TicketRevenue).toLocaleString("da")}
                    </span>

                    {Math.round(data[0].TicketRevenue) <= 0 ? (
                      " "
                    ) : radioCheched === "1" &&
                      data[1] &&
                      Math.round(data[1].TicketRevenue) > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[0].TicketRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data[0].TicketRevenue - data[1].TicketRevenue) *
                                100) /
                                data[1].TicketRevenue
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(
                          ((data[0].TicketRevenue - data[1].TicketRevenue) *
                            100) /
                            data[1].TicketRevenue
                        ) > 0
                          ? "+"
                          : ""}
                        {Math.round(
                          ((data[0].TicketRevenue - data[1].TicketRevenue) *
                            100) /
                            data[1].TicketRevenue
                        ) + "%"}
                      </span>
                    ) : (
                      radioCheched === "0" && (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[0].TicketRevenue) /
                                  Math.round(maxBilletoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                    )}

                    <div
                      className="billetTal"
                      style={{
                        height:
                          Math.round(
                            (Math.round(data[0].TicketRevenue) /
                              Math.round(maxBilletoms * 1.25)) *
                              100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : ' ' }
             { pillarsPicked === false || (pillarsPicked === true && kioskPiller === 'true') ? (
              <div style={{ marginTop: data.length > 2 ? "25px" : "" }}>
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#46484c",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Kioskomsætning
                </p>
                <div
                  className="antalContainer"
                  style={{
                    justifyContent: data.length < 6 ? "" : "space-between",
                  }}
                >
                  {data[10] && (
                    <div className="antalBar">
                      
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[10].ConcessionRevenue) /
                                Math.round(maxBilletoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[10].ConcessionRevenue).toLocaleString(
                          "da"
                        )}
                      </span>

                      {Math.round(data[10].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "0" ? (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (Math.round(data[10].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[10].ConcessionRevenue -
                                  data[0].ConcessionRevenue) *
                                  100) /
                                  data[0].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[10] &&
                              Math.round(data[10].ConcessionRevenue) -
                                Math.round(data[0].ConcessionRevenue)) *
                              100) /
                              Math.round(data[0].ConcessionRevenue)
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[10] &&
                              Math.round(data[10].ConcessionRevenue) -
                                Math.round(data[0].ConcessionRevenue)) *
                              100) /
                              Math.round(data[0].ConcessionRevenue)
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.35,
                          height:
                            Math.round(
                              (Math.round(data[10].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[10].FromDate.slice(8, 10) +
                            "/" +
                            data[10].FromDate.slice(5, 7) +
                            "-" +
                            data[10].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[10].FromDate === data[10].ToDate
                            ? " "
                            : data[10].ToDate.slice(8, 10) +
                              "/" +
                              data[10].ToDate.slice(5, 7) +
                              "-" +
                              data[10].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[9] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[9] && data[9].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[9] && data[9].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[9] && data[9].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[10] &&
                        Math.round(data[10].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[9].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[9].ConcessionRevenue -
                                  data[10].ConcessionRevenue) *
                                  100) /
                                  data[10].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[9].ConcessionRevenue -
                              data[10].ConcessionRevenue) *
                              100) /
                              data[10].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[9].ConcessionRevenue -
                              data[10].ConcessionRevenue) *
                              100) /
                              data[10].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[9].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[9].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[9] &&
                                Math.round(data[9].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[9] &&
                                Math.round(data[9].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.4,
                          height:
                            Math.round(
                              (Math.round(data[9].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[9].FromDate.slice(8, 10) +
                            "/" +
                            data[9].FromDate.slice(5, 7) +
                            "-" +
                            data[9].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[9].FromDate === data[9].ToDate
                            ? " "
                            : data[9].ToDate.slice(8, 10) +
                              "/" +
                              data[9].ToDate.slice(5, 7) +
                              "-" +
                              data[9].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[8] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[8] && data[8].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[8] && data[8].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>

                      {Math.round(data[8] && data[8].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[9] &&
                        Math.round(data[9].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[8].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[8].ConcessionRevenue -
                                  data[9].ConcessionRevenue) *
                                  100) /
                                  data[9].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[8].ConcessionRevenue -
                              data[9].ConcessionRevenue) *
                              100) /
                              data[9].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[8].ConcessionRevenue -
                              data[9].ConcessionRevenue) *
                              100) /
                              data[9].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[8].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[8].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[8] &&
                                Math.round(data[8].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[8] &&
                                Math.round(data[8].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.45,
                          height:
                            Math.round(
                              (Math.round(data[8].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[8].FromDate.slice(8, 10) +
                            "/" +
                            data[8].FromDate.slice(5, 7) +
                            "-" +
                            data[8].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[8].FromDate === data[8].ToDate
                            ? " "
                            : data[8].ToDate.slice(8, 10) +
                              "/" +
                              data[8].ToDate.slice(5, 7) +
                              "-" +
                              data[8].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[7] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[7] && data[7].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[7] && data[7].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[7] && data[7].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[8] &&
                        Math.round(data[8].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[7].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[7].ConcessionRevenue -
                                  data[8].ConcessionRevenue) *
                                  100) /
                                  data[8].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[7].ConcessionRevenue -
                              data[8].ConcessionRevenue) *
                              100) /
                              data[8].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[7].ConcessionRevenue -
                              data[8].ConcessionRevenue) *
                              100) /
                              data[8].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[7].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[7].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[7] &&
                                Math.round(data[7].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[7] &&
                                Math.round(data[7].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.5,
                          height:
                            Math.round(
                              (Math.round(data[7].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[7].FromDate.slice(8, 10) +
                            "/" +
                            data[7].FromDate.slice(5, 7) +
                            "-" +
                            data[7].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[7].FromDate === data[7].ToDate
                            ? " "
                            : data[7].ToDate.slice(8, 10) +
                              "/" +
                              data[7].ToDate.slice(5, 7) +
                              "-" +
                              data[7].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[6] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[6] && data[6].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[6] && data[6].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[6] && data[6].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[7] &&
                        Math.round(data[7].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[6].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[6].ConcessionRevenue -
                                  data[7].ConcessionRevenue) *
                                  100) /
                                  data[7].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[6].ConcessionRevenue -
                              data[7].ConcessionRevenue) *
                              100) /
                              data[7].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[6].ConcessionRevenue -
                              data[7].ConcessionRevenue) *
                              100) /
                              data[7].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[6].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[6].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[6] &&
                                Math.round(data[6].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[6] &&
                                Math.round(data[6].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.55,
                          height:
                            Math.round(
                              (Math.round(data[6].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[6].FromDate.slice(8, 10) +
                            "/" +
                            data[6].FromDate.slice(5, 7) +
                            "-" +
                            data[6].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[6].FromDate === data[6].ToDate
                            ? " "
                            : data[6].ToDate.slice(8, 10) +
                              "/" +
                              data[6].ToDate.slice(5, 7) +
                              "-" +
                              data[6].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[5] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[5] && data[5].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[5] && data[5].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[5] && data[5].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[6] &&
                        Math.round(data[6].TicketRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[5].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[5].ConcessionRevenue -
                                  data[6].ConcessionRevenue) *
                                  100) /
                                  data[6].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[5].ConcessionRevenue -
                              data[6].ConcessionRevenue) *
                              100) /
                              data[6].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[5].ConcessionRevenue -
                              data[6].ConcessionRevenue) *
                              100) /
                              data[6].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[5].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[5].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[5] &&
                                Math.round(data[5].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[5] &&
                                Math.round(data[5].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.6,
                          height:
                            Math.round(
                              (Math.round(data[5].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[5].FromDate.slice(8, 10) +
                            "/" +
                            data[5].FromDate.slice(5, 7) +
                            "-" +
                            data[5].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[5].FromDate === data[5].ToDate
                            ? " "
                            : data[5].ToDate.slice(8, 10) +
                              "/" +
                              data[5].ToDate.slice(5, 7) +
                              "-" +
                              data[5].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[4] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[4] && data[4].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[4] && data[4].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[4] && data[4].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[5] &&
                        Math.round(data[5].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[4].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[4].ConcessionRevenue -
                                  data[5].ConcessionRevenue) *
                                  100) /
                                  data[5].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[4].ConcessionRevenue -
                              data[5].ConcessionRevenue) *
                              100) /
                              data[5].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[4].ConcessionRevenue -
                              data[5].ConcessionRevenue) *
                              100) /
                              data[5].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[4].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[4].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[4] &&
                                Math.round(data[4].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[4] &&
                                Math.round(data[4].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.65,
                          height:
                            Math.round(
                              (Math.round(data[4].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[4].FromDate.slice(8, 10) +
                            "/" +
                            data[4].FromDate.slice(5, 7) +
                            "-" +
                            data[4].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[4].FromDate === data[4].ToDate
                            ? " "
                            : data[4].ToDate.slice(8, 10) +
                              "/" +
                              data[4].ToDate.slice(5, 7) +
                              "-" +
                              data[4].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[3] && (
                    <div className="antalBar">
                      
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[3] && data[3].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[3] && data[3].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[3] && data[3].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[4] &&
                        Math.round(data[4].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[3].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[3].ConcessionRevenue -
                                  data[4].ConcessionRevenue) *
                                  100) /
                                  data[4].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[3].ConcessionRevenue -
                              data[4].ConcessionRevenue) *
                              100) /
                              data[4].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[3].ConcessionRevenue -
                              data[4].ConcessionRevenue) *
                              100) /
                              data[4].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[3].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[3].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[3] &&
                                Math.round(data[3].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[3] &&
                                Math.round(data[3].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.7,
                          height:
                            Math.round(
                              (Math.round(data[3].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[3].FromDate.slice(8, 10) +
                            "/" +
                            data[3].FromDate.slice(5, 7) +
                            "-" +
                            data[3].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[3].FromDate === data[3].ToDate
                            ? " "
                            : data[3].ToDate.slice(8, 10) +
                              "/" +
                              data[3].ToDate.slice(5, 7) +
                              "-" +
                              data[3].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[2] && (
                    <div className="antalBar">
                     
                      <span
                        className="orangeNr"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[2] && data[2].ConcessionRevenue
                              ) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[2] && data[2].ConcessionRevenue
                        ).toLocaleString("da")}
                      </span>
                      {Math.round(data[2] && data[2].ConcessionRevenue) <= 0 ? (
                        ""
                      ) : radioCheched === "1" &&
                        data[3] &&
                        Math.round(data[3].ConcessionRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[2].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[2].ConcessionRevenue -
                                  data[3].ConcessionRevenue) *
                                  100) /
                                  data[3].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[2].ConcessionRevenue -
                              data[3].ConcessionRevenue) *
                              100) /
                              data[3].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[2].ConcessionRevenue -
                              data[3].ConcessionRevenue) *
                              100) /
                              data[3].ConcessionRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].ConcessionRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (Math.round(data[2].ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[2].ConcessionRevenue -
                                    data[0].ConcessionRevenue) *
                                    100) /
                                    data[0].ConcessionRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[2] &&
                                Math.round(data[2].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[2] &&
                                Math.round(data[2].ConcessionRevenue) -
                                  Math.round(data[0].ConcessionRevenue)) *
                                100) /
                                Math.round(data[0].ConcessionRevenue)
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="kioskTal"
                        style={{
                          opacity: 0.75,
                          height:
                            Math.round(
                              (Math.round(data[2].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>

                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[2].FromDate.slice(8, 10) +
                            "/" +
                            data[2].FromDate.slice(5, 7) +
                            "-" +
                            data[2].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[2].FromDate === data[2].ToDate
                            ? " "
                            : data[2].ToDate.slice(8, 10) +
                              "/" +
                              data[2].ToDate.slice(5, 7) +
                              "-" +
                              data[2].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="antalBar">
                    <span
                      className="orangeNr"
                      style={{
                        bottom:
                          Math.round(
                            (Math.round(data[1].ConcessionRevenue) /
                              Math.round(maxKioskoms * 1.25)) *
                              100 +
                              3
                          ) + "%",
                      }}
                    >
                      {Math.round(data[1].ConcessionRevenue).toLocaleString(
                        "da"
                      )}
                    </span>
                    {Math.round(data[1].ConcessionRevenue) <= 0 ? (
                      ""
                    ) : radioCheched === "1" &&
                      data[2] &&
                      Math.round(data[2].ConcessionRevenue) > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[1].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data[1].ConcessionRevenue -
                                data[2].ConcessionRevenue) *
                                100) /
                                data[2].ConcessionRevenue
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(
                          ((data[1].ConcessionRevenue -
                            data[2].ConcessionRevenue) *
                            100) /
                            data[2].ConcessionRevenue
                        ) > 0
                          ? "+"
                          : ""}
                        {Math.round(
                          ((data[1].ConcessionRevenue -
                            data[2].ConcessionRevenue) *
                            100) /
                            data[2].ConcessionRevenue
                        ) + "%"}
                      </span>
                    ) : (
                      radioCheched === "0" &&
                      data[0].ConcessionRevenue > 0 && (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (Math.round(data[1].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[1].ConcessionRevenue -
                                  data[0].ConcessionRevenue) *
                                  100) /
                                  data[0].ConcessionRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[1] &&
                              Math.round(data[1].ConcessionRevenue) -
                                Math.round(data[0].ConcessionRevenue)) *
                              100) /
                              Math.round(data[0].ConcessionRevenue)
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[1] &&
                              Math.round(data[1].ConcessionRevenue) -
                                Math.round(data[0].ConcessionRevenue)) *
                              100) /
                              Math.round(data[0].ConcessionRevenue)
                          ) + "%"}
                        </span>
                      )
                    )}
                    <div
                      className="kioskTal"
                      style={{
                        opacity: 0.8,
                        height:
                          Math.round(
                            (Math.round(data[1].ConcessionRevenue) /
                              Math.round(maxKioskoms * 1.25)) *
                              100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                  <div className="antalBar">
                    <span
                      className="orangeNr"
                      style={{
                        bottom:
                          Math.round(
                            (Math.round(data[0].ConcessionRevenue) /
                              Math.round(maxKioskoms * 1.25)) *
                              100 +
                              3
                          ) + "%",
                      }}
                    >
                      {Math.round(data[0].ConcessionRevenue).toLocaleString(
                        "da"
                      )}
                    </span>
                    {Math.round(data[0].ConcessionRevenue) <= 0 ? (
                      ""
                    ) : radioCheched === "1" &&
                      data[1] &&
                      Math.round(data[1].ConcessionRevenue) > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(data[0].ConcessionRevenue) /
                                Math.round(maxKioskoms * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data[0].ConcessionRevenue -
                                data[1].ConcessionRevenue) *
                                100) /
                                data[1].ConcessionRevenue
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(
                          ((data[0].ConcessionRevenue -
                            data[1].ConcessionRevenue) *
                            100) /
                            data[1].ConcessionRevenue
                        ) > 0
                          ? "+"
                          : ""}
                        {Math.round(
                          ((data[0].ConcessionRevenue -
                            data[1].ConcessionRevenue) *
                            100) /
                            data[1].ConcessionRevenue
                        ) + "%"}
                      </span>
                    ) : (
                      radioCheched === "0" && (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(data[0].ConcessionRevenue) /
                                  Math.round(maxKioskoms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                    )}
                    <div
                      className="kioskTal"
                      style={{
                        height:
                          Math.round(
                            (Math.round(data[0].ConcessionRevenue) /
                              Math.round(maxKioskoms * 1.25)) *
                              100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              ) : ' ' }
              {/* </div> */}

              {/* <div> */}
              { pillarsPicked === false || (pillarsPicked === true && kioskomsPiller === 'true') ? (
              <div style={{ marginTop: data.length > 2 ? "25px" : "" }}>
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#46484c",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Kioskomsætning pr. solgt billet
                </p>
                <div
                  className="antalContainer"
                  style={{
                    justifyContent: data.length < 6 ? "" : "space-between",
                  }}
                >
                  {data[10] && (
                    <div className="antalBar">
                     
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[10].TicketCount <= 0 ||
                            data[10].ConcessionRevenue <= 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[10].ConcessionRevenue /
                                    data[10].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[10].TicketCount <= 0 ||
                        data[10].ConcessionRevenue <= 0
                          ? "0"
                          : Math.round(
                              data[10].ConcessionRevenue / data[10].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {data[10].TicketCount ||
                      data[10].ConcessionRevenue <= 0 ? (
                        ""
                      ) : Math.round(
                          data[10].ConcessionRevenue / data[10].TicketCount
                        ) <= 0 ? (
                        " "
                      ) : radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) ? (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              data[10].TicketCount <= 0 ||
                              data[10].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[10].ConcessionRevenue /
                                      data[10].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      3
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[10].ConcessionRevenue /
                                    data[10].TicketCount
                                ) -
                                  Math.round(
                                    data[0].ConcessionRevenue /
                                      data[0].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[0].ConcessionRevenue /
                                      data[0].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[10].ConcessionRevenue / data[10].TicketCount
                            ) -
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((Math.round(
                              data[10].ConcessionRevenue / data[10].TicketCount
                            ) -
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.35,
                          height:
                            Math.round(
                              data[10].TicketCount <= 0 ||
                                data[10].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[10].ConcessionRevenue /
                                      data[10].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[10].FromDate.slice(8, 10) +
                            "/" +
                            data[10].FromDate.slice(5, 7) +
                            "-" +
                            data[10].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[10].FromDate === data[10].ToDate
                            ? " "
                            : data[10].ToDate.slice(8, 10) +
                              "/" +
                              data[10].ToDate.slice(5, 7) +
                              "-" +
                              data[10].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[9] && (
                    <div className="antalBar">
                     
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            (data[9] && data[9].TicketCount <= 0) ||
                            data[9].ConcessionRevenue <= 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[9] &&
                                    data[9].ConcessionRevenue / data[9] &&
                                    data[9].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {(data[9] && data[9].TicketCount <= 0) ||
                        (data[9] && data[9].ConcessionRevenue <= 0)
                          ? "0"
                          : Math.round(
                              data[9] &&
                                data[9].ConcessionRevenue / data[9] &&
                                data[9].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {(data[9] && data[9].TicketCount) ||
                      (data[9] && data[9].ConcessionRevenue <= 0) ? (
                        ""
                      ) : Math.round(
                          data[9] &&
                            data[9].ConcessionRevenue / data[9] &&
                            data[9].TicketCount
                        ) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[10].TicketCount > 0 &&
                        data[10].ConcessionRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[9].TicketCount <= 0 ||
                              data[9].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[9].ConcessionRevenue /
                                      data[9].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      3
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[9].ConcessionRevenue /
                                    data[9].TicketCount
                                ) -
                                  Math.round(
                                    data[10].ConcessionRevenue /
                                      data[10].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[10].ConcessionRevenue /
                                      data[10].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[9].ConcessionRevenue / data[9].TicketCount
                            ) -
                              Math.round(
                                data[10].ConcessionRevenue /
                                  data[10].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[10].ConcessionRevenue /
                                  data[10].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[9].TicketCount === 0 ||
                                    data[9].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[9].ConcessionRevenue /
                                      data[9].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[9].ConcessionRevenue / data[9].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[9].ConcessionRevenue / data[9].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.4,
                          height:
                            Math.round(
                              data[9].TicketCount <= 0 ||
                                data[9].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[9].ConcessionRevenue /
                                      data[9].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[9].FromDate.slice(8, 10) +
                            "/" +
                            data[9].FromDate.slice(5, 7) +
                            "-" +
                            data[9].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[9].FromDate === data[9].ToDate
                            ? " "
                            : data[9].ToDate.slice(8, 10) +
                              "/" +
                              data[9].ToDate.slice(5, 7) +
                              "-" +
                              data[9].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[8] && (
                    <div className="antalBar">
                     
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[8].TicketCount <= 0 ||
                            data[8].ConcessionRevenue <= 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[8].ConcessionRevenue /
                                    data[8].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[8].TicketCount <= 0 ||
                        data[8].ConcessionRevenue <= 0
                          ? "0"
                          : Math.round(
                              data[8].ConcessionRevenue / data[8].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {data[8].TicketCount || data[8].ConcessionRevenue <= 0 ? (
                        ""
                      ) : Math.round(
                          data[8].ConcessionRevenue / data[8].TicketCount
                        ) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[9].TicketCount > 0 &&
                        data[9].ConcessionRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[8].TicketCount <= 0 ||
                              data[8].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[8].ConcessionRevenue /
                                      data[8].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      3
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[8].ConcessionRevenue /
                                    data[8].TicketCount
                                ) -
                                  Math.round(
                                    data[9].ConcessionRevenue /
                                      data[9].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[9].ConcessionRevenue /
                                      data[9].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[8].ConcessionRevenue / data[8].TicketCount
                            ) -
                              Math.round(
                                data[9].ConcessionRevenue / data[9].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[9].ConcessionRevenue / data[9].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[8].TicketCount === 0 ||
                                    data[8].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[8].ConcessionRevenue /
                                      data[8].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[8].ConcessionRevenue / data[8].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[8].ConcessionRevenue / data[8].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.45,
                          height:
                            Math.round(
                              data[8].TicketCount <= 0 ||
                                data[8].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[8].ConcessionRevenue /
                                      data[8].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[8].FromDate.slice(8, 10) +
                            "/" +
                            data[8].FromDate.slice(5, 7) +
                            "-" +
                            data[8].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[8].FromDate === data[8].ToDate
                            ? " "
                            : data[8].ToDate.slice(8, 10) +
                              "/" +
                              data[8].ToDate.slice(5, 7) +
                              "-" +
                              data[8].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[7] && (
                    <div className="antalBar">
                     
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[7].TicketCount === 0 ||
                            data[7].ConcessionRevenue === 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[7].ConcessionRevenue /
                                    data[7].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[7].TicketCount <= 0 ||
                        data[7].ConcessionRevenue <= 0
                          ? "0"
                          : Math.round(
                              data[7].ConcessionRevenue / data[7].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {data[7].TicketCount || data[7].ConcessionRevenue <= 0 ? (
                        ""
                      ) : Math.round(
                          data[7].ConcessionRevenue / data[7].TicketCount
                        ) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[8].TicketCount > 0 &&
                        data[8].ConcessionRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[7].TicketCount <= 0 ||
                              data[7].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[7].ConcessionRevenue /
                                      data[7].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      3
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[7].ConcessionRevenue /
                                    data[7].TicketCount
                                ) -
                                  Math.round(
                                    data[8].ConcessionRevenue /
                                      data[8].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[8].ConcessionRevenue /
                                      data[8].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[7].ConcessionRevenue / data[7].TicketCount
                            ) -
                              Math.round(
                                data[8].ConcessionRevenue / data[8].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[8].ConcessionRevenue / data[8].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[7].TicketCount === 0 ||
                                    data[7].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[7].ConcessionRevenue /
                                      data[7].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[7].ConcessionRevenue / data[7].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[7].ConcessionRevenue / data[7].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.5,
                          height:
                            Math.round(
                              data[7].TicketCount <= 0 ||
                                data[7].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[7].ConcessionRevenue /
                                      data[7].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[7].FromDate.slice(8, 10) +
                            "/" +
                            data[7].FromDate.slice(5, 7) +
                            "-" +
                            data[7].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[7].FromDate === data[7].ToDate
                            ? " "
                            : data[7].ToDate.slice(8, 10) +
                              "/" +
                              data[7].ToDate.slice(5, 7) +
                              "-" +
                              data[7].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[6] && (
                    <div className="antalBar">
                      
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[6].TicketCount <= 0 ||
                            data[6].ConcessionRevenue <= 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[6].ConcessionRevenue /
                                    data[6].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[6].TicketCount <= 0 ||
                        data[6].ConcessionRevenue <= 0
                          ? "0"
                          : Math.round(
                              data[6].ConcessionRevenue / data[6].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {data[6].TicketCount || data[6].ConcessionRevenue <= 0 ? (
                        ""
                      ) : Math.round(
                          data[6].ConcessionRevenue / data[6].TicketCount
                        ) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[7].TicketCount > 0 &&
                        data[7].ConcessionRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[6].TicketCount <= 0 ||
                              data[6].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[6].ConcessionRevenue /
                                      data[6].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      3
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[6].ConcessionRevenue /
                                    data[6].TicketCount
                                ) -
                                  Math.round(
                                    data[7].ConcessionRevenue /
                                      data[7].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[7].ConcessionRevenue /
                                      data[7].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[6].ConcessionRevenue / data[6].TicketCount
                            ) -
                              Math.round(
                                data[7].ConcessionRevenue / data[7].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[7].ConcessionRevenue / data[7].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[6].TicketCount === 0 ||
                                    data[6].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[6].ConcessionRevenue /
                                      data[6].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[6].ConcessionRevenue / data[6].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[6].ConcessionRevenue / data[6].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.55,
                          height:
                            Math.round(
                              data[6].TicketCount <= 0 ||
                                data[6].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[6].ConcessionRevenue /
                                      data[6].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[6].FromDate.slice(8, 10) +
                            "/" +
                            data[6].FromDate.slice(5, 7) +
                            "-" +
                            data[6].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[6].FromDate === data[6].ToDate
                            ? " "
                            : data[6].ToDate.slice(8, 10) +
                              "/" +
                              data[6].ToDate.slice(5, 7) +
                              "-" +
                              data[6].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[5] && (
                    <div className="antalBar">
                      
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[5].TicketCount <= 0 ||
                            data[5].ConcessionRevenue <= 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[5].ConcessionRevenue /
                                    data[5].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[5].TicketCount <= 0 ||
                        data[5].ConcessionRevenue <= 0
                          ? "0"
                          : Math.round(
                              data[5].ConcessionRevenue / data[5].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {data[5].TicketCount || data[5].ConcessionRevenue <= 0 ? (
                        ""
                      ) : Math.round(
                          data[5].ConcessionRevenue / data[5].TicketCount
                        ) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[6].TicketCount > 0 &&
                        data[6].ConcessionRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[5].TicketCount <= 0 ||
                              data[5].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[5].ConcessionRevenue /
                                      data[5].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      3
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[5].ConcessionRevenue /
                                    data[5].TicketCount
                                ) -
                                  Math.round(
                                    data[6].ConcessionRevenue /
                                      data[6].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[6].ConcessionRevenue /
                                      data[6].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[5].ConcessionRevenue / data[5].TicketCount
                            ) -
                              Math.round(
                                data[6].ConcessionRevenue / data[6].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[6].ConcessionRevenue / data[6].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[5].TicketCount === 0 ||
                                    data[5].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[5].ConcessionRevenue /
                                      data[5].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[5].ConcessionRevenue / data[5].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[5].ConcessionRevenue / data[5].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.6,
                          height:
                            Math.round(
                              data[5].TicketCount <= 0 ||
                                data[5].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[5].ConcessionRevenue /
                                      data[5].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[5].FromDate.slice(8, 10) +
                            "/" +
                            data[5].FromDate.slice(5, 7) +
                            "-" +
                            data[5].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[5].FromDate === data[5].ToDate
                            ? " "
                            : data[5].ToDate.slice(8, 10) +
                              "/" +
                              data[5].ToDate.slice(5, 7) +
                              "-" +
                              data[5].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[4] && (
                    <div className="antalBar">
                    
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[4].TicketCount <= 0 ||
                            data[4].ConcessionRevenue <= 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[4].ConcessionRevenue /
                                    data[4].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[4] && data[4].TicketCount <= 0
                          ? "0"
                          : Math.round(
                              data[4].ConcessionRevenue / data[4].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {(data[4].TicketCount <= 0 ||
                      data[4].ConcessionRevenue <= 0
                        ? "0"
                        : Math.round(
                            data[4].ConcessionRevenue / data[4].TicketCount
                          )) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[5] &&
                        data[5].TicketCount > 0 &&
                        data[5].ConcessionRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[4].TicketCount <= 0 ||
                              data[4].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[4].ConcessionRevenue /
                                      data[4].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      0
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[4].ConcessionRevenue /
                                    data[4].TicketCount
                                ) -
                                  Math.round(
                                    data[5].ConcessionRevenue /
                                      data[5].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[5].ConcessionRevenue /
                                      data[5].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[4].ConcessionRevenue / data[4].TicketCount
                            ) -
                              Math.round(
                                data[5].ConcessionRevenue / data[5].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[5].ConcessionRevenue / data[5].TicketCount
                              )
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        (data[0].ConcessionRevenue > 0 ||
                          data[0].TicketCount > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[4].TicketCount === 0 ||
                                    data[4].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[4].ConcessionRevenue /
                                      data[4].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[4].ConcessionRevenue / data[4].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[4].ConcessionRevenue / data[4].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.65,
                          height:
                            Math.round(
                              data[4].TicketCount <= 0 ||
                                data[4].ConcessionRevenue <= 0
                                ? 0
                                : (Math.round(
                                    data[4].ConcessionRevenue /
                                      data[4].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[4].FromDate.slice(8, 10) +
                            "/" +
                            data[4].FromDate.slice(5, 7) +
                            "-" +
                            data[4].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[4].FromDate === data[4].ToDate
                            ? " "
                            : data[4].ToDate.slice(8, 10) +
                              "/" +
                              data[4].ToDate.slice(5, 7) +
                              "-" +
                              data[4].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[3] && (
                    <div className="antalBar">
                      
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            Math.round(
                              data[3].TicketCount === 0 ||
                                data[3].ConcessionRevenue === 0
                                ? 0
                                : (Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                            ) + "%",
                        }}
                      >
                        {data[3] && data[3].TicketCount === 0
                          ? "0"
                          : Math.round(
                              data[3].ConcessionRevenue / data[3].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {data[3] &&
                      (data[3].TicketCount <= 0 ||
                      data[3].ConcessionRevenue <= 0
                        ? "0"
                        : Math.round(
                            data[3].ConcessionRevenue / data[3] &&
                              data[3].TicketCount
                          )) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[4] &&
                        data[4].TicketCount > 0 &&
                        data[4].ConcessionRevenue ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                data[3].TicketCount === 0 ||
                                  data[3].ConcessionRevenue === 0
                                  ? 0
                                  : (Math.round(
                                      data[3].ConcessionRevenue /
                                        data[3].TicketCount
                                    ) /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[3].ConcessionRevenue /
                                    data[3].TicketCount
                                ) -
                                  Math.round(
                                    data[4].ConcessionRevenue /
                                      data[4].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[4].ConcessionRevenue /
                                      data[4].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {data[4].TicketCount > 0
                            ? Math.round(
                                ((Math.round(
                                  data[3].ConcessionRevenue /
                                    data[3].TicketCount
                                ) -
                                  Math.round(
                                    data[4].ConcessionRevenue /
                                      data[4].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[4].ConcessionRevenue /
                                      data[4].TicketCount
                                  )
                              ) + "%"
                            : "0"}
                        </span>
                      ) : (
                        (radioCheched === "0" &&
                        (data[0].TicketCount <= 0 ||
                          data[0].ConcessionRevenue <= 0)
                          ? "0"
                          : Math.round(
                              data[0].ConcessionRevenue / data[0].TicketCount
                            ) > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  data[3].TicketCount === 0 ||
                                    data[3].ConcessionRevenue === 0
                                    ? 0
                                    : (Math.round(
                                        data[3].ConcessionRevenue /
                                          data[3].TicketCount
                                      ) /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[3].ConcessionRevenue / data[3].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[3].ConcessionRevenue / data[3].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.7,
                          height:
                            Math.round(
                              data[3].TicketCount === 0 ||
                                data[3].ConcessionRevenue === 0
                                ? 0
                                : (Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[3].FromDate.slice(8, 10) +
                            "/" +
                            data[3].FromDate.slice(5, 7) +
                            "-" +
                            data[3].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[3].FromDate === data[3].ToDate
                            ? " "
                            : data[3].ToDate.slice(8, 10) +
                              "/" +
                              data[3].ToDate.slice(5, 7) +
                              "-" +
                              data[3].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[2] && (
                    <div className="antalBar">
                     
                      <span
                        className="purpleNr"
                        style={{
                          bottom:
                            data[2].TicketCount === 0 ||
                            data[2].ConcessionRevenue === 0
                              ? 5 + "%"
                              : Math.round(
                                  (data[2].ConcessionRevenue /
                                    data[2].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    5
                                ) + "%",
                        }}
                      >
                        {data[2].TicketCount === 0
                          ? "0"
                          : Math.round(
                              data[2].ConcessionRevenue / data[2].TicketCount
                            ).toLocaleString("da")}
                      </span>

                      {(data[2].TicketCount <= 0 ||
                      data[2].ConcessionRevenue <= 0
                        ? "0"
                        : Math.round(
                            data[2].ConcessionRevenue / data[2].TicketCount
                          )) <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[3].TicketCount > 0 &&
                        data[3].ConcessionRevenue ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              data[2].TicketCount <= 0 ||
                              data[2].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[2].ConcessionRevenue /
                                      data[2].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      0
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                ) -
                                  Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {data[3].TicketCount > 0
                            ? Math.round(
                                ((Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                ) -
                                  Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[3].ConcessionRevenue /
                                      data[3].TicketCount
                                  )
                              ) + "%"
                            : "0"}
                        </span>
                      ) : (
                        (radioCheched === "0" &&
                        (data[0].TicketCount <= 0 ||
                          data[0].ConcessionRevenue <= 0)
                          ? "0"
                          : Math.round(
                              data[0].ConcessionRevenue / data[0].TicketCount
                            ) > 0) && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                data[2].TicketCount <= 0 ||
                                data[2].ConcessionRevenue <= 0
                                  ? 0
                                  : Math.round(
                                      (data[2].ConcessionRevenue /
                                        data[2].TicketCount /
                                        Math.round(maxKioskPerBillet * 1.25)) *
                                        100
                                    ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((Math.round(
                                    data[2].ConcessionRevenue /
                                      data[2].TicketCount
                                  ) -
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )) *
                                    100) /
                                    Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    )
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((Math.round(
                                data[2].ConcessionRevenue / data[2].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((Math.round(
                                data[2].ConcessionRevenue / data[2].TicketCount
                              ) -
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                )
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="kioskomsTal"
                        style={{
                          opacity: 0.75,
                          height:
                            Math.round(
                              data[2].TicketCount === 0 ||
                                data[2].ConcessionRevenue === 0
                                ? 0
                                : (Math.round(
                                    data[2].ConcessionRevenue /
                                      data[2].TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 +
                                    3
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[2].FromDate.slice(8, 10) +
                            "/" +
                            data[2].FromDate.slice(5, 7) +
                            "-" +
                            data[2].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[2].FromDate === data[2].ToDate
                            ? " "
                            : data[2].ToDate.slice(8, 10) +
                              "/" +
                              data[2].ToDate.slice(5, 7) +
                              "-" +
                              data[2].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="antalBar">
                    <span
                      className="purpleNr"
                      style={{
                        bottom:
                          data[1].TicketCount === 0 ||
                          data[1].ConcessionRevenue === 0
                            ? 5 + "%"
                            : Math.round(
                                (data[1].ConcessionRevenue /
                                  data[1].TicketCount /
                                  Math.round(maxKioskPerBillet * 1.25)) *
                                  100 +
                                  5
                              ) + "%",
                      }}
                    >
                      {data[1] && data[1].TicketCount === 0
                        ? "0"
                        : Math.round(
                            data[1].ConcessionRevenue / data[1].TicketCount
                          ).toLocaleString("da")}
                    </span>

                    {(data[1].TicketCount <= 0 || data[1].ConcessionRevenue <= 0
                      ? "0"
                      : Math.round(
                          data[1].ConcessionRevenue / data[1].TicketCount
                        )) <= 0 ? (
                      " "
                    ) : radioCheched === "1" &&
                      data[2] &&
                      data[2].TicketCount > 0 &&
                      data[2].ConcessionRevenue ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            data[1].TicketCount <= 0 ||
                            data[1].ConcessionRevenue <= 0
                              ? 0
                              : Math.round(
                                  (data[1].ConcessionRevenue /
                                    data[1].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 -
                                    0
                                ) + "%",
                          backgroundColor:
                            Math.round(
                              ((Math.round(
                                data[1].ConcessionRevenue / data[1].TicketCount
                              ) -
                                Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                )
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {data[2].TicketCount > 0
                          ? Math.round(
                              ((Math.round(
                                data[1].ConcessionRevenue / data[1].TicketCount
                              ) -
                                Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                )
                            ) + "%"
                          : "0"}
                      </span>
                    ) : (
                      (radioCheched === "0" &&
                      (data[0].TicketCount <= 0 ||
                        data[0].ConcessionRevenue <= 0)
                        ? "0"
                        : Math.round(
                            data[0].ConcessionRevenue / data[0].TicketCount
                          ) > 0) && (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              data[1].TicketCount <= 0 ||
                              data[1].ConcessionRevenue <= 0
                                ? 0
                                : Math.round(
                                    (data[1].ConcessionRevenue /
                                      data[1].TicketCount /
                                      Math.round(maxKioskPerBillet * 1.25)) *
                                      100 -
                                      0
                                  ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                ) -
                                  Math.round(
                                    data[0].ConcessionRevenue /
                                      data[0].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    data[0].ConcessionRevenue /
                                      data[0].TicketCount
                                  )
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[1].ConcessionRevenue / data[1].TicketCount
                            ) -
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((Math.round(
                              data[1].ConcessionRevenue / data[1].TicketCount
                            ) -
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )) *
                              100) /
                              Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              )
                          ) + "%"}
                        </span>
                      )
                    )}

                    <div
                      className="kioskomsTal"
                      style={{
                        opacity: 0.8,
                        height:
                          Math.round(
                            data[1].TicketCount === 0 ||
                              data[1].ConcessionRevenue === 0
                              ? 0
                              : (Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                ) /
                                  Math.round(maxKioskPerBillet * 1.25)) *
                                  100 +
                                  3
                          ) + "%",
                      }}
                    ></div>
                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>

                  <div className="antalBar">
                    <span
                      className="purpleNr"
                      style={{
                        bottom:
                          data[0].TicketCount <= 0 ||
                          data[0].ConcessionRevenue <= 0
                            ? 5 + "%"
                            : Math.round(
                                (data[0].ConcessionRevenue /
                                  data[0].TicketCount /
                                  Math.round(maxKioskPerBillet * 1.25)) *
                                  100 +
                                  5
                              ) + "%",
                      }}
                    >
                      {data[0].TicketCount <= 0 ||
                      data[0].ConcessionRevenue <= 0
                        ? "0"
                        : Math.round(
                            data[0].ConcessionRevenue / data[0].TicketCount
                          ).toLocaleString("da")}
                    </span>

                    {(data[0].TicketCount <= 0 || data[0].ConcessionRevenue <= 0
                      ? "0"
                      : Math.round(
                          data[0].ConcessionRevenue / data[0].TicketCount
                        )) <= 0 ? (
                      " "
                    ) : radioCheched === "1" && data[1].TicketCount > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            data[0].TicketCount <= 0 ||
                            data[0].ConcessionRevenue <= 0
                              ? 0
                              : Math.round(
                                  (data[0].ConcessionRevenue /
                                    data[0].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 -
                                    0
                                ) + "%",
                          backgroundColor:
                            Math.round(
                              ((Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              ) -
                                Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                )
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {data[1].TicketCount > 0
                          ? Math.round(
                              ((Math.round(
                                data[0].ConcessionRevenue / data[0].TicketCount
                              ) -
                                Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                )) *
                                100) /
                                Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                )
                            ) + "%"
                          : "0"}
                      </span>
                    ) : (
                      <span
                        className="thisYear"
                        style={{
                          bottom:
                            data[0].TicketCount <= 0 ||
                            data[0].ConcessionRevenue <= 0
                              ? 0
                              : Math.round(
                                  (data[0].ConcessionRevenue /
                                    data[0].TicketCount /
                                    Math.round(maxKioskPerBillet * 1.25)) *
                                    100 -
                                    0
                                ) + "%",
                        }}
                      >
                        I år
                      </span>
                    )}

                    <div
                      className="kioskomsTal"
                      style={{
                        height:
                          Math.round(
                            data[0].TicketCount <= 0 ||
                              data[0].ConcessionRevenue <= 0
                              ? 0
                              : (Math.round(
                                  data[0].ConcessionRevenue /
                                    data[0].TicketCount
                                ) /
                                  Math.round(maxKioskPerBillet * 1.25)) *
                                  100 +
                                  3
                          ) + "%",
                      }}
                    ></div>
                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              ) : ' ' }

{ pillarsPicked === false || (pillarsPicked === true && otherPiller === 'true') ? (
              <div>
                {" "}
                <br />
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#46484c",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Anden omsætning
                </p>
                <div
                  className="antalContainer"
                  style={{
                    justifyContent: data.length < 6 ? "" : "space-between",
                  }}
                >
                  {data[10] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[10].OtherRevenue /
                                Math.round(maxAntalBillter * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[10].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[10].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "0" ? (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (data[10].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[10].OtherRevenue -
                                  data[0].OtherRevenue) *
                                  100) /
                                  data[0].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[10].OtherRevenue - data[0].OtherRevenue) *
                              100) /
                              data[0].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[10].OtherRevenue - data[0].OtherRevenue) *
                              100) /
                              data[0].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.35,
                          height:
                            Math.round(
                              (data[10].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[10].FromDate.slice(8, 10) +
                            "/" +
                            data[10].FromDate.slice(5, 7) +
                            "-" +
                            data[10].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[10].FromDate === data[10].ToDate
                            ? " "
                            : data[10].ToDate.slice(8, 10) +
                              "/" +
                              data[10].ToDate.slice(5, 7) +
                              "-" +
                              data[10].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[9] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[9] &&
                                data[9].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[9] && data[9].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[9] && data[9].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[10] &&
                        data[10].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[9].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[9].OtherRevenue -
                                  data[10].OtherRevenue) *
                                  100) /
                                  data[10].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[9].OtherRevenue - data[10].OtherRevenue) *
                              100) /
                              data[10].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[9].OtherRevenue - data[10].OtherRevenue) *
                              100) /
                              data[10].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[9].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[9].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[9].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[9].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.4,
                          height:
                            Math.round(
                              (data[9].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[9].FromDate.slice(8, 10) +
                            "/" +
                            data[9].FromDate.slice(5, 7) +
                            "-" +
                            data[9].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[9].FromDate === data[9].ToDate
                            ? " "
                            : data[9].ToDate.slice(8, 10) +
                              "/" +
                              data[9].ToDate.slice(5, 7) +
                              "-" +
                              data[9].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[8] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[8] &&
                                data[8].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[8] && data[8].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[8] && data[8].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[9] &&
                        data[9].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[8].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[8].OtherRevenue - data[9].OtherRevenue) *
                                  100) /
                                  data[9].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[8].OtherRevenue - data[9].OtherRevenue) *
                              100) /
                              data[9].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[8].OtherRevenue - data[9].OtherRevenue) *
                              100) /
                              data[9].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[8].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[8].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[8].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].TicketCount
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[8].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.45,
                          height:
                            Math.round(
                              (data[8].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[8].FromDate.slice(8, 10) +
                            "/" +
                            data[8].FromDate.slice(5, 7) +
                            "-" +
                            data[8].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[8].FromDate === data[8].ToDate
                            ? " "
                            : data[8].ToDate.slice(8, 10) +
                              "/" +
                              data[8].ToDate.slice(5, 7) +
                              "-" +
                              data[8].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[7] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[7] &&
                                data[7].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[7] && data[7].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[7] && data[7].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[8] &&
                        data[8].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[7].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[7].OtherRevenue - data[8].OtherRevenue) *
                                  100) /
                                  data[8].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[7].OtherRevenue - data[8].OtherRevenue) *
                              100) /
                              data[8].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[7].OtherRevenue - data[8].OtherRevenue) *
                              100) /
                              data[8].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[7].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[7].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[7].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[7].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.5,
                          height:
                            Math.round(
                              (data[7].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[7].FromDate.slice(8, 10) +
                            "/" +
                            data[7].FromDate.slice(5, 7) +
                            "-" +
                            data[7].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[7].FromDate === data[7].ToDate
                            ? " "
                            : data[7].ToDate.slice(8, 10) +
                              "/" +
                              data[7].ToDate.slice(5, 7) +
                              "-" +
                              data[7].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[6] && (
                    <div className="antalBar">
                      
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[6] &&
                                data[6].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[6] && data[6].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[6] && data[6].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[7] &&
                        data[7].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[6].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[6].OtherRevenue - data[7].OtherRevenue) *
                                  100) /
                                  data[7].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[6].OtherRevenue - data[7].OtherRevenue) *
                              100) /
                              data[7].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[6].OtherRevenue - data[7].OtherRevenue) *
                              100) /
                              data[7].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[6].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[6].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[6].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[6].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.55,
                          height:
                            Math.round(
                              (data[6].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[6].FromDate.slice(8, 10) +
                            "/" +
                            data[6].FromDate.slice(5, 7) +
                            "-" +
                            data[6].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[6].FromDate === data[6].ToDate
                            ? " "
                            : data[6].ToDate.slice(8, 10) +
                              "/" +
                              data[6].ToDate.slice(5, 7) +
                              "-" +
                              data[6].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[5] && (
                    <div className="antalBar">
                      
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[5] &&
                                data[5].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[5] && data[5].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[5] && data[5].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[6] &&
                        data[6].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[5].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[5].OtherRevenue - data[6].OtherRevenue) *
                                  100) /
                                  data[6].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[5].OtherRevenue - data[6].OtherRevenue) *
                              100) /
                              data[6].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[5].OtherRevenue - data[6].OtherRevenue) *
                              100) /
                              data[6].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[5].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[5].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[5].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[5].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.6,
                          height:
                            Math.round(
                              (data[5].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[5].FromDate.slice(8, 10) +
                            "/" +
                            data[5].FromDate.slice(5, 7) +
                            "-" +
                            data[5].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[5].FromDate === data[5].ToDate
                            ? " "
                            : data[5].ToDate.slice(8, 10) +
                              "/" +
                              data[5].ToDate.slice(5, 7) +
                              "-" +
                              data[5].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[4] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[4] &&
                                data[4].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[4] && data[4].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[4] && data[4].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[5] &&
                        data[5].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[4].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[4].OtherRevenue - data[5].OtherRevenue) *
                                  100) /
                                  data[5].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[4].OtherRevenue - data[5].OtherRevenue) *
                              100) /
                              data[5].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[4].OtherRevenue - data[5].OtherRevenue) *
                              100) /
                              data[5].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[4].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[4].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[4].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[4].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.65,
                          height:
                            Math.round(
                              (data[4].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[4].FromDate.slice(8, 10) +
                            "/" +
                            data[4].FromDate.slice(5, 7) +
                            "-" +
                            data[4].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[4].FromDate === data[4].ToDate
                            ? " "
                            : data[4].ToDate.slice(8, 10) +
                              "/" +
                              data[4].ToDate.slice(5, 7) +
                              "-" +
                              data[4].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[3] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[3] &&
                                data[3].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[3] && data[3].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[3] && data[3].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        (data[4] && data[4].OtherRevenue) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[3].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[3].OtherRevenue - data[4].OtherRevenue) *
                                  100) /
                                  data[4].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {" "}
                          {Math.round(
                            ((data[3].OtherRevenue - data[4].OtherRevenue) *
                              100) /
                              data[4].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[3].OtherRevenue - data[4].OtherRevenue) *
                              100) /
                              data[4].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[3].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[3].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[3].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[3].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.7,
                          height:
                            Math.round(
                              (data[3].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[3].FromDate.slice(8, 10) +
                            "/" +
                            data[3].FromDate.slice(5, 7) +
                            "-" +
                            data[3].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[3].FromDate === data[3].ToDate
                            ? " "
                            : data[3].ToDate.slice(8, 10) +
                              "/" +
                              data[3].ToDate.slice(5, 7) +
                              "-" +
                              data[3].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[2] && (
                    <div className="antalBar">
                     
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[2] &&
                                data[2].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[2] && data[2].OtherRevenue.toLocaleString("da")}
                      </span>

                      {data[2] && data[2].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[3] &&
                        data[3].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[2].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[2].OtherRevenue - data[3].OtherRevenue) *
                                  100) /
                                  data[3].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[2].OtherRevenue - data[3].OtherRevenue) *
                              100) /
                              data[3].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[2].OtherRevenue - data[3].OtherRevenue) *
                              100) /
                              data[3].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[2].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[2].OtherRevenue -
                                    data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[2].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[2].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.75,
                          height:
                            Math.round(
                              (data[2].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[2].FromDate.slice(8, 10) +
                            "/" +
                            data[2].FromDate.slice(5, 7) +
                            "-" +
                            data[2].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[2].FromDate === data[2].ToDate
                            ? " "
                            : data[2].ToDate.slice(8, 10) +
                              "/" +
                              data[2].ToDate.slice(5, 7) +
                              "-" +
                              data[2].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[1] && (
                    <div className="antalBar">
                      <span
                        className="yellowNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[1] &&
                                data[1].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[1] && data[1].OtherRevenue.toLocaleString("da")}
                      </span>
                      {data[1] && data[1].OtherRevenue <= 0 ? (
                        " "
                      ) : radioCheched === "1" &&
                        data[2] &&
                        data[2].OtherRevenue > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data[1].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data[1].OtherRevenue - data[2].OtherRevenue) *
                                  100) /
                                  data[2].OtherRevenue
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data[1].OtherRevenue - data[2].OtherRevenue) *
                              100) /
                              data[2].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[1].OtherRevenue - data[2].OtherRevenue) *
                              100) /
                              data[2].OtherRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data[0].OtherRevenue > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data[1].OtherRevenue /
                                    Math.round(maxAndenOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[1] &&
                                    data[1].OtherRevenue -
                                      data[0].OtherRevenue) *
                                    100) /
                                    data[0].OtherRevenue
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[1] &&
                                data[1].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[1] &&
                                data[1].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="andenOmsTal"
                        style={{
                          opacity: 0.8,
                          height:
                            Math.round(
                              (data[1].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[1].FromDate.slice(8, 10) +
                            "/" +
                            data[1].FromDate.slice(5, 7) +
                            "-" +
                            data[1].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[1].FromDate === data[1].ToDate
                            ? " "
                            : data[1].ToDate.slice(8, 10) +
                              "/" +
                              data[1].ToDate.slice(5, 7) +
                              "-" +
                              data[1].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="antalBar">
                    <span
                      className="yellowNr"
                      style={{
                        bottom:
                          Math.round(
                            (data[0].OtherRevenue /
                              Math.round(maxAndenOms * 1.25)) *
                              100 +
                              3
                          ) + "%",
                      }}
                    >
                      {data[0].OtherRevenue.toLocaleString("da")}
                    </span>

                    {data[0].OtherRevenue <= 0 ? (
                      " "
                    ) : radioCheched === "1" && data[1].OtherRevenue > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (data[0].OtherRevenue /
                                Math.round(maxAndenOms * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data[0].OtherRevenue - data[1].OtherRevenue) *
                                100) /
                                data[1].OtherRevenue
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(
                          ((data[0].OtherRevenue - data[1].OtherRevenue) *
                            100) /
                            data[1].OtherRevenue
                        ) > 0
                          ? "+"
                          : ""}
                        {data[1].OtherRevenue > 0
                          ? Math.round(
                              ((data[0].OtherRevenue - data[1].OtherRevenue) *
                                100) /
                                data[1].OtherRevenue
                            ) + "%"
                          : ""}
                      </span>
                    ) : (
                      radioCheched === "0" && (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].OtherRevenue /
                                  Math.round(maxAndenOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                    )}

                    <div
                      className="andenOmsTal"
                      style={{
                        height:
                          Math.round(
                            (data[0].OtherRevenue /
                              Math.round(maxAndenOms * 1.25)) *
                              100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
 ) : ' ' }
  { pillarsPicked === false || (pillarsPicked === true && totalPiller === 'true') ? (
              <div>
                {" "}
                <br />
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#46484c",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Samlet omsætning
                </p>
                <div
                  className="antalContainer"
                  style={{
                    justifyContent: data.length < 6 ? "" : "space-between",
                  }}
                >
                  {data[10] && (
                    <div className="antalBar">
                      {/* { data10sam = Math.round(data[10].ConcessionRevenue + data[10].OtherRevenue + data[10].TicketRevenue) } */}
                    
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data10sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data10sam.toLocaleString("da")}
                      </span>
                      {data10sam <= 0 ? (
                        " "
                      ) : radioCheched === "0" ? (
                        <span
                          className="thisYear"
                          style={{
                            color: "white",
                            bottom:
                              Math.round(
                                (data10sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data10sam - data0sam) * 100) / data0sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data10sam - data0sam) * 100) / data0sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data10sam - data0sam) * 100) / data0sam
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.35,
                          height:
                            Math.round(
                              (data10sam / Math.round(maxSamletOms * 1.25)) *
                                100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[10].FromDate.slice(8, 10) +
                            "/" +
                            data[10].FromDate.slice(5, 7) +
                            "-" +
                            data[10].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[10].FromDate === data[10].ToDate
                            ? " "
                            : data[10].ToDate.slice(8, 10) +
                              "/" +
                              data[10].ToDate.slice(5, 7) +
                              "-" +
                              data[10].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[9] && (
                    <div className="antalBar">
                      {/* {data9sam = Math.round(data[9].ConcessionRevenue + data[9].OtherRevenue + data[9].TicketRevenue)} */}
                      
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[9] &&
                                data9sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[9] && data9sam.toLocaleString("da")}
                      </span>
                      {data[9] && data9sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[10] && data10sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data9sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data9sam - data10sam) * 100) / data10sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data9sam - data10sam) * 100) / data10sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data9sam - data10sam) * 100) / data10sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data9sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data9sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data9sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data9sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.4,
                          height:
                            Math.round(
                              (data9sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[9].FromDate.slice(8, 10) +
                            "/" +
                            data[9].FromDate.slice(5, 7) +
                            "-" +
                            data[9].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[9].FromDate === data[9].ToDate
                            ? " "
                            : data[9].ToDate.slice(8, 10) +
                              "/" +
                              data[9].ToDate.slice(5, 7) +
                              "-" +
                              data[9].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[8] && (
                    <div className="antalBar">
                      {/* {data8sam = Math.round(data[8].ConcessionRevenue + data[8].OtherRevenue + data[8].TicketRevenue)} */}
                      
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[8] &&
                                data8sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[8] && data8sam.toLocaleString("da")}
                      </span>
                      {data[8] && data8sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[9] && data9sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data8sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data8sam - data9sam) * 100) / data9sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data8sam - data9sam) * 100) / data9sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data8sam - data9sam) * 100) / data9sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data8sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data8sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data8sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data8sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.45,
                          height:
                            Math.round(
                              (data8sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[8].FromDate.slice(8, 10) +
                            "/" +
                            data[8].FromDate.slice(5, 7) +
                            "-" +
                            data[8].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[8].FromDate === data[8].ToDate
                            ? " "
                            : data[8].ToDate.slice(8, 10) +
                              "/" +
                              data[8].ToDate.slice(5, 7) +
                              "-" +
                              data[8].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[7] && (
                    <div className="antalBar">
                      {/* {data7sam = Math.round(data[7].ConcessionRevenue + data[7].OtherRevenue + data[7].TicketRevenue)} */}
                    
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[7] &&
                                data7sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[7] && data7sam.toLocaleString("da")}
                      </span>
                      {data[7] && data7sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[8] && data8sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data7sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data7sam - data8sam) * 100) / data8sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data7sam - data8sam) * 100) / data8sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data7sam - data8sam) * 100) / data8sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data7sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data7sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data7sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data7sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.5,
                          height:
                            Math.round(
                              (data7sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[7].FromDate.slice(8, 10) +
                            "/" +
                            data[7].FromDate.slice(5, 7) +
                            "-" +
                            data[7].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[7].FromDate === data[7].ToDate
                            ? " "
                            : data[7].ToDate.slice(8, 10) +
                              "/" +
                              data[7].ToDate.slice(5, 7) +
                              "-" +
                              data[7].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[6] && (
                    <div className="antalBar">
                      {/* {data6sam = Math.round(data[6].ConcessionRevenue + data[6].OtherRevenue + data[6].TicketRevenue)} */}
                      
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[6] &&
                                data6sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[6] && data6sam.toLocaleString("da")}
                      </span>
                      {data[6] && data6sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[7] && data7sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data6sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data6sam - data7sam) * 100) / data7sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data6sam - data7sam) * 100) / data7sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data6sam - data7sam) * 100) / data7sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data6sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data6sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data6sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data6sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.55,
                          height:
                            Math.round(
                              (data6sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[6].FromDate.slice(8, 10) +
                            "/" +
                            data[6].FromDate.slice(5, 7) +
                            "-" +
                            data[6].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[6].FromDate === data[6].ToDate
                            ? " "
                            : data[6].ToDate.slice(8, 10) +
                              "/" +
                              data[6].ToDate.slice(5, 7) +
                              "-" +
                              data[6].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[5] && (
                    <div className="antalBar">
                      {/* {data5sam = Math.round(data[5].ConcessionRevenue + data[5].OtherRevenue + data[5].TicketRevenue)} */}
                    
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[5] &&
                                data5sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[5] && data5sam.toLocaleString("da")}
                      </span>
                      {data[5] && data5sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[6] && data6sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data5sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data5sam - data6sam) * 100) / data6sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data5sam - data6sam) * 100) / data6sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data5sam - data6sam) * 100) / data6sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data5sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data5sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data5sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data5sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.6,
                          height:
                            Math.round(
                              (data5sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[5].FromDate.slice(8, 10) +
                            "/" +
                            data[5].FromDate.slice(5, 7) +
                            "-" +
                            data[5].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[5].FromDate === data[5].ToDate
                            ? " "
                            : data[5].ToDate.slice(8, 10) +
                              "/" +
                              data[5].ToDate.slice(5, 7) +
                              "-" +
                              data[5].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[4] && (
                    <div className="antalBar">
                      {/* {data4sam = Math.round(data[4].ConcessionRevenue + data[4].OtherRevenue + data[4].TicketRevenue)} */}
                      
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[4] &&
                                data4sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[4] && data4sam.toLocaleString("da")}
                      </span>
                      {data[4] && data4sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[5] && data5sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data4sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data4sam - data5sam) * 100) / data5sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data4sam - data5sam) * 100) / data5sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data4sam - data5sam) * 100) / data5sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data4sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data4sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data4sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data4sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.65,
                          height:
                            Math.round(
                              (data4sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[4].FromDate.slice(8, 10) +
                            "/" +
                            data[4].FromDate.slice(5, 7) +
                            "-" +
                            data[4].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[4].FromDate === data[4].ToDate
                            ? " "
                            : data[4].ToDate.slice(8, 10) +
                              "/" +
                              data[4].ToDate.slice(5, 7) +
                              "-" +
                              data[4].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[3] && (
                    <div className="antalBar">
                      {/* {data3sam = Math.round(data[3].ConcessionRevenue + data[3].OtherRevenue + data[3].TicketRevenue)} */}
                      
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[3] &&
                                data3sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[3] && data3sam.toLocaleString("da")}
                      </span>
                      {data[3] && data3sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && (data[4] && data4sam) > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data3sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data3sam - data4sam) * 100) / data4sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {" "}
                          {Math.round(
                            ((data3sam - data4sam) * 100) / data4sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data3sam - data4sam) * 100) / data4sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data3sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data3sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data3sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data3sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.7,
                          height:
                            Math.round(
                              (data3sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[3].FromDate.slice(8, 10) +
                            "/" +
                            data[3].FromDate.slice(5, 7) +
                            "-" +
                            data[3].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[3].FromDate === data[3].ToDate
                            ? " "
                            : data[3].ToDate.slice(8, 10) +
                              "/" +
                              data[3].ToDate.slice(5, 7) +
                              "-" +
                              data[3].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[2] && (
                    <div className="antalBar">
                      {/* {data2sam = Math.round(data[2].ConcessionRevenue + data[2].OtherRevenue + data[2].TicketRevenue)} */}
                     
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[2] &&
                                data2sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[2] && data2sam.toLocaleString("da")}
                      </span>

                      {data[2] && data2sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[3] && data3sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data2sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data2sam - data3sam) * 100) / data3sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data2sam - data3sam) * 100) / data3sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data2sam - data3sam) * 100) / data3sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data2sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data2sam - data0sam) * 100) / data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data2sam - data0sam) * 100) / data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data2sam - data0sam) * 100) / data0sam
                            ) + "%"}
                          </span>
                        )
                      )}
                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.75,
                          height:
                            Math.round(
                              (data2sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[2].FromDate.slice(8, 10) +
                            "/" +
                            data[2].FromDate.slice(5, 7) +
                            "-" +
                            data[2].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[2].FromDate === data[2].ToDate
                            ? " "
                            : data[2].ToDate.slice(8, 10) +
                              "/" +
                              data[2].ToDate.slice(5, 7) +
                              "-" +
                              data[2].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  {data[1] && (
                    <div className="antalBar">
                      {/* {data1sam = Math.round(data[1].ConcessionRevenue + data[1].OtherRevenue + data[1].TicketRevenue)} */}
                      <span
                        className="darkGreenNr"
                        style={{
                          bottom:
                            Math.round(
                              (data[1] &&
                                data1sam / Math.round(maxSamletOms * 1.25)) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[1] && data1sam.toLocaleString("da")}
                      </span>
                      {data[1] && data1sam <= 0 ? (
                        " "
                      ) : radioCheched === "1" && data[2] && data2sam > 0 ? (
                        <span
                          className="antalBarProcent"
                          style={{
                            bottom:
                              Math.round(
                                (data1sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((data1sam - data2sam) * 100) / data2sam
                              ) > 0
                                ? "#40b22d"
                                : "#cf4747",
                          }}
                        >
                          {Math.round(
                            ((data1sam - data2sam) * 100) / data2sam
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data1sam - data2sam) * 100) / data2sam
                          ) + "%"}
                        </span>
                      ) : (
                        radioCheched === "0" &&
                        data0sam > 0 && (
                          <span
                            className="thisYear"
                            style={{
                              color: "white",
                              bottom:
                                Math.round(
                                  (data1sam / Math.round(maxSamletOms * 1.25)) *
                                    100 -
                                    3
                                ) + "%",
                              backgroundColor:
                                Math.round(
                                  ((data[1] && data1sam - data0sam) * 100) /
                                    data0sam
                                ) > 0
                                  ? "#40b22d"
                                  : "#cf4747",
                            }}
                          >
                            {Math.round(
                              ((data[1] && data1sam - data0sam) * 100) /
                                data0sam
                            ) > 0
                              ? "+"
                              : ""}
                            {Math.round(
                              ((data[1] && data1sam - data0sam) * 100) /
                                data0sam
                            ) + "%"}
                          </span>
                        )
                      )}

                      <div
                        className="samletTal"
                        style={{
                          opacity: 0.8,
                          height:
                            Math.round(
                              (data1sam / Math.round(maxSamletOms * 1.25)) * 100
                            ) + "%",
                        }}
                      ></div>
                      <div className="antalPeriode">
                        <span>
                          {" "}
                          {data[1].FromDate.slice(8, 10) +
                            "/" +
                            data[1].FromDate.slice(5, 7) +
                            "-" +
                            data[1].FromDate.slice(2, 4)}{" "}
                        </span>
                        <span>
                          {data[1].FromDate === data[1].ToDate
                            ? " "
                            : data[1].ToDate.slice(8, 10) +
                              "/" +
                              data[1].ToDate.slice(5, 7) +
                              "-" +
                              data[1].ToDate.slice(2, 4)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="antalBar">
                    <span
                      className="darkGreenNr"
                      style={{
                        bottom:
                          Math.round(
                            (data0sam / Math.round(maxSamletOms * 1.25)) * 100 +
                              3
                          ) + "%",
                      }}
                    >
                      {data0sam.toLocaleString("da")}
                    </span>

                    {data0sam <= 0 ? (
                      " "
                    ) : radioCheched === "1" && data1sam > 0 ? (
                      <span
                        className="antalBarProcent"
                        style={{
                          bottom:
                            Math.round(
                              (data0sam / Math.round(maxSamletOms * 1.25)) *
                                100 -
                                3
                            ) + "%",
                          backgroundColor:
                            Math.round(
                              ((data0sam - data1sam) * 100) / data1sam
                            ) > 0
                              ? "#40b22d"
                              : "#cf4747",
                        }}
                      >
                        {Math.round(((data0sam - data1sam) * 100) / data1sam) >
                        0
                          ? "+"
                          : ""}
                        {data[1].TicketCount > 0
                          ? Math.round(
                              ((data0sam - data1sam) * 100) / data1sam
                            ) + "%"
                          : ""}
                      </span>
                    ) : (
                      radioCheched === "0" && (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data0sam / Math.round(maxSamletOms * 1.25)) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                    )}

                    <div
                      className="samletTal"
                      style={{
                        height:
                          Math.round(
                            (data0sam / Math.round(maxSamletOms * 1.25)) * 100
                          ) + "%",
                      }}
                    ></div>

                    <div className="antalPeriode">
                      <span>
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}{" "}
                      </span>
                      <span>
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
                ) : ' ' }
              {/* </div>   */}
              {/* div ends above */}
            </>
          )}
        </section>
        {/* when selected year is only 1, the following layout will be shown */}

        <section
          className="barsContainer"
          style={{ display: data.length === 2 ? "flex" : "none" }}
        >
          {data.length &&   (
            <>
            {/* antal solgte billeter */}
             { pillarsPicked === false || (pillarsPicked === true && soldPiller === 'true') ? ( 
              <div className="bars">
                <div className="barsBox">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="bar1">
                      <span
                        className="soldBar1Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[1].TicketCount /
                                Math.round(
                                  Math.max(
                                    data[0].TicketCount,
                                    data[1].TicketCount
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[1].TicketCount.toLocaleString("da")}
                      </span>
                      {radioCheched === "0" && data[1].TicketCount !== 0 ? (
                        <span
                          className="soldBar1Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[1].TicketCount /
                                  Math.round(
                                    Math.max(
                                      data[0].TicketCount,
                                      data[1].TicketCount
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[1].TicketCount - data[0].TicketCount) *
                                100) /
                                data[0].TicketCount <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {" "}
                          {Math.round(
                            ((data[1].TicketCount - data[0].TicketCount) *
                              100) /
                              data[0].TicketCount
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[1].TicketCount - data[0].TicketCount) *
                              100) /
                              data[0].TicketCount
                          ) + "%"}{" "}
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className="soldBar1"
                        style={{
                          height:
                            Math.round(
                              (data[1].TicketCount /
                                Math.round(
                                  Math.max(
                                    data[0].TicketCount,
                                    data[1].TicketCount
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>

                    <div className="bar2">
                      <span
                        className="soldBar2Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[0].TicketCount /
                                Math.round(
                                  Math.max(
                                    data[0].TicketCount,
                                    data[1].TicketCount
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {data[0].TicketCount.toLocaleString("da")}
                      </span>

                      {radioCheched === "1" ? (
                        <span
                          className="soldBar2Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].TicketCount /
                                  Math.round(
                                    Math.max(
                                      data[0].TicketCount,
                                      data[1].TicketCount
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[0].TicketCount - data[1].TicketCount) *
                                100) /
                                data[1].TicketCount <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[0].TicketCount - data[1].TicketCount) *
                              100) /
                              data[1].TicketCount
                          ) > 0
                            ? "+"
                            : " "}{" "}
                          {data[1].TicketCount === 0
                            ? "100%"
                            : Math.round(
                                ((data[0].TicketCount - data[1].TicketCount) *
                                  100) /
                                  data[1].TicketCount
                              ) + "%"}
                        </span>
                      ) : (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].TicketCount /
                                  Math.round(
                                    Math.max(
                                      data[0].TicketCount,
                                      data[1].TicketCount
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )}
                      <div
                        className="soldBar2"
                        style={{
                          height:
                            Math.round(
                              (data[0].TicketCount /
                                Math.round(
                                  Math.max(
                                    data[0].TicketCount,
                                    data[1].TicketCount
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}
                      </span>
                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}
                      </span>

                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              
                <br />
                <p
                  className="antal"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Antal solgte billetter
                </p>
              </div>
            ) : ' ' }
            {/* billetomsætnig */}
            { pillarsPicked === false || (pillarsPicked === true && ticketPiller === 'true') ? ( 
              <div className="bars">
                <div className="barsBox">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="bar1">
                      <span
                        className="billetBar1Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[1].TicketRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].TicketRevenue,
                                    data[1].TicketRevenue
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[1].TicketRevenue).toLocaleString("da")}
                      </span>
                      {radioCheched === "0" && data[1].TicketRevenue !== 0 ? (
                        <span
                          className="billetBar1Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[1].TicketRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].TicketRevenue,
                                      data[1].TicketRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[1].TicketRevenue - data[0].TicketRevenue) *
                                100) /
                                data[0].TicketRevenue <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[1].TicketRevenue - data[0].TicketRevenue) *
                              100) /
                              data[0].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          {Math.round(
                            ((data[1].TicketRevenue - data[0].TicketRevenue) *
                              100) /
                              data[0].TicketRevenue
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="billetBar1"
                        style={{
                          height:
                            Math.round(
                              (data[1].TicketRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].TicketRevenue,
                                    data[1].TicketRevenue
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                    <div className="bar2">
                      <span
                        className="billetBar2Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[0].TicketRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].TicketRevenue,
                                    data[1].TicketRevenue
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[0].TicketRevenue).toLocaleString("da")}
                      </span>
                      {radioCheched === "1" ? (
                        <span
                          className="billetBar2Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].TicketRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].TicketRevenue,
                                      data[1].TicketRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[0].TicketRevenue - data[1].TicketRevenue) *
                                100) /
                                data[1].TicketRevenue <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[0].TicketRevenue - data[1].TicketRevenue) *
                              100) /
                              data[1].TicketRevenue
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          {data[1].TicketRevenue === 0
                            ? "100%"
                            : Math.round(
                                ((data[0].TicketRevenue -
                                  data[1].TicketRevenue) *
                                  100) /
                                  data[1].TicketRevenue
                              ) + "%"}
                        </span>
                      ) : (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].TicketRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].TicketRevenue,
                                      data[1].TicketRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )}
                      <div
                        className="billetBar2"
                        style={{
                          height:
                            Math.round(
                              (data[0].TicketRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].TicketRevenue,
                                    data[1].TicketRevenue
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}
                      </span>
                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}
                      </span>

                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
              
                <br />
                <p
                  className="billet"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Billetomsætning
                </p>
              </div>
               ) : ' ' }
            {/* kioskomsætning */}
            { pillarsPicked === false || (pillarsPicked === true && kioskPiller === 'true')  ? (
              
              <div className="bars" >
                <div className="barsBox">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="bar1">
                      <span
                        className="kioskBar1Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[1].ConcessionRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].ConcessionRevenue,
                                    data[1].ConcessionRevenue
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[1].ConcessionRevenue).toLocaleString(
                          "da"
                        )}
                      </span>
                      {radioCheched === "0" &&
                      data[1].ConcessionRevenue !== 0 &&
                      data[0].ConcessionRevenue !== 0 ? (
                        <span
                          className="kioskBar1Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[1].ConcessionRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].ConcessionRevenue,
                                      data[1].ConcessionRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[1].ConcessionRevenue -
                                data[0].ConcessionRevenue) *
                                100) /
                                data[0].ConcessionRevenue <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[1].ConcessionRevenue -
                              data[0].ConcessionRevenue) *
                              100) /
                              data[0].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          {Math.round(
                            ((data[1].ConcessionRevenue -
                              data[0].ConcessionRevenue) *
                              100) /
                              data[0].ConcessionRevenue
                          ) + "%"}{" "}
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className="kioskBar1"
                        style={{
                          height:
                            Math.round(
                              (data[1].ConcessionRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].ConcessionRevenue,
                                    data[1].ConcessionRevenue
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                    <div className="bar2">
                      <span
                        className="kioskBar2Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[0].ConcessionRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].ConcessionRevenue,
                                    data[1].ConcessionRevenue
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[0].ConcessionRevenue).toLocaleString(
                          "da"
                        )}
                      </span>

{ data[0].ConcessionRevenue > 0 ? 
                      radioCheched === "1" ? (
                        <span
                          className="kioskBar2Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].ConcessionRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].ConcessionRevenue,
                                      data[1].ConcessionRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[0].ConcessionRevenue -
                                data[1].ConcessionRevenue) *
                                100) /
                                data[1].ConcessionRevenue <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[0].ConcessionRevenue -
                              data[1].ConcessionRevenue) *
                              100) /
                              data[1].ConcessionRevenue
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          {data[1].ConcessionRevenue === 0
                            ? "100%"
                            : Math.round(
                                ((data[0].ConcessionRevenue -
                                  data[1].ConcessionRevenue) *
                                  100) /
                                  data[1].ConcessionRevenue
                              ) + "%"}
                        </span>
                      ) : (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].ConcessionRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].ConcessionRevenue,
                                      data[1].ConcessionRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                      : ' '}
                      <div
                        className="kioskBar2"
                        style={{
                          height:
                            Math.round(
                              (data[0].ConcessionRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].ConcessionRevenue,
                                    data[1].ConcessionRevenue
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}
                      </span>
                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}
                      </span>

                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
                
                <br />
                <p
                  className="billet"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Kioskomsætning
                </p>{" "}
              </div>
               ) : ' ' }
            {/* kiosk oms per solgt billet */}
            { pillarsPicked === false || (pillarsPicked === true && kioskomsPiller === 'true') ? (
              <div className="bars" >
                <div className="barsBox">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="bar1">
                      <span
                        className="omsBar1Tal"
                        style={{
                          bottom:
                            data[1].TicketCount === 0
                              ? 3 + "%"
                              : Math.round(
                                  (Math.round(data[1].ConcessionRevenue) /
                                    data[1].TicketCount /
                                    Math.round(
                                      Math.max(
                                        data[1].TicketCount === 0
                                          ? "0"
                                          : Math.round(
                                              Math.round(
                                                data[1].ConcessionRevenue
                                              ) / data[1].TicketCount
                                            ),
                                        data[0].TicketCount === 0
                                          ? "0"
                                          : Math.round(
                                              Math.round(
                                                data[0].ConcessionRevenue
                                              ) / data[0].TicketCount
                                            )
                                      ) * 1.25
                                    )) *
                                    100 +
                                    3
                                ) + "%",
                        }}
                      >
                        {" "}
                        {data[1].TicketCount === 0
                          ? "0"
                          : Math.round(
                              Math.round(data[1].ConcessionRevenue) /
                                data[1].TicketCount
                            )}
                      </span>
                      {radioCheched === "0" &&
                      data[1].TicketCount !== 0 &&
                      data[0].ConcessionRevenue !== 0 ? (
                        <span
                          className="omsBar1Procent"
                          style={{
                            bottom:
                              data[1].TicketCount === 0
                                ? 0
                                : Math.round(
                                    (Math.round(data[1].ConcessionRevenue) /
                                      data[1].TicketCount /
                                      Math.round(
                                        Math.max(
                                          data[1].TicketCount === 0
                                            ? "0"
                                            : Math.round(
                                                Math.round(
                                                  data[1].ConcessionRevenue
                                                ) / data[1].TicketCount
                                              ),
                                          data[0].TicketCount === 0
                                            ? "0"
                                            : Math.round(
                                                Math.round(
                                                  data[0].ConcessionRevenue
                                                ) / data[0].TicketCount
                                              )
                                        ) * 1.25
                                      )) *
                                      100
                                  ) -
                                  3 +
                                  "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  Math.round(data[1].ConcessionRevenue) /
                                    data[1].TicketCount
                                ) -
                                  Math.round(data[0].ConcessionRevenue) /
                                    data[0].TicketCount) *
                                  100) /
                                  (Math.round(data[0].ConcessionRevenue) /
                                    data[0].TicketCount)
                              ) < 0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              Math.round(data[1].ConcessionRevenue) /
                                data[1].TicketCount
                            ) -
                              Math.round(data[0].ConcessionRevenue) /
                                data[0].TicketCount) *
                              100) /
                              (Math.round(data[0].ConcessionRevenue) /
                                data[0].TicketCount)
                          ) > 0
                            ? "+"
                            : ""}

                          {Math.round(
                            ((Math.round(
                              Math.round(data[1].ConcessionRevenue) /
                                data[1].TicketCount
                            ) -
                              Math.round(data[0].ConcessionRevenue) /
                                data[0].TicketCount) *
                              100) /
                              (Math.round(data[0].ConcessionRevenue) /
                                data[0].TicketCount)
                          ) + "%"}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className="omsBar1"
                        style={{
                          height:
                            data[1].TicketCount === 0
                              ? "0"
                              : Math.round(
                                  (Math.round(data[1].ConcessionRevenue) /
                                    data[1].TicketCount /
                                    Math.round(
                                      Math.max(
                                        data[1].TicketCount === 0
                                          ? "0"
                                          : Math.round(
                                              Math.round(
                                                data[1].ConcessionRevenue
                                              ) / data[1].TicketCount
                                            ),
                                        data[0].TicketCount === 0
                                          ? "0"
                                          : Math.round(
                                              Math.round(
                                                data[0].ConcessionRevenue
                                              ) / data[0].TicketCount
                                            )
                                      ) * 1.25
                                    )) *
                                    100
                                ) + "%",
                        }}
                      ></div>
                    </div>
                    <div className="bar2">
                      <span
                        className="omsBar2Tal"
                        style={{
                          bottom:
                            (data[0].TicketCount === 0
                              ? "0"
                              : Math.round(
                                  Math.round(data[0].ConcessionRevenue) /
                                    data[0].TicketCount
                                ) /
                                Math.round(
                                  Math.max(
                                    data[1].TicketCount === 0
                                      ? "0"
                                      : Math.round(
                                          Math.round(
                                            data[1].ConcessionRevenue
                                          ) / data[1].TicketCount
                                        ),
                                    data[0].TicketCount === 0
                                      ? "0"
                                      : Math.round(
                                          Math.round(
                                            data[0].ConcessionRevenue
                                          ) / data[0].TicketCount
                                        )
                                  ) * 1.25
                                )) *
                              100 +
                            3 +
                            "%",
                        }}
                      >
                        {data[0].TicketCount === 0
                          ? "0"
                          : Math.round(
                              Math.round(data[0].ConcessionRevenue) /
                                data[0].TicketCount
                            )}
                      </span>
{ data[0].TicketCount > 0 && data[0].ConcessionRevenue > 0 ? 
                      radioCheched === "1" ? (
                        <span
                          className="omsBar2Procent"
                          style={{
                            bottom:
                              (data[0].TicketCount === 0
                                ? "0"
                                : Math.round(
                                    Math.round(data[0].ConcessionRevenue) /
                                      data[0].TicketCount
                                  ) /
                                  Math.round(
                                    Math.max(
                                      data[1].TicketCount === 0
                                        ? "0"
                                        : Math.round(
                                            Math.round(
                                              data[1].ConcessionRevenue
                                            ) / data[1].TicketCount
                                          ),
                                      data[0].TicketCount === 0
                                        ? "0"
                                        : Math.round(
                                            Math.round(
                                              data[0].ConcessionRevenue
                                            ) / data[0].TicketCount
                                          )
                                    ) * 1.25
                                  )) *
                                100 -
                              3 +
                              "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  Math.round(data[0].ConcessionRevenue) /
                                    data[0].TicketCount
                                ) -
                                  Math.round(
                                    Math.round(data[1].ConcessionRevenue) /
                                      data[1].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    Math.round(data[1].ConcessionRevenue) /
                                      data[1].TicketCount
                                  )
                              ) < 0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[0].ConcessionRevenue / data[0].TicketCount
                            ) -
                              Math.round(
                                data[1].ConcessionRevenue / data[1].TicketCount
                              )) *
                              100) /
                              Math.round(
                                Math.round(data[1].ConcessionRevenue) /
                                  data[1].TicketCount
                              )
                          ) > 0
                            ? "+"
                            : ""}
                          {data[1].TicketCount === 0 ? "+" : ""}{" "}
                          {data[1].TicketCount === 0
                            ? "100%"
                            : Math.round(
                                ((Math.round(
                                  Math.round(data[0].ConcessionRevenue) /
                                    data[0].TicketCount
                                ) -
                                  Math.round(
                                    Math.round(data[1].ConcessionRevenue) /
                                      data[1].TicketCount
                                  )) *
                                  100) /
                                  Math.round(
                                    Math.round(data[1].ConcessionRevenue) /
                                      data[1].TicketCount
                                  )
                              ) + "%"}
                        </span>
                      ) : (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              (data[0].TicketCount === 0
                                ? "0"
                                : Math.round(
                                    Math.round(data[0].ConcessionRevenue) /
                                      data[0].TicketCount
                                  ) /
                                  Math.round(
                                    Math.max(
                                      data[1].TicketCount === 0
                                        ? "0"
                                        : Math.round(
                                            Math.round(
                                              data[1].ConcessionRevenue
                                            ) / data[1].TicketCount
                                          ),
                                      data[0].TicketCount === 0
                                        ? "0"
                                        : Math.round(
                                            Math.round(
                                              data[0].ConcessionRevenue
                                            ) / data[0].TicketCount
                                          )
                                    ) * 1.25
                                  )) *
                                100 -
                              3 +
                              "%",
                          }}
                        >
                          I år
                        </span>
                      )
                      : '' }
                      <div
                        className="omsBar2"
                        style={{
                          height:
                            (data[0].TicketCount === 0
                              ? "0"
                              : Math.round(
                                  Math.round(data[0].ConcessionRevenue) /
                                    data[0].TicketCount
                                ) /
                                Math.round(
                                  Math.max(
                                    data[1].TicketCount === 0
                                      ? "0"
                                      : Math.round(
                                          Math.round(
                                            data[1].ConcessionRevenue
                                          ) / data[1].TicketCount
                                        ),
                                    data[0].TicketCount === 0
                                      ? "0"
                                      : Math.round(
                                          Math.round(
                                            data[0].ConcessionRevenue
                                          ) / data[0].TicketCount
                                        )
                                  ) * 1.25
                                )) *
                              100 +
                            "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}
                      </span>
                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}
                      </span>

                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
               
                <br />
                <p
                  className="oms"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Kioskomsætning pr. solgt billet
                </p>
              </div>
               ) : ' ' }
            {/* anden omsætning */}
            { pillarsPicked === false || (pillarsPicked === true && otherPiller === 'true') ? (
              <div className="bars " >
                <div className="barsBox">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="bar1">
                      <span
                        className="andenOmsBar1Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[1].OtherRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].OtherRevenue,
                                    data[1].OtherRevenue
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[1].OtherRevenue).toLocaleString("da")}
                      </span>
                      {radioCheched === "0" &&
                      data[1].OtherRevenue !== 0 &&
                      data[1].OtherRevenue !== 0 &&
                      data[0].OtherRevenue !== 0 ? (
                        <span
                          className="kioskBar1Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[1].OtherRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].OtherRevenue,
                                      data[1].OtherRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[1].OtherRevenue - data[0].OtherRevenue) *
                                100) /
                                data[0].OtherRevenue <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[1].OtherRevenue - data[0].OtherRevenue) *
                              100) /
                              data[0].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}
                          {Math.round(
                            ((data[1].OtherRevenue - data[0].OtherRevenue) *
                              100) /
                              data[0].OtherRevenue
                          ) + "%"}{" "}
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className="andenOmsBar1"
                        style={{
                          height:
                            Math.round(
                              (data[1].OtherRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].OtherRevenue,
                                    data[1].OtherRevenue
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                    <div className="bar2">
                      <span
                        className="andenOmsBar2Tal"
                        style={{
                          bottom:
                            Math.round(
                              (data[0].OtherRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].OtherRevenue,
                                    data[1].OtherRevenue
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(data[0].OtherRevenue).toLocaleString("da")}
                      </span>
                      { data[0].OtherRevenue > 0 ? 
                      radioCheched === "1" ? (
                        <span
                          className="kioskBar2Procent"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].OtherRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].OtherRevenue,
                                      data[1].OtherRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              ((data[0].OtherRevenue - data[1].OtherRevenue) *
                                100) /
                                data[1].OtherRevenue <
                              0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((data[0].OtherRevenue - data[1].OtherRevenue) *
                              100) /
                              data[1].OtherRevenue
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          {data[1].OtherRevenue === 0
                            ? "100%"
                            : Math.round(
                                ((data[0].OtherRevenue - data[1].OtherRevenue) *
                                  100) /
                                  data[1].OtherRevenue
                              ) + "%"}
                        </span>
                      ) : (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (data[0].OtherRevenue /
                                  Math.round(
                                    Math.max(
                                      data[0].OtherRevenue,
                                      data[1].OtherRevenue
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )
                      : ' '}
                      <div
                        className="andenOmsBar2"
                        style={{
                          height:
                            Math.round(
                              (data[0].OtherRevenue /
                                Math.round(
                                  Math.max(
                                    data[0].OtherRevenue,
                                    data[1].OtherRevenue
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}
                      </span>
                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}
                      </span>

                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
               
                <br />
                <p
                  className="billet"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Anden omsætning
                </p>{" "}
              </div>
                ) : ' ' }

            {/* samlet omsætning */}
            { pillarsPicked === false || (pillarsPicked === true && totalPiller === 'true') ? (
              <div className="bars ">
                <div className="barsBox">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="bar1">
                      <span
                        className="samletBar1Tal"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[1].ConcessionRevenue +
                                  data[1].TicketRevenue +
                                  data[1].OtherRevenue
                              ) /
                                Math.round(
                                  Math.max(
                                    Math.round(
                                      data[0].ConcessionRevenue +
                                        data[0].TicketRevenue +
                                        data[0].OtherRevenue
                                    ),
                                    Math.round(
                                      data[1].ConcessionRevenue +
                                        data[1].TicketRevenue +
                                        data[1].OtherRevenue
                                    )
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[1].ConcessionRevenue +
                            data[1].OtherRevenue +
                            data[1].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {radioCheched === "0"  ? (
                        <span
                          className="kioskBar1Procent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(
                                  data[1].ConcessionRevenue +
                                    data[1].TicketRevenue +
                                    data[1].OtherRevenue
                                ) /
                                  Math.round(
                                    Math.max(
                                     Math.round(
                                    data[0].ConcessionRevenue +
                                      data[0].TicketRevenue +
                                      data[0].OtherRevenue
                                  ),
                                      Math.round(
                                  data[1].ConcessionRevenue +
                                    data[1].TicketRevenue +
                                    data[1].OtherRevenue
                                )
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[1].ConcessionRevenue +
                                    data[1].TicketRevenue +
                                    data[1].OtherRevenue
                                ) -
                                  Math.round(
                                    data[0].ConcessionRevenue +
                                      data[0].TicketRevenue +
                                      data[0].OtherRevenue
                                  )) *
                                  100) /
                                  Math.round(
                                    data[0].ConcessionRevenue +
                                      data[0].TicketRevenue +
                                      data[0].OtherRevenue
                                  )
                              ) < 0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[1].ConcessionRevenue +
                                data[1].TicketRevenue +
                                data[1].OtherRevenue
                            ) -
                              Math.round(
                                data[0].ConcessionRevenue +
                                  data[0].TicketRevenue +
                                  data[0].OtherRevenue
                              )) *
                              100) /
                              Math.round(
                                data[0].ConcessionRevenue +
                                  data[0].TicketRevenue +
                                  data[0].OtherRevenue
                              )
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          {Math.round(
                            ((Math.round(
                              data[1].ConcessionRevenue +
                                data[1].TicketRevenue +
                                data[1].OtherRevenue
                            ) -
                              Math.round(
                                data[0].ConcessionRevenue +
                                  data[0].TicketRevenue +
                                  data[0].OtherRevenue
                              )) *
                              100) /
                              Math.round(
                                data[0].ConcessionRevenue +
                                  data[0].TicketRevenue +
                                  data[0].OtherRevenue
                              )
                          ) + "%"}{" "}
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className="samletBar1"
                        style={{
                          height:
                            Math.round(
                              (Math.round( data[1].ConcessionRevenue +
                                        data[1].OtherRevenue +
                                        data[1].TicketRevenue) /
                                Math.round(
                                  Math.max(
                                     Math.round(
                                      data[0].ConcessionRevenue +
                                        data[0].OtherRevenue +
                                        data[0].TicketRevenue
                                    ),
                                    Math.round(
                                      data[1].ConcessionRevenue +
                                        data[1].OtherRevenue +
                                        data[1].TicketRevenue
                                    )
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                    <div className="bar2">
                      <span
                        className="samletBar2Tal"
                        style={{
                          bottom:
                            Math.round(
                              (Math.round(
                                data[0].ConcessionRevenue +
                                  data[0].TicketRevenue +
                                  data[0].OtherRevenue
                              ) /
                                Math.round(
                                  Math.max(
                                    Math.round(
                                      data[0].ConcessionRevenue +
                                        data[0].TicketRevenue +
                                        data[0].OtherRevenue
                                    ),
                                    Math.round(
                                      data[1].ConcessionRevenue +
                                        data[1].TicketRevenue +
                                        data[1].OtherRevenue
                                    )
                                  ) * 1.25
                                )) *
                                100 +
                                3
                            ) + "%",
                        }}
                      >
                        {Math.round(
                          data[0].ConcessionRevenue +
                            data[0].OtherRevenue +
                            data[0].TicketRevenue
                        ).toLocaleString("da")}
                      </span>
                      {radioCheched === "1" ? (
                        <span
                          className="kioskBar2Procent"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(
                                  data[0].ConcessionRevenue +
                                    data[0].OtherRevenue +
                                    data[0].TicketRevenue
                                ) /
                                  Math.round(
                                    Math.max(
                                      Math.round(
                                        data[0].ConcessionRevenue +
                                          data[0].TicketRevenue +
                                          data[0].OtherRevenue
                                      ),
                                      Math.round(
                                        data[1].ConcessionRevenue +
                                          data[1].TicketRevenue +
                                          data[1].OtherRevenue
                                      )
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                            backgroundColor:
                              Math.round(
                                ((Math.round(
                                  data[0].ConcessionRevenue +
                                    data[0].OtherRevenue +
                                    data[0].TicketRevenue
                                ) -
                                  Math.round(
                                    data[1].ConcessionRevenue +
                                      data[1].OtherRevenue +
                                      data[1].TicketRevenue
                                  )) *
                                  100) /
                                  Math.round(
                                    data[1].ConcessionRevenue +
                                      data[1].OtherRevenue +
                                      data[1].TicketRevenue
                                  )
                              ) < 0
                                ? "#cf4747"
                                : "#40b22d",
                          }}
                        >
                          {Math.round(
                            ((Math.round(
                              data[0].ConcessionRevenue +
                                data[0].OtherRevenue +
                                data[0].TicketRevenue
                            ) -
                              Math.round(
                                data[1].ConcessionRevenue +
                                  data[1].OtherRevenue +
                                  data[1].TicketRevenue
                              )) *
                              100) /
                              Math.round(
                                data[1].ConcessionRevenue +
                                  data[1].OtherRevenue +
                                  data[1].TicketRevenue
                              )
                          ) > 0
                            ? "+"
                            : ""}{" "}
                          { Math.round(
                                ((Math.round(
                                  data[0].ConcessionRevenue +
                                    data[0].OtherRevenue +
                                    data[0].TicketRevenue
                                ) -
                                  Math.round(
                                    data[1].ConcessionRevenue +
                                      data[1].OtherRevenue +
                                      data[1].TicketRevenue
                                  )) *
                                  100) /
                                  Math.round(
                                    data[1].ConcessionRevenue +
                                      data[1].OtherRevenue +
                                      data[1].TicketRevenue
                                  )
                              ) + "%"}
                        </span>
                      ) : (
                        <span
                          className="thisYear"
                          style={{
                            bottom:
                              Math.round(
                                (Math.round(
                                  data[0].ConcessionRevenue +
                                    data[0].OtherRevenue +
                                    data[0].TicketRevenue
                                ) /
                                  Math.round(
                                    Math.max(
                                      Math.round(
                                        data[0].ConcessionRevenue +
                                          data[0].OtherRevenue +
                                          data[0].TicketRevenue
                                      ),
                                      Math.round(
                                        data[1].ConcessionRevenue +
                                          data[1].OtherRevenue +
                                          data[1].TicketRevenue
                                      )
                                    ) * 1.25
                                  )) *
                                  100 -
                                  3
                              ) + "%",
                          }}
                        >
                          I år
                        </span>
                      )}
                      <div
                        className="samletBar2"
                        style={{
                          height:
                            Math.round(
                              (Math.round(
                                data[0].ConcessionRevenue +
                                  data[0].OtherRevenue +
                                  data[0].TicketRevenue
                              ) /
                                Math.round(
                                  Math.max(
                                    Math.round(
                                      data[0].ConcessionRevenue +
                                        data[0].OtherRevenue +
                                        data[0].TicketRevenue
                                    ),
                                    Math.round(
                                      data[1].ConcessionRevenue +
                                        data[1].OtherRevenue +
                                        data[1].TicketRevenue
                                    )
                                  ) * 1.25
                                )) *
                                100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[1].FromDate.slice(8, 10) +
                          "/" +
                          data[1].FromDate.slice(5, 7) +
                          "-" +
                          data[1].FromDate.slice(2, 4)}
                      </span>
                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[1].FromDate === data[1].ToDate
                          ? " "
                          : data[1].ToDate.slice(8, 10) +
                            "/" +
                            data[1].ToDate.slice(5, 7) +
                            "-" +
                            data[1].ToDate.slice(2, 4)}
                      </span>
                    </div>
                    <div className="dates-container">
                      <span className="barsDate">
                        {" "}
                        {data[0].FromDate.slice(8, 10) +
                          "/" +
                          data[0].FromDate.slice(5, 7) +
                          "-" +
                          data[0].FromDate.slice(2, 4)}
                      </span>

                      <span className="barsDate" style={{ marginTop: 0 }}>
                        {" "}
                        {data[0].FromDate === data[0].ToDate
                          ? " "
                          : data[0].ToDate.slice(8, 10) +
                            "/" +
                            data[0].ToDate.slice(5, 7) +
                            "-" +
                            data[0].ToDate.slice(2, 4)}
                      </span>
                    </div>
                  </div>
                </div>
               
                <br />
                <p
                  className="billet"
                  style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                >
                  Samlet omsætning
                </p>{" "}
              </div>
                ) : ' ' }
            </>
          )}
        </section>

        {/* when selected year is 11 or more, the following layout is shown */}
        <section
          className=""
          style={{
            display: data.length > 11 ? "flex" : "none",
            flexDirection: "column",
          }}
        >
           { pillarsPicked === false || (pillarsPicked === true && soldPiller === 'true') ? ( 
          <div>
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Antal solgte billetter
            </p>

            <div className="progressbarsContainer">
              <div style={{ margin: "20px 61px 20px 20px " }}>
               

                {data.length > 11 &&
                  data.map((item, index) => (
                    <div className="progressBars">
                      <div
                        className={`progressData progressBars_${index}`}
                        style={{
                          width:
                            item.TicketCount > 0
                              ? Math.round(
                                  (item.TicketCount /
                                    Math.round(maxAntalBillter * 1.1)) *
                                    100
                                ) + "%"
                              : "22%",
                          minWidth: item.TicketCount > 0 ? "25%" : "",
                          backgroundColor:
                            item.TicketCount > 0 ? "#dbe754" : "none",
                        }}
                      ></div>
                      <div className="progressCon">
                        <div className="progressDato">
                          <span>
                            {" "}
                            {item.FromDate.slice(8, 10) +
                              "/" +
                              item.FromDate.slice(5, 7) +
                              "-" +
                              item.FromDate.slice(2, 4)}
                          </span>
                        </div>
                        <span className="progressDato" style={{ left: 90 }}>
                          {item.FromDate === item.ToDate
                            ? ""
                            : item.ToDate.slice(8, 10) +
                              "/" +
                              item.ToDate.slice(5, 7) +
                              "-" +
                              item.ToDate.slice(2, 4)}
                        </span>
                        {item.TicketCount === 0 ? (
                          ""
                        ) : radioCheched === "1" ? (
                          <>
                            {index === 0 && data[1].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[0].TicketCount -
                                        data[1].TicketCount) *
                                        100) /
                                        data[1].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[0].TicketCount - data[1].TicketCount) *
                                    100) /
                                    data[1].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[0].TicketCount - data[1].TicketCount) *
                                    100) /
                                    data[1].TicketCount
                                ) + "%"}
                              </span>
                            )}
                            {index === 1 && data[2].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[1].TicketCount -
                                        data[2].TicketCount) *
                                        100) /
                                        data[2].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[1].TicketCount - data[2].TicketCount) *
                                    100) /
                                    data[2].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[1].TicketCount - data[2].TicketCount) *
                                    100) /
                                    data[2].TicketCount
                                ) + "%"}
                              </span>
                            )}
                            {index === 2 && data[3].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[2].TicketCount -
                                        data[3].TicketCount) *
                                        100) /
                                        data[3].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[2].TicketCount - data[3].TicketCount) *
                                    100) /
                                    data[3].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[2].TicketCount - data[3].TicketCount) *
                                    100) /
                                    data[3].TicketCount
                                ) + "%"}
                              </span>
                            )}
                            {index === 3 && data[4].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[3].TicketCount -
                                        data[4].TicketCount) *
                                        100) /
                                        data[4].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[3].TicketCount - data[4].TicketCount) *
                                    100) /
                                    data[4].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[3].TicketCount - data[4].TicketCount) *
                                    100) /
                                    data[4].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 4 && data[5].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[4].TicketCount -
                                        data[5].TicketCount) *
                                        100) /
                                        data[5].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[4].TicketCount - data[5].TicketCount) *
                                    100) /
                                    data[5].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[4].TicketCount - data[5].TicketCount) *
                                    100) /
                                    data[5].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 5 && data[6].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[5].TicketCount -
                                        data[6].TicketCount) *
                                        100) /
                                        data[6].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[5].TicketCount - data[6].TicketCount) *
                                    100) /
                                    data[6].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[5].TicketCount - data[6].TicketCount) *
                                    100) /
                                    data[6].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 6 && data[7].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[6].TicketCount -
                                        data[7].TicketCount) *
                                        100) /
                                        data[7].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[6].TicketCount - data[7].TicketCount) *
                                    100) /
                                    data[7].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[6].TicketCount - data[7].TicketCount) *
                                    100) /
                                    data[7].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 7 && data[8].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[7].TicketCount -
                                        data[8].TicketCount) *
                                        100) /
                                        data[8].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[7].TicketCount - data[8].TicketCount) *
                                    100) /
                                    data[8].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[7].TicketCount - data[8].TicketCount) *
                                    100) /
                                    data[8].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 8 && data[9].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[8].TicketCount -
                                        data[9].TicketCount) *
                                        100) /
                                        data[9].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[8].TicketCount - data[9].TicketCount) *
                                    100) /
                                    data[9].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[8].TicketCount - data[9].TicketCount) *
                                    100) /
                                    data[9].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 9 && data[10].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[9].TicketCount -
                                        data[10].TicketCount) *
                                        100) /
                                        data[10].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[9].TicketCount -
                                    data[10].TicketCount) *
                                    100) /
                                    data[10].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[9].TicketCount -
                                    data[10].TicketCount) *
                                    100) /
                                    data[10].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 10 && data[11].TicketCount > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[10].TicketCount -
                                        data[11].TicketCount) *
                                        100) /
                                        data[11].TicketCount
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[10].TicketCount -
                                    data[11].TicketCount) *
                                    100) /
                                    data[11].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[10].TicketCount -
                                    data[11].TicketCount) *
                                    100) /
                                    data[11].TicketCount
                                ) + "%"}
                              </span>
                            )}

                            {index === 11 &&
                              data[12] &&
                              data[12].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].TicketCount -
                                          data[12].TicketCount) *
                                          100) /
                                          data[12].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].TicketCount -
                                      data[12].TicketCount) *
                                      100) /
                                      data[12].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].TicketCount -
                                      data[12].TicketCount) *
                                      100) /
                                      data[12].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 12 &&
                              data[13] &&
                              data[13].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].TicketCount -
                                          data[13].TicketCount) *
                                          100) /
                                          data[13].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].TicketCount -
                                      data[13].TicketCount) *
                                      100) /
                                      data[13].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].TicketCount -
                                      data[13].TicketCount) *
                                      100) /
                                      data[13].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 13 &&
                              data[14] &&
                              data[14].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].TicketCount -
                                          data[14].TicketCount) *
                                          100) /
                                          data[14].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].TicketCount -
                                      data[14].TicketCount) *
                                      100) /
                                      data[14].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].TicketCount -
                                      data[14].TicketCount) *
                                      100) /
                                      data[14].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 14 &&
                              data[15] &&
                              data[15].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].TicketCount -
                                          data[15].TicketCount) *
                                          100) /
                                          data[15].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].TicketCount -
                                      data[15].TicketCount) *
                                      100) /
                                      data[15].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].TicketCount -
                                      data[15].TicketCount) *
                                      100) /
                                      data[15].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 15 &&
                              data[16] &&
                              data[16].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].TicketCount -
                                          data[16].TicketCount) *
                                          100) /
                                          data[16].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].TicketCount -
                                      data[16].TicketCount) *
                                      100) /
                                      data[16].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].TicketCount -
                                      data[16].TicketCount) *
                                      100) /
                                      data[16].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 16 &&
                              data[17] &&
                              data[17].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].TicketCount -
                                          data[17].TicketCount) *
                                          100) /
                                          data[17].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].TicketCount -
                                      data[17].TicketCount) *
                                      100) /
                                      data[17].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].TicketCount -
                                      data[17].TicketCount) *
                                      100) /
                                      data[17].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 17 &&
                              data[18] &&
                              data[18].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].TicketCount -
                                          data[18].TicketCount) *
                                          100) /
                                          data[18].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].TicketCount -
                                      data[18].TicketCount) *
                                      100) /
                                      data[18].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].TicketCount -
                                      data[18].TicketCount) *
                                      100) /
                                      data[18].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 18 &&
                              data[19] &&
                              data[19].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].TicketCount -
                                          data[19].TicketCount) *
                                          100) /
                                          data[19].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].TicketCount -
                                      data[19].TicketCount) *
                                      100) /
                                      data[19].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].TicketCount -
                                      data[19].TicketCount) *
                                      100) /
                                      data[19].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 19 &&
                              data[20] &&
                              data[20].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].TicketCount -
                                          data[20].TicketCount) *
                                          100) /
                                          data[20].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].TicketCount -
                                      data[20].TicketCount) *
                                      100) /
                                      data[20].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].TicketCount -
                                      data[20].TicketCount) *
                                      100) /
                                      data[20].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 20 &&
                              data[21] &&
                              data[21].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].TicketCount -
                                          data[21].TicketCount) *
                                          100) /
                                          data[21].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].TicketCount -
                                      data[21].TicketCount) *
                                      100) /
                                      data[21].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].TicketCount -
                                      data[21].TicketCount) *
                                      100) /
                                      data[21].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 21 &&
                              data[22] &&
                              data[22].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].TicketCount -
                                          data[22].TicketCount) *
                                          100) /
                                          data[22].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].TicketCount -
                                      data[22].TicketCount) *
                                      100) /
                                      data[22].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].TicketCount -
                                      data[22].TicketCount) *
                                      100) /
                                      data[22].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 22 &&
                              data[23] &&
                              data[23].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].TicketCount -
                                          data[23].TicketCount) *
                                          100) /
                                          data[23].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].TicketCount -
                                      data[23].TicketCount) *
                                      100) /
                                      data[23].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].TicketCount -
                                      data[23].TicketCount) *
                                      100) /
                                      data[23].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 23 &&
                              data[24] &&
                              data[24].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].TicketCount -
                                          data[24].TicketCount) *
                                          100) /
                                          data[24].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].TicketCount -
                                      data[24].TicketCount) *
                                      100) /
                                      data[24].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].TicketCount -
                                      data[24].TicketCount) *
                                      100) /
                                      data[24].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                            {index === 24 &&
                              data[25] &&
                              data[25].TicketCount > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].TicketCount -
                                          data[25].TicketCount) *
                                          100) /
                                          data[25].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].TicketCount -
                                      data[25].TicketCount) *
                                      100) /
                                      data[25].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].TicketCount -
                                      data[25].TicketCount) *
                                      100) /
                                      data[25].TicketCount
                                  ) + "%"}
                                </span>
                              )}
                          </>
                        ) : (
                          radioCheched === "0" && (
                            <>
                              {index === 0 && (
                                <span className="thisYear">I år</span>
                              )}

                              {index === 1 && data[1].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[1].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[1].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[1].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 2 && data[2].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[2].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[2].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[2].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 3 && data[3].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[3].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[3].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[3].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 4 && data[4].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[4].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[4].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[4].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 5 && data[5].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[5].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[5].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[5].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 6 && data[6].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[6].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[6].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[6].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 7 && data[7].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[7].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[7].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[7].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 8 && data[8].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[8].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[8].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[8].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 9 && data[9].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[9].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[9].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[9].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 10 && data[10].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[10].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[10].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[10].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 11 && data[11].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 12 && data[12].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 13 && data[13].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 14 && data[14].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 15 && data[15].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 16 && data[16].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 17 && data[17].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 18 && data[18].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 19 && data[19].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 20 && data[20].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 21 && data[21].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 22 && data[22].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 23 && data[23].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 24 && data[24].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}

                              {index === 25 && data[25].TicketCount > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[25].TicketCount -
                                          data[0].TicketCount) *
                                          100) /
                                          data[0].TicketCount
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[25].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[25].TicketCount -
                                      data[0].TicketCount) *
                                      100) /
                                      data[0].TicketCount
                                  ) + "%"}
                                </span>
                              )}
                            </>
                          )
                        )}
                      </div>

                      <p
                        className="progressValue"
                        style={{
                          paddingLeft: item.TicketCount > 0 ? "10px" : "",
                          marginLeft: item.TicketCount > 0 ? "0px" : "-45px",

                          color: "#a6b40a",
                        }}
                      >
                        {item.TicketCount.toLocaleString("da")}
                      </p>
                      {/* ):''} */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          ) : ' ' }
         {/* antal solgte slutter her */}
          <br />
          { pillarsPicked === false || (pillarsPicked === true && ticketPiller === 'true') ? ( 
          <div>
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {" "}
              Billetomsætning
            </p>
            <div className="progressbarsContainer">
              <div style={{ margin: "20px 61px 20px 20px " }}>
               

                {data.length > 11 &&
                  data.map((item, index) => (
                    <div className="progressBars">
                      <div
                        className={`progressData progressBars_${index}`}
                        style={{
                          width:
                            item.TicketRevenue > 0
                              ? Math.round(
                                  (item.TicketRevenue /
                                    Math.round(maxBilletoms * 1.1)) *
                                    100
                                ) + "%"
                              : "22%",
                          minWidth: item.TicketRevenue > 0 ? "25%" : "",
                          backgroundColor:
                            item.TicketRevenue > 0 ? "#a9e5ff" : "none",
                        }}
                      ></div>
                      <div>
                        <span className="progressDato">
                          {item.FromDate.slice(8, 10) +
                            "/" +
                            item.FromDate.slice(5, 7) +
                            "-" +
                            item.FromDate.slice(2, 4)}
                        </span>
                        <span className="progressDato" style={{ left: 90 }}>
                          {item.FromDate === item.ToDate
                            ? ""
                            : item.ToDate.slice(8, 10) +
                              "/" +
                              item.ToDate.slice(5, 7) +
                              "-" +
                              item.ToDate.slice(2, 4)}
                        </span>
                        {Math.round(item.TicketRevenue) === 0 ? (
                          ""
                        ) : radioCheched === "1" ? (
                          <>
                            {index === 0 && data[1].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[0].TicketRevenue -
                                        data[1].TicketRevenue) *
                                        100) /
                                        data[1].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[0].TicketRevenue -
                                    data[1].TicketRevenue) *
                                    100) /
                                    data[1].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[0].TicketRevenue -
                                    data[1].TicketRevenue) *
                                    100) /
                                    data[1].TicketRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 1 && data[2].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[1].TicketRevenue -
                                        data[2].TicketRevenue) *
                                        100) /
                                        data[2].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[1].TicketRevenue -
                                    data[2].TicketRevenue) *
                                    100) /
                                    data[2].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[1].TicketRevenue -
                                    data[2].TicketRevenue) *
                                    100) /
                                    data[2].TicketRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 2 && data[3].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[2].TicketRevenue -
                                        data[3].TicketRevenue) *
                                        100) /
                                        data[3].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[2].TicketRevenue -
                                    data[3].TicketRevenue) *
                                    100) /
                                    data[3].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[2].TicketRevenue -
                                    data[3].TicketRevenue) *
                                    100) /
                                    data[3].TicketRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 3 && data[4].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[3].TicketRevenue -
                                        data[4].TicketRevenue) *
                                        100) /
                                        data[4].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[3].TicketRevenue -
                                    data[4].TicketRevenue) *
                                    100) /
                                    data[4].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[3].TicketRevenue -
                                    data[4].TicketRevenue) *
                                    100) /
                                    data[4].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 4 && data[5].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[4].TicketRevenue -
                                        data[5].TicketRevenue) *
                                        100) /
                                        data[5].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[4].TicketRevenue -
                                    data[5].TicketRevenue) *
                                    100) /
                                    data[5].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[4].TicketRevenue -
                                    data[5].TicketRevenue) *
                                    100) /
                                    data[5].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 5 && data[6].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[5].TicketRevenue -
                                        data[6].TicketRevenue) *
                                        100) /
                                        data[6].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[5].TicketRevenue -
                                    data[6].TicketRevenue) *
                                    100) /
                                    data[6].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[5].TicketRevenue -
                                    data[6].TicketRevenue) *
                                    100) /
                                    data[6].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 6 && data[7].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[6].TicketRevenue -
                                        data[7].TicketRevenue) *
                                        100) /
                                        data[7].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[6].TicketRevenue -
                                    data[7].TicketRevenue) *
                                    100) /
                                    data[7].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[6].TicketRevenue -
                                    data[7].TicketRevenue) *
                                    100) /
                                    data[7].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 7 && data[8].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[7].TicketRevenue -
                                        data[8].TicketRevenue) *
                                        100) /
                                        data[8].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[7].TicketRevenue -
                                    data[8].TicketRevenue) *
                                    100) /
                                    data[8].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[7].TicketRevenue -
                                    data[8].TicketRevenue) *
                                    100) /
                                    data[8].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 8 && data[9].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[8].TicketRevenue -
                                        data[9].TicketRevenue) *
                                        100) /
                                        data[9].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[8].TicketRevenue -
                                    data[9].TicketRevenue) *
                                    100) /
                                    data[9].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[8].TicketRevenue -
                                    data[9].TicketRevenue) *
                                    100) /
                                    data[9].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 9 && data[10].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[9].TicketRevenue -
                                        data[10].TicketRevenue) *
                                        100) /
                                        data[10].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[9].TicketRevenue -
                                    data[10].TicketRevenue) *
                                    100) /
                                    data[10].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[9].TicketRevenue -
                                    data[10].TicketRevenue) *
                                    100) /
                                    data[10].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 10 && data[11].TicketRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[10].TicketRevenue -
                                        data[11].TicketRevenue) *
                                        100) /
                                        data[11].TicketRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[10].TicketRevenue -
                                    data[11].TicketRevenue) *
                                    100) /
                                    data[11].TicketRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[10].TicketRevenue -
                                    data[11].TicketRevenue) *
                                    100) /
                                    data[11].TicketRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 11 &&
                              data[12] &&
                              data[12].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].TicketRevenue -
                                          data[12].TicketRevenue) *
                                          100) /
                                          data[12].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].TicketRevenue -
                                      data[12].TicketRevenue) *
                                      100) /
                                      data[12].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].TicketRevenue -
                                      data[12].TicketRevenue) *
                                      100) /
                                      data[12].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 12 &&
                              data[13] &&
                              data[13].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].TicketRevenue -
                                          data[13].TicketRevenue) *
                                          100) /
                                          data[13].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].TicketRevenue -
                                      data[13].TicketRevenue) *
                                      100) /
                                      data[13].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].TicketRevenue -
                                      data[13].TicketRevenue) *
                                      100) /
                                      data[13].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 13 &&
                              data[14] &&
                              data[14].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].TicketRevenue -
                                          data[14].TicketRevenue) *
                                          100) /
                                          data[14].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].TicketRevenue -
                                      data[14].TicketRevenue) *
                                      100) /
                                      data[14].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].TicketRevenue -
                                      data[14].TicketRevenue) *
                                      100) /
                                      data[14].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 14 &&
                              data[15] &&
                              data[15].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].TicketRevenue -
                                          data[15].TicketRevenue) *
                                          100) /
                                          data[15].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].TicketRevenue -
                                      data[15].TicketRevenue) *
                                      100) /
                                      data[15].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].TicketRevenue -
                                      data[15].TicketRevenue) *
                                      100) /
                                      data[15].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 15 &&
                              data[16] &&
                              data[16].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].TicketRevenue -
                                          data[16].TicketRevenue) *
                                          100) /
                                          data[16].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].TicketRevenue -
                                      data[16].TicketRevenue) *
                                      100) /
                                      data[16].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].TicketRevenue -
                                      data[16].TicketRevenue) *
                                      100) /
                                      data[16].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 16 &&
                              data[17] &&
                              data[17].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].TicketRevenue -
                                          data[17].TicketRevenue) *
                                          100) /
                                          data[17].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].TicketRevenue -
                                      data[17].TicketRevenue) *
                                      100) /
                                      data[17].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].TicketRevenue -
                                      data[17].TicketRevenue) *
                                      100) /
                                      data[17].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 17 &&
                              data[18] &&
                              data[18].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].TicketRevenue -
                                          data[18].TicketRevenue) *
                                          100) /
                                          data[18].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].TicketRevenue -
                                      data[18].TicketRevenue) *
                                      100) /
                                      data[18].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].TicketRevenue -
                                      data[18].TicketRevenue) *
                                      100) /
                                      data[18].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 18 &&
                              data[19] &&
                              data[19].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].TicketRevenue -
                                          data[19].TicketRevenue) *
                                          100) /
                                          data[19].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].TicketRevenue -
                                      data[19].TicketRevenue) *
                                      100) /
                                      data[19].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].TicketRevenue -
                                      data[19].TicketRevenue) *
                                      100) /
                                      data[19].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 19 &&
                              data[20] &&
                              data[20].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].TicketRevenue -
                                          data[20].TicketRevenue) *
                                          100) /
                                          data[20].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].TicketRevenue -
                                      data[20].TicketRevenue) *
                                      100) /
                                      data[20].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].TicketRevenue -
                                      data[20].TicketRevenue) *
                                      100) /
                                      data[20].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 20 &&
                              data[21] &&
                              data[21].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].TicketRevenue -
                                          data[21].TicketRevenue) *
                                          100) /
                                          data[21].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].TicketRevenue -
                                      data[21].TicketRevenue) *
                                      100) /
                                      data[21].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].TicketRevenue -
                                      data[21].TicketRevenue) *
                                      100) /
                                      data[21].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 21 &&
                              data[22] &&
                              data[22].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].TicketRevenue -
                                          data[22].TicketRevenue) *
                                          100) /
                                          data[22].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].TicketRevenue -
                                      data[22].TicketRevenue) *
                                      100) /
                                      data[22].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].TicketRevenue -
                                      data[22].TicketRevenue) *
                                      100) /
                                      data[22].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 22 &&
                              data[23] &&
                              data[23].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].TicketRevenue -
                                          data[23].TicketRevenue) *
                                          100) /
                                          data[23].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].TicketRevenue -
                                      data[23].TicketRevenue) *
                                      100) /
                                      data[23].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].TicketRevenue -
                                      data[23].TicketRevenue) *
                                      100) /
                                      data[23].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 23 &&
                              data[24] &&
                              data[24].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].TicketRevenue -
                                          data[24].TicketRevenue) *
                                          100) /
                                          data[24].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].TicketRevenue -
                                      data[24].TicketRevenue) *
                                      100) /
                                      data[24].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].TicketRevenue -
                                      data[24].TicketRevenue) *
                                      100) /
                                      data[24].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 24 &&
                              data[25] &&
                              data[25].TicketRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].TicketRevenue -
                                          data[25].TicketRevenue) *
                                          100) /
                                          data[25].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].TicketRevenue -
                                      data[25].TicketRevenue) *
                                      100) /
                                      data[25].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].TicketRevenue -
                                      data[25].TicketRevenue) *
                                      100) /
                                      data[25].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}
                          </>
                        ) : (
                          radioCheched === "0" && (
                            <>
                              {index === 0 && (
                                <span className="thisYear">I år</span>
                              )}

                              {index === 1 && data[1].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[1].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[1].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[1].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 2 && data[2].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[2].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[2].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[2].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 3 && data[3].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[3].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[3].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[3].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 4 && data[4].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[4].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[4].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[4].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 5 && data[5].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[5].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[5].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[5].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 6 && data[6].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[6].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[6].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[6].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 7 && data[7].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[7].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[7].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[7].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 8 && data[8].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[8].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[8].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[8].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 9 && data[3].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[9].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[9].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[9].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 10 && data[10].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[10].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[10].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[10].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 11 && data[11].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 12 && data[12].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 13 && data[13].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 14 && data[14].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 15 && data[15].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 16 && data[16].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 17 && data[17].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 18 && data[18].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 19 && data[19].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 20 && data[20].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 21 && data[21].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 22 && data[22].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 23 && data[23].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 24 && data[24].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 25 && data[25].TicketRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[25].TicketRevenue -
                                          data[0].TicketRevenue) *
                                          100) /
                                          data[0].TicketRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[25].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[25].TicketRevenue -
                                      data[0].TicketRevenue) *
                                      100) /
                                      data[0].TicketRevenue
                                  ) + "%"}
                                </span>
                              )}
                            </>
                          )
                        )}
                      </div>
                      <p
                        className="progressValue"
                        style={{
                          paddingLeft:
                            Math.round(item.TicketRevenue) > 0 ? "10px" : "",
                          marginLeft:
                            Math.round(item.TicketRevenue) > 0
                              ? "0px"
                              : "-45px",

                          color: "#4ea5c2",
                        }}
                        //  style={{ left: item.TicketCount > 0 ?
                        //   (Math.round(((item.TicketCount / Math.round(maxAntalBillter * 1.10) * 100)
                        //      ))) + "%" :  '26%' }}
                      >
                        {Math.round(item.TicketRevenue).toLocaleString("da")}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
           ) : ' ' }
         {/* billetomsætning slutter her */}
          <br />
          { pillarsPicked === false || (pillarsPicked === true && kioskPiller === 'true') ? (
          <div>
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {" "}
              Kioskomsætning
            </p>
            <div className="progressbarsContainer">
              <div style={{ margin: "20px 61px 20px 20px " }}>
               

                {data.length > 11 &&
                  data.map((item, index) => (
                    <div className="progressBars">
                      <div
                        className={`progressData progressBars_${index}`}
                        style={{
                          width:
                            Math.round(item.ConcessionRevenue) > 1
                              ? Math.round(
                                  (Math.round(item.ConcessionRevenue) /
                                    Math.round(maxKioskoms * 1.1)) *
                                    100
                                ) + "%"
                              : "22%",
                          minWidth:
                            Math.round(item.ConcessionRevenue) > 1 ? "25%" : "",
                          backgroundColor:
                            Math.round(item.ConcessionRevenue) > 0
                              ? "#ff9b2f"
                              : "none",
                        }}
                      ></div>
                      <div>
                        <span className="progressDato">
                          {item.FromDate.slice(8, 10) +
                            "/" +
                            item.FromDate.slice(5, 7) +
                            "-" +
                            item.FromDate.slice(2, 4)}
                        </span>
                        <span className="progressDato" style={{ left: 90 }}>
                          {item.FromDate === item.ToDate
                            ? ""
                            : item.ToDate.slice(8, 10) +
                              "/" +
                              item.ToDate.slice(5, 7) +
                              "-" +
                              item.ToDate.slice(2, 4)}
                        </span>
                        {Math.round(item.ConcessionRevenue) <= 0 ? (
                          ""
                        ) : radioCheched === "1" ? (
                          <>
                            {index === 0 && data[1].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[0].ConcessionRevenue -
                                        data[1].ConcessionRevenue) *
                                        100) /
                                        data[1].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[0].ConcessionRevenue -
                                    data[1].ConcessionRevenue) *
                                    100) /
                                    data[1].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[0].ConcessionRevenue -
                                    data[1].ConcessionRevenue) *
                                    100) /
                                    data[1].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 1 && data[2].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[1].ConcessionRevenue -
                                        data[2].ConcessionRevenue) *
                                        100) /
                                        data[2].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[1].ConcessionRevenue -
                                    data[2].ConcessionRevenue) *
                                    100) /
                                    data[2].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[1].ConcessionRevenue -
                                    data[2].ConcessionRevenue) *
                                    100) /
                                    data[2].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 2 && data[3].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[2].ConcessionRevenue -
                                        data[3].ConcessionRevenue) *
                                        100) /
                                        data[3].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[2].ConcessionRevenue -
                                    data[3].ConcessionRevenue) *
                                    100) /
                                    data[3].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[2].ConcessionRevenue -
                                    data[3].ConcessionRevenue) *
                                    100) /
                                    data[3].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 3 && data[4].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[3].ConcessionRevenue -
                                        data[4].ConcessionRevenue) *
                                        100) /
                                        data[4].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[3].ConcessionRevenue -
                                    data[4].ConcessionRevenue) *
                                    100) /
                                    data[4].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[3].ConcessionRevenue -
                                    data[4].ConcessionRevenue) *
                                    100) /
                                    data[4].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 4 && data[5].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[4].ConcessionRevenue -
                                        data[5].ConcessionRevenue) *
                                        100) /
                                        data[5].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[4].ConcessionRevenue -
                                    data[5].ConcessionRevenue) *
                                    100) /
                                    data[5].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[4].ConcessionRevenue -
                                    data[5].ConcessionRevenue) *
                                    100) /
                                    data[5].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 5 && data[6].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[5].ConcessionRevenue -
                                        data[6].ConcessionRevenue) *
                                        100) /
                                        data[6].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[5].ConcessionRevenue -
                                    data[6].ConcessionRevenue) *
                                    100) /
                                    data[6].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[5].ConcessionRevenue -
                                    data[6].ConcessionRevenue) *
                                    100) /
                                    data[6].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 6 && data[7].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[6].ConcessionRevenue -
                                        data[7].ConcessionRevenue) *
                                        100) /
                                        data[7].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[6].ConcessionRevenue -
                                    data[7].ConcessionRevenue) *
                                    100) /
                                    data[7].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[6].ConcessionRevenue -
                                    data[7].ConcessionRevenue) *
                                    100) /
                                    data[7].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 7 && data[8].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[7].ConcessionRevenue -
                                        data[8].ConcessionRevenue) *
                                        100) /
                                        data[8].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[7].ConcessionRevenue -
                                    data[8].ConcessionRevenue) *
                                    100) /
                                    data[8].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[7].ConcessionRevenue -
                                    data[8].ConcessionRevenue) *
                                    100) /
                                    data[8].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 8 && data[9].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[8].ConcessionRevenue -
                                        data[9].ConcessionRevenue) *
                                        100) /
                                        data[9].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[8].ConcessionRevenue -
                                    data[9].ConcessionRevenue) *
                                    100) /
                                    data[9].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[8].ConcessionRevenue -
                                    data[9].ConcessionRevenue) *
                                    100) /
                                    data[9].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 9 && data[10].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[9].ConcessionRevenue -
                                        data[10].ConcessionRevenue) *
                                        100) /
                                        data[10].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[9].ConcessionRevenue -
                                    data[10].ConcessionRevenue) *
                                    100) /
                                    data[10].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[9].ConcessionRevenue -
                                    data[10].ConcessionRevenue) *
                                    100) /
                                    data[10].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 10 && data[11].ConcessionRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[10].ConcessionRevenue -
                                        data[11].ConcessionRevenue) *
                                        100) /
                                        data[11].ConcessionRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[10].ConcessionRevenue -
                                    data[11].ConcessionRevenue) *
                                    100) /
                                    data[11].ConcessionRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[10].ConcessionRevenue -
                                    data[11].ConcessionRevenue) *
                                    100) /
                                    data[11].ConcessionRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 11 &&
                              data[12] &&
                              data[12].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].ConcessionRevenue -
                                          data[12].ConcessionRevenue) *
                                          100) /
                                          data[12].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].ConcessionRevenue -
                                      data[12].ConcessionRevenue) *
                                      100) /
                                      data[12].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].ConcessionRevenue -
                                      data[12].ConcessionRevenue) *
                                      100) /
                                      data[12].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 12 &&
                              data[13] &&
                              data[13].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].ConcessionRevenue -
                                          data[13].ConcessionRevenue) *
                                          100) /
                                          data[13].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].ConcessionRevenue -
                                      data[13].ConcessionRevenue) *
                                      100) /
                                      data[13].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].ConcessionRevenue -
                                      data[13].ConcessionRevenue) *
                                      100) /
                                      data[13].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 13 &&
                              data[14] &&
                              data[14].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].ConcessionRevenue -
                                          data[14].ConcessionRevenue) *
                                          100) /
                                          data[14].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].ConcessionRevenue -
                                      data[14].ConcessionRevenue) *
                                      100) /
                                      data[14].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].ConcessionRevenue -
                                      data[14].ConcessionRevenue) *
                                      100) /
                                      data[14].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 14 &&
                              data[15] &&
                              data[15].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].ConcessionRevenue -
                                          data[15].ConcessionRevenue) *
                                          100) /
                                          data[15].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].ConcessionRevenue -
                                      data[15].ConcessionRevenue) *
                                      100) /
                                      data[15].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].ConcessionRevenue -
                                      data[15].ConcessionRevenue) *
                                      100) /
                                      data[15].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 15 &&
                              data[16] &&
                              data[16].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].ConcessionRevenue -
                                          data[16].ConcessionRevenue) *
                                          100) /
                                          data[16].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].ConcessionRevenue -
                                      data[16].ConcessionRevenue) *
                                      100) /
                                      data[16].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].ConcessionRevenue -
                                      data[16].ConcessionRevenue) *
                                      100) /
                                      data[16].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 16 &&
                              data[17] &&
                              data[17].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].ConcessionRevenue -
                                          data[17].ConcessionRevenue) *
                                          100) /
                                          data[17].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].ConcessionRevenue -
                                      data[17].ConcessionRevenue) *
                                      100) /
                                      data[17].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].ConcessionRevenue -
                                      data[17].ConcessionRevenue) *
                                      100) /
                                      data[17].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 17 &&
                              data[18] &&
                              data[18].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].ConcessionRevenue -
                                          data[18].ConcessionRevenue) *
                                          100) /
                                          data[18].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].ConcessionRevenue -
                                      data[18].ConcessionRevenue) *
                                      100) /
                                      data[18].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].ConcessionRevenue -
                                      data[18].ConcessionRevenue) *
                                      100) /
                                      data[18].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 18 &&
                              data[19] &&
                              data[19].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].ConcessionRevenue -
                                          data[19].ConcessionRevenue) *
                                          100) /
                                          data[19].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].ConcessionRevenue -
                                      data[19].ConcessionRevenue) *
                                      100) /
                                      data[19].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].ConcessionRevenue -
                                      data[19].ConcessionRevenue) *
                                      100) /
                                      data[19].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 19 &&
                              data[20] &&
                              data[20].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].ConcessionRevenue -
                                          data[20].ConcessionRevenue) *
                                          100) /
                                          data[20].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].ConcessionRevenue -
                                      data[20].ConcessionRevenue) *
                                      100) /
                                      data[20].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].ConcessionRevenue -
                                      data[20].ConcessionRevenue) *
                                      100) /
                                      data[20].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 20 &&
                              data[21] &&
                              data[21].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].ConcessionRevenue -
                                          data[21].ConcessionRevenue) *
                                          100) /
                                          data[21].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].ConcessionRevenue -
                                      data[21].ConcessionRevenue) *
                                      100) /
                                      data[21].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].ConcessionRevenue -
                                      data[21].ConcessionRevenue) *
                                      100) /
                                      data[21].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 21 &&
                              data[22] &&
                              data[22].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].ConcessionRevenue -
                                          data[22].ConcessionRevenue) *
                                          100) /
                                          data[22].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].ConcessionRevenue -
                                      data[22].ConcessionRevenue) *
                                      100) /
                                      data[22].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].ConcessionRevenue -
                                      data[22].ConcessionRevenue) *
                                      100) /
                                      data[22].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 22 &&
                              data[23] &&
                              data[23].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].ConcessionRevenue -
                                          data[23].ConcessionRevenue) *
                                          100) /
                                          data[23].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].ConcessionRevenue -
                                      data[23].ConcessionRevenue) *
                                      100) /
                                      data[23].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].ConcessionRevenue -
                                      data[23].ConcessionRevenue) *
                                      100) /
                                      data[23].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 23 &&
                              data[24] &&
                              data[24].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].ConcessionRevenue -
                                          data[24].ConcessionRevenue) *
                                          100) /
                                          data[24].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].ConcessionRevenue -
                                      data[24].ConcessionRevenue) *
                                      100) /
                                      data[24].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].ConcessionRevenue -
                                      data[24].ConcessionRevenue) *
                                      100) /
                                      data[24].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 24 &&
                              data[25] &&
                              data[25].ConcessionRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].ConcessionRevenue -
                                          data[25].ConcessionRevenue) *
                                          100) /
                                          data[25].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].ConcessionRevenue -
                                      data[25].ConcessionRevenue) *
                                      100) /
                                      data[25].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].ConcessionRevenue -
                                      data[25].ConcessionRevenue) *
                                      100) /
                                      data[25].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}
                          </>
                        ) : (
                          radioCheched === "0" && (
                            <>
                              {index === 0 && (
                                <span className="thisYear">I år</span>
                              )}

                              {index === 1 && data[1].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[1].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[1].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[1].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 2 && data[2].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[2].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[2].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[2].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 3 && data[3].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[3].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[3].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[3].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 4 && data[4].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[4].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[4].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[4].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 5 && data[5].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[5].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[5].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[5].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 6 && data[6].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[6].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[6].ConcessionRevenue -
                                      data[0].ConcessionRevenuet) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[6].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 7 && data[7].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[7].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[7].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[7].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 8 && data[8].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[8].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[8].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[8].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 9 && data[3].ConcessionRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[9].ConcessionRevenue -
                                          data[0].ConcessionRevenue) *
                                          100) /
                                          data[0].ConcessionRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[9].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[9].ConcessionRevenue -
                                      data[0].ConcessionRevenue) *
                                      100) /
                                      data[0].ConcessionRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 10 &&
                                data[10].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[10].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[10].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[10].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 11 &&
                                data[11].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[11].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[11].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[11].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 12 &&
                                data[12].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[12].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[12].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[12].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 13 &&
                                data[13].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[13].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[13].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[13].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 14 &&
                                data[14].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[14].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[14].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[14].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 15 &&
                                data[15].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[15].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[15].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[15].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 16 &&
                                data[16].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[16].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[16].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[16].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 17 &&
                                data[17].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[17].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[17].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[17].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 18 &&
                                data[18].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[18].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[18].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[18].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 19 &&
                                data[19].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[19].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[19].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[19].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 20 &&
                                data[20].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[20].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[20].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[20].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 21 &&
                                data[21].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[21].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[21].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[21].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 22 &&
                                data[22].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[22].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[22].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[22].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 23 &&
                                data[23].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[23].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[23].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[23].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 24 &&
                                data[24].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[24].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[24].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[24].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 25 &&
                                data[25].ConcessionRevenue > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((data[25].ConcessionRevenue -
                                            data[0].ConcessionRevenue) *
                                            100) /
                                            data[0].ConcessionRevenue
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((data[25].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((data[25].ConcessionRevenue -
                                        data[0].ConcessionRevenue) *
                                        100) /
                                        data[0].ConcessionRevenue
                                    ) + "%"}
                                  </span>
                                )}
                            </>
                          )
                        )}
                      </div>
                      <p
                        className="progressValue"
                        style={{
                          paddingLeft:
                            Math.round(item.ConcessionRevenue) > 0
                              ? "10px"
                              : "",
                          marginLeft:
                            Math.round(item.ConcessionRevenue) > 0
                              ? ""
                              : "-45px",
                          color: "#d27817",
                        }}
                        
                      >
                        {Math.round(item.ConcessionRevenue).toLocaleString(
                          "da"
                        )}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
           ) : ' ' }
          {/* kioskomsætning slutter her */}
          <br />
          { pillarsPicked === false || (pillarsPicked === true && kioskomsPiller === 'true') ? (
          <div>
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {" "}
              Kioskomsætning pr solgt billet
            </p>
            <div className="progressbarsContainer">
              <div style={{ margin: "20px 61px 20px 20px " }}>
               
                {/* {data && data[1]} */}
                {data.length > 11 &&
                  data.map((item, index) => (
                    <div className="progressBars">
                      <div
                        className={`progressData progressBars_${index}`}
                        style={{
                          width:
                            Math.round(item.ConcessionRevenue) > 1
                              ? Math.round(
                                  (Math.round(
                                    item.ConcessionRevenue / item.TicketCount
                                  ) /
                                    Math.round(maxKioskPerBillet * 1.1)) *
                                    100
                                ) + "%"
                              : "22%",
                          minWidth:
                            Math.round(item.ConcessionRevenue) > 1 ? "25%" : "",
                          backgroundColor:
                            Math.round(
                              item.ConcessionRevenue / item.TicketCount
                            ) > 0
                              ? "#adb0ff"
                              : "none",
                        }}
                      ></div>

                      <div>
                        <span className="progressDato">
                          {item.FromDate.slice(8, 10) +
                            "/" +
                            item.FromDate.slice(5, 7) +
                            "-" +
                            item.FromDate.slice(2, 4)}
                        </span>
                        <span className="progressDato" style={{ left: 90 }}>
                          {item.FromDate === item.ToDate
                            ? ""
                            : item.ToDate.slice(8, 10) +
                              "/" +
                              item.ToDate.slice(5, 7) +
                              "-" +
                              item.ToDate.slice(2, 4)}
                        </span>
                        {Math.round(
                          item.ConcessionRevenue / item.TicketCount
                        ) <= 0 ? (
                          ""
                        ) : radioCheched === "1" ? (
                          <>
                            {index === 0 &&
                              Math.round(
                                data[1].ConcessionRevenue / data[1].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        ) -
                                          Math.round(
                                            data[1].ConcessionRevenue /
                                              data[1].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[1].ConcessionRevenue /
                                              data[1].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    ) -
                                      Math.round(
                                        data[1].ConcessionRevenue /
                                          data[1].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[1].ConcessionRevenue /
                                          data[1].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[0].ConcessionRevenue /
                                        data[0].TicketCount
                                    ) -
                                      Math.round(
                                        data[1].ConcessionRevenue /
                                          data[1].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[1].ConcessionRevenue /
                                          data[1].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 1 &&
                              Math.round(
                                data[2].ConcessionRevenue / data[2].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[1].ConcessionRevenue /
                                            data[1].TicketCount
                                        ) -
                                          Math.round(
                                            data[2].ConcessionRevenue /
                                              data[2].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[2].ConcessionRevenue /
                                              data[2].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[1].ConcessionRevenue /
                                        data[1].TicketCount
                                    ) -
                                      Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[1].ConcessionRevenue /
                                        data[1].TicketCount
                                    ) -
                                      Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 2 &&
                              data[2].TicketCount > 0 &&
                              Math.round(
                                data[3].ConcessionRevenue / data[3].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[2].ConcessionRevenue /
                                            data[2].TicketCount
                                        ) -
                                          Math.round(
                                            data[3].ConcessionRevenue /
                                              data[3].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[3].ConcessionRevenue /
                                              data[3].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[2].ConcessionRevenue /
                                        data[2].TicketCount
                                    ) -
                                      Math.round(
                                        data[3].ConcessionRevenue /
                                          data[3].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[3].ConcessionRevenue /
                                          data[3].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[1].ConcessionRevenue /
                                        data[1].TicketCount
                                    ) -
                                      Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 3 &&
                              Math.round(
                                data[4].ConcessionRevenue / data[4].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[3].ConcessionRevenue /
                                            data[3].TicketCount
                                        ) -
                                          Math.round(
                                            data[4].ConcessionRevenue /
                                              data[4].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[4].ConcessionRevenue /
                                              data[4].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[3].ConcessionRevenue /
                                        data[3].TicketCount
                                    ) -
                                      Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[3].ConcessionRevenue /
                                        data[3].TicketCount
                                    ) -
                                      Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 4 &&
                              Math.round(
                                data[5].ConcessionRevenue / data[5].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[4].ConcessionRevenue /
                                            data[4].TicketCount
                                        ) -
                                          Math.round(
                                            data[5].ConcessionRevenue /
                                              data[5].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[5].ConcessionRevenue /
                                              data[5].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[4].ConcessionRevenue /
                                        data[4].TicketCount
                                    ) -
                                      Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[4].ConcessionRevenue /
                                        data[4].TicketCount
                                    ) -
                                      Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 5 &&
                              Math.round(
                                data[6].ConcessionRevenue / data[6].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[5].ConcessionRevenue /
                                            data[5].TicketCount
                                        ) -
                                          Math.round(
                                            data[6].ConcessionRevenue /
                                              data[6].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[6].ConcessionRevenue /
                                              data[6].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[5].ConcessionRevenue /
                                        data[5].TicketCount
                                    ) -
                                      Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[5].ConcessionRevenue /
                                        data[5].TicketCount
                                    ) -
                                      Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 6 &&
                              Math.round(
                                data[7].ConcessionRevenue / data[7].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[6].ConcessionRevenue /
                                            data[6].TicketCount
                                        ) -
                                          Math.round(
                                            data[7].ConcessionRevenue /
                                              data[7].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[7].ConcessionRevenue /
                                              data[7].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[6].ConcessionRevenue /
                                        data[6].TicketCount
                                    ) -
                                      Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[6].ConcessionRevenue /
                                        data[6].TicketCount
                                    ) -
                                      Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 7 &&
                              Math.round(
                                data[8].ConcessionRevenue / data[8].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[7].ConcessionRevenue /
                                            data[7].TicketCount
                                        ) -
                                          Math.round(
                                            data[8].ConcessionRevenue /
                                              data[8].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[8].ConcessionRevenue /
                                              data[8].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[7].ConcessionRevenue /
                                        data[7].TicketCount
                                    ) -
                                      Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[7].ConcessionRevenue /
                                        data[7].TicketCount
                                    ) -
                                      Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 8 &&
                              Math.round(
                                data[9].ConcessionRevenue / data[9].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[8].ConcessionRevenue /
                                            data[8].TicketCount
                                        ) -
                                          Math.round(
                                            data[9].ConcessionRevenue /
                                              data[9].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[9].ConcessionRevenue /
                                              data[9].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[8].ConcessionRevenue /
                                        data[8].TicketCount
                                    ) -
                                      Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[8].ConcessionRevenue /
                                        data[8].TicketCount
                                    ) -
                                      Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 9 &&
                              Math.round(
                                data[10].ConcessionRevenue /
                                  data[10].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[9].ConcessionRevenue /
                                            data[9].TicketCount
                                        ) -
                                          Math.round(
                                            data[10].ConcessionRevenue /
                                              data[10].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[10].ConcessionRevenue /
                                              data[10].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[9].ConcessionRevenue /
                                        data[9].TicketCount
                                    ) -
                                      Math.round(
                                        data[10].ConcessionRevenue /
                                          data[10].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[10].ConcessionRevenue /
                                          data[10].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[9].ConcessionRevenue /
                                        data[9].TicketCount
                                    ) -
                                      Math.round(
                                        data[10].ConcessionRevenue /
                                          data[10].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[10].ConcessionRevenue /
                                          data[10].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 10 &&
                              Math.round(
                                data[11].ConcessionRevenue /
                                  data[11].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[10].ConcessionRevenue /
                                            data[10].TicketCount
                                        ) -
                                          Math.round(
                                            data[11].ConcessionRevenue /
                                              data[11].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[11].ConcessionRevenue /
                                              data[11].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[10].ConcessionRevenue /
                                        data[10].TicketCount
                                    ) -
                                      Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[10].ConcessionRevenue /
                                        data[10].TicketCount
                                    ) -
                                      Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 11 &&
                              Math.round(
                                data[12].ConcessionRevenue /
                                  data[12].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[11].ConcessionRevenue /
                                            data[11].TicketCount
                                        ) -
                                          Math.round(
                                            data[12].ConcessionRevenue /
                                              data[12].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[12].ConcessionRevenue /
                                              data[12].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[10].ConcessionRevenue /
                                        data[10].TicketCount
                                    ) -
                                      Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[11].ConcessionRevenue /
                                        data[11].TicketCount
                                    ) -
                                      Math.round(
                                        data[12].ConcessionRevenue /
                                          data[12].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[12].ConcessionRevenue /
                                          data[12].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 12 &&
                              Math.round(
                                data[13].ConcessionRevenue /
                                  data[13].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[12].ConcessionRevenue /
                                            data[12].TicketCount
                                        ) -
                                          Math.round(
                                            data[13].ConcessionRevenue /
                                              data[13].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[13].ConcessionRevenue /
                                              data[13].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[12].ConcessionRevenue /
                                        data[12].TicketCount
                                    ) -
                                      Math.round(
                                        data[13].ConcessionRevenue /
                                          data[13].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[13].ConcessionRevenue /
                                          data[13].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[12].ConcessionRevenue /
                                        data[12].TicketCount
                                    ) -
                                      Math.round(
                                        data[13].ConcessionRevenue /
                                          data[13].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[13].ConcessionRevenue /
                                          data[13].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 13 &&
                              Math.round(
                                data[14].ConcessionRevenue /
                                  data[14].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[13].ConcessionRevenue /
                                            data[13].TicketCount
                                        ) -
                                          Math.round(
                                            data[14].ConcessionRevenue /
                                              data[14].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[14].ConcessionRevenue /
                                              data[14].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[13].ConcessionRevenue /
                                        data[13].TicketCount
                                    ) -
                                      Math.round(
                                        data[14].ConcessionRevenue /
                                          data[14].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[14].ConcessionRevenue /
                                          data[14].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[13].ConcessionRevenue /
                                        data[13].TicketCount
                                    ) -
                                      Math.round(
                                        data[14].ConcessionRevenue /
                                          data[14].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[14].ConcessionRevenue /
                                          data[14].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 14 &&
                              Math.round(
                                data[15].ConcessionRevenue /
                                  data[15].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[14].ConcessionRevenue /
                                            data[14].TicketCount
                                        ) -
                                          Math.round(
                                            data[15].ConcessionRevenue /
                                              data[15].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[15].ConcessionRevenue /
                                              data[15].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[14].ConcessionRevenue /
                                        data[14].TicketCount
                                    ) -
                                      Math.round(
                                        data[15].ConcessionRevenue /
                                          data[15].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[15].ConcessionRevenue /
                                          data[15].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[14].ConcessionRevenue /
                                        data[14].TicketCount
                                    ) -
                                      Math.round(
                                        data[14].ConcessionRevenue /
                                          data[15].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[15].ConcessionRevenue /
                                          data[15].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 15 &&
                              Math.round(
                                data[16].ConcessionRevenue /
                                  data[16].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[15].ConcessionRevenue /
                                            data[15].TicketCount
                                        ) -
                                          Math.round(
                                            data[16].ConcessionRevenue /
                                              data[16].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[16].ConcessionRevenue /
                                              data[16].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[15].ConcessionRevenue /
                                        data[15].TicketCount
                                    ) -
                                      Math.round(
                                        data[16].ConcessionRevenue /
                                          data[16].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[16].ConcessionRevenue /
                                          data[16].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[15].ConcessionRevenue /
                                        data[15].TicketCount
                                    ) -
                                      Math.round(
                                        data[16].ConcessionRevenue /
                                          data[16].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[16].ConcessionRevenue /
                                          data[16].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 16 &&
                              Math.round(
                                data[17].ConcessionRevenue /
                                  data[17].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[16].ConcessionRevenue /
                                            data[16].TicketCount
                                        ) -
                                          Math.round(
                                            data[17].ConcessionRevenue /
                                              data[17].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[17].ConcessionRevenue /
                                              data[17].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[16].ConcessionRevenue /
                                        data[16].TicketCount
                                    ) -
                                      Math.round(
                                        data[17].ConcessionRevenue /
                                          data[17].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[17].ConcessionRevenue /
                                          data[17].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[16].ConcessionRevenue /
                                        data[16].TicketCount
                                    ) -
                                      Math.round(
                                        data[17].ConcessionRevenue /
                                          data[17].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[17].ConcessionRevenue /
                                          data[17].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 17 &&
                              Math.round(
                                data[18].ConcessionRevenue /
                                  data[18].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[17].ConcessionRevenue /
                                            data[17].TicketCount
                                        ) -
                                          Math.round(
                                            data[18].ConcessionRevenue /
                                              data[18].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[18].ConcessionRevenue /
                                              data[18].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[17].ConcessionRevenue /
                                        data[17].TicketCount
                                    ) -
                                      Math.round(
                                        data[18].ConcessionRevenue /
                                          data[18].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[18].ConcessionRevenue /
                                          data[18].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[17].ConcessionRevenue /
                                        data[17].TicketCount
                                    ) -
                                      Math.round(
                                        data[18].ConcessionRevenue /
                                          data[18].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[18].ConcessionRevenue /
                                          data[18].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 18 &&
                              Math.round(
                                data[19].ConcessionRevenue /
                                  data[19].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[18].ConcessionRevenue /
                                            data[18].TicketCount
                                        ) -
                                          Math.round(
                                            data[19].ConcessionRevenue /
                                              data[19].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[19].ConcessionRevenue /
                                              data[19].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[18].ConcessionRevenue /
                                        data[18].TicketCount
                                    ) -
                                      Math.round(
                                        data[19].ConcessionRevenue /
                                          data[19].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[19].ConcessionRevenue /
                                          data[19].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[18].ConcessionRevenue /
                                        data[18].TicketCount
                                    ) -
                                      Math.round(
                                        data[19].ConcessionRevenue /
                                          data[19].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[19].ConcessionRevenue /
                                          data[19].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 19 &&
                              Math.round(
                                data[20].ConcessionRevenue /
                                  data[20].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[19].ConcessionRevenue /
                                            data[19].TicketCount
                                        ) -
                                          Math.round(
                                            data[20].ConcessionRevenue /
                                              data[20].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[20].ConcessionRevenue /
                                              data[20].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[19].ConcessionRevenue /
                                        data[19].TicketCount
                                    ) -
                                      Math.round(
                                        data[20].ConcessionRevenue /
                                          data[20].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[20].ConcessionRevenue /
                                          data[20].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[19].ConcessionRevenue /
                                        data[19].TicketCount
                                    ) -
                                      Math.round(
                                        data[20].ConcessionRevenue /
                                          data[20].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[20].ConcessionRevenue /
                                          data[20].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 20 &&
                              Math.round(
                                data[21].ConcessionRevenue /
                                  data[21].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[20].ConcessionRevenue /
                                            data[20].TicketCount
                                        ) -
                                          Math.round(
                                            data[21].ConcessionRevenue /
                                              data[21].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[21].ConcessionRevenue /
                                              data[21].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[20].ConcessionRevenue /
                                        data[20].TicketCount
                                    ) -
                                      Math.round(
                                        data[21].ConcessionRevenue /
                                          data[21].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[21].ConcessionRevenue /
                                          data[21].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[20].ConcessionRevenue /
                                        data[20].TicketCount
                                    ) -
                                      Math.round(
                                        data[21].ConcessionRevenue /
                                          data[21].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[21].ConcessionRevenue /
                                          data[21].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 21 &&
                              Math.round(
                                data[22].ConcessionRevenue /
                                  data[22].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[21].ConcessionRevenue /
                                            data[21].TicketCount
                                        ) -
                                          Math.round(
                                            data[22].ConcessionRevenue /
                                              data[22].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[22].ConcessionRevenue /
                                              data[22].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[21].ConcessionRevenue /
                                        data[21].TicketCount
                                    ) -
                                      Math.round(
                                        data[22].ConcessionRevenue /
                                          data[22].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[22].ConcessionRevenue /
                                          data[22].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[21].ConcessionRevenue /
                                        data[21].TicketCount
                                    ) -
                                      Math.round(
                                        data[22].ConcessionRevenue /
                                          data[22].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[22].ConcessionRevenue /
                                          data[22].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 22 &&
                              Math.round(
                                data[23].ConcessionRevenue /
                                  data[23].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[22].ConcessionRevenue /
                                            data[22].TicketCount
                                        ) -
                                          Math.round(
                                            data[23].ConcessionRevenue /
                                              data[23].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[23].ConcessionRevenue /
                                              data[23].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[22].ConcessionRevenue /
                                        data[22].TicketCount
                                    ) -
                                      Math.round(
                                        data[23].ConcessionRevenue /
                                          data[23].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[23].ConcessionRevenue /
                                          data[23].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[22].ConcessionRevenue /
                                        data[22].TicketCount
                                    ) -
                                      Math.round(
                                        data[23].ConcessionRevenue /
                                          data[23].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[23].ConcessionRevenue /
                                          data[23].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 23 &&
                              Math.round(
                                data[24].ConcessionRevenue /
                                  data[24].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[23].ConcessionRevenue /
                                            data[23].TicketCount
                                        ) -
                                          Math.round(
                                            data[24].ConcessionRevenue /
                                              data[24].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[24].ConcessionRevenue /
                                              data[24].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[23].ConcessionRevenue /
                                        data[23].TicketCount
                                    ) -
                                      Math.round(
                                        data[24].ConcessionRevenue /
                                          data[24].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[24].ConcessionRevenue /
                                          data[24].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[23].ConcessionRevenue /
                                        data[23].TicketCount
                                    ) -
                                      Math.round(
                                        data[24].ConcessionRevenue /
                                          data[24].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[24].ConcessionRevenue /
                                          data[24].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 24 &&
                              Math.round(
                                data[25].ConcessionRevenue /
                                  data[25].TicketCount
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[24].ConcessionRevenue /
                                            data[24].TicketCount
                                        ) -
                                          Math.round(
                                            data[25].ConcessionRevenue /
                                              data[25].TicketCount
                                          )) *
                                          100) /
                                          Math.round(
                                            data[25].ConcessionRevenue /
                                              data[25].TicketCount
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[24].ConcessionRevenue /
                                        data[24].TicketCount
                                    ) -
                                      Math.round(
                                        data[25].ConcessionRevenue /
                                          data[25].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[25].ConcessionRevenue /
                                          data[25].TicketCount
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}

                                  {Math.round(
                                    ((Math.round(
                                      data[24].ConcessionRevenue /
                                        data[24].TicketCount
                                    ) -
                                      Math.round(
                                        data[25].ConcessionRevenue /
                                          data[25].TicketCount
                                      )) *
                                      100) /
                                      Math.round(
                                        data[25].ConcessionRevenue /
                                          data[25].TicketCount
                                      )
                                  ) + "%"}
                                </span>
                              )}
                          </>
                        ) : (
                          radioCheched === "0" && (
                            <>
                              {index === 0 && (
                                <span className="thisYear">I år</span>
                              )}

                              {index === 1 &&
                                Math.round(
                                  data[1].ConcessionRevenue /
                                    data[1].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[1].ConcessionRevenue /
                                              data[1].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[1].ConcessionRevenue /
                                          data[1].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[1].ConcessionRevenue /
                                          data[1].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 2 &&
                                Math.round(
                                  data[2].ConcessionRevenue /
                                    data[2].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[2].ConcessionRevenue /
                                              data[2].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[2].ConcessionRevenue /
                                          data[2].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 3 &&
                                Math.round(
                                  data[3].ConcessionRevenue /
                                    data[3].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[3].ConcessionRevenue /
                                              data[3].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[3].ConcessionRevenue /
                                          data[3].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[3].ConcessionRevenue /
                                          data[3].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 4 &&
                                Math.round(
                                  data[4].ConcessionRevenue /
                                    data[4].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[4].ConcessionRevenue /
                                              data[4].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[4].ConcessionRevenue /
                                          data[4].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 5 &&
                                Math.round(
                                  data[5].ConcessionRevenue /
                                    data[5].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[5].ConcessionRevenue /
                                              data[5].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[5].ConcessionRevenue /
                                          data[5].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 6 &&
                                Math.round(
                                  data[6].ConcessionRevenue /
                                    data[6].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[6].ConcessionRevenue /
                                              data[6].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[6].ConcessionRevenue /
                                          data[6].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 7 &&
                                Math.round(
                                  data[7].ConcessionRevenue /
                                    data[7].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[7].ConcessionRevenue /
                                              data[7].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[7].ConcessionRevenue /
                                          data[7].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 8 &&
                                Math.round(
                                  data[8].ConcessionRevenue /
                                    data[8].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[8].ConcessionRevenue /
                                              data[8].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[8].ConcessionRevenue /
                                          data[8].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 9 &&
                                Math.round(
                                  data[9].ConcessionRevenue /
                                    data[9].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[9].ConcessionRevenue /
                                              data[9].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[9].ConcessionRevenue /
                                          data[9].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 10 &&
                                Math.round(
                                  data[10].ConcessionRevenue /
                                    data[10].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[10].ConcessionRevenue /
                                              data[10].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[10].ConcessionRevenue /
                                          data[10].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[10].ConcessionRevenue /
                                          data[10].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 11 &&
                                Math.round(
                                  data[11].ConcessionRevenue /
                                    data[11].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[11].ConcessionRevenue /
                                              data[11].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[11].ConcessionRevenue /
                                          data[11].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 12 &&
                                Math.round(
                                  data[12].ConcessionRevenue /
                                    data[12].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[12].ConcessionRevenue /
                                              data[12].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[12].ConcessionRevenue /
                                          data[12].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[12].ConcessionRevenue /
                                          data[12].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 13 &&
                                Math.round(
                                  data[13].ConcessionRevenue /
                                    data[13].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[13].ConcessionRevenue /
                                              data[13].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[13].ConcessionRevenue /
                                          data[13].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[13].ConcessionRevenue /
                                          data[13].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 14 &&
                                Math.round(
                                  data[14].ConcessionRevenue /
                                    data[14].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[14].ConcessionRevenue /
                                              data[14].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[14].ConcessionRevenue /
                                          data[14].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[14].ConcessionRevenue /
                                          data[14].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 15 &&
                                Math.round(
                                  data[15].ConcessionRevenue /
                                    data[15].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[15].ConcessionRevenue /
                                              data[15].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[15].ConcessionRevenue /
                                          data[15].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[15].ConcessionRevenue /
                                          data[15].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 16 &&
                                Math.round(
                                  data[16].ConcessionRevenue /
                                    data[16].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[16].ConcessionRevenue /
                                              data[16].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[16].ConcessionRevenue /
                                          data[16].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[16].ConcessionRevenue /
                                          data[16].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 17 &&
                                Math.round(
                                  data[17].ConcessionRevenue /
                                    data[17].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[17].ConcessionRevenue /
                                              data[17].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[17].ConcessionRevenue /
                                          data[17].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[17].ConcessionRevenue /
                                          data[17].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 18 &&
                                Math.round(
                                  data[18].ConcessionRevenue /
                                    data[18].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[18].ConcessionRevenue /
                                              data[18].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[18].ConcessionRevenue /
                                          data[18].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[18].ConcessionRevenue /
                                          data[18].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 19 &&
                                Math.round(
                                  data[19].ConcessionRevenue /
                                    data[19].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[19].ConcessionRevenue /
                                              data[19].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[19].ConcessionRevenue /
                                          data[19].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[19].ConcessionRevenue /
                                          data[19].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 20 &&
                                Math.round(
                                  data[20].ConcessionRevenue /
                                    data[20].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[20].ConcessionRevenue /
                                              data[20].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[20].ConcessionRevenue /
                                          data[20].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[20].ConcessionRevenue /
                                          data[20].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 21 &&
                                Math.round(
                                  data[21].ConcessionRevenue /
                                    data[21].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[21].ConcessionRevenue /
                                              data[21].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[21].ConcessionRevenue /
                                          data[21].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[21].ConcessionRevenue /
                                          data[21].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 22 &&
                                Math.round(
                                  data[22].ConcessionRevenue /
                                    data[22].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[22].ConcessionRevenue /
                                              data[22].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[22].ConcessionRevenue /
                                          data[22].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[22].ConcessionRevenue /
                                          data[22].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 23 &&
                                Math.round(
                                  data[23].ConcessionRevenue /
                                    data[23].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[23].ConcessionRevenue /
                                              data[23].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[23].ConcessionRevenue /
                                          data[23].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[23].ConcessionRevenue /
                                          data[23].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 24 &&
                                Math.round(
                                  data[24].ConcessionRevenue /
                                    data[24].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[24].ConcessionRevenue /
                                              data[24].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[24].ConcessionRevenue /
                                          data[24].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[24].ConcessionRevenue /
                                          data[24].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 25 &&
                                Math.round(
                                  data[25].ConcessionRevenue /
                                    data[25].TicketCount
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[25].ConcessionRevenue /
                                              data[25].TicketCount
                                          ) -
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].ConcessionRevenue /
                                                data[0].TicketCount
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[25].ConcessionRevenue /
                                          data[25].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}

                                    {Math.round(
                                      ((Math.round(
                                        data[25].ConcessionRevenue /
                                          data[25].TicketCount
                                      ) -
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].ConcessionRevenue /
                                            data[0].TicketCount
                                        )
                                    ) + "%"}
                                  </span>
                                )}
                            </>
                          )
                        )}
                      </div>
                      <p
                        className="progressValue"
                        style={{
                          paddingLeft:
                            Math.round(
                              item.ConcessionRevenue / item.TicketCount
                            ) > 0
                              ? "10px"
                              : "",
                          color: "#7174c7",
                          marginLeft:
                            Math.round(
                              item.ConcessionRevenue / item.TicketCount
                            ) > 0
                              ? "0px"
                              : "-45px",
                        }}
                      >
                        {item.TicketCount <= 0
                          ? "0"
                          : Math.round(
                              item.ConcessionRevenue / item.TicketCount
                            )}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
           ) : ' ' }
           {/* kioskomsætning per solgt billet slutter her */}
          <br />
          { pillarsPicked === false || (pillarsPicked === true && otherPiller === 'true') ? (
          <div>
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Anden omsætning
            </p>

            <div className="progressbarsContainer">
              <div style={{ margin: "20px 61px 20px 20px " }}>
               

                {data.length > 11 &&
                  data.map((item, index) => (
                    <div className="progressBars">
                      <div
                        className={`progressData progressBars_${index}`}
                        style={{
                          width:
                            item.OtherRevenue > 0
                              ? Math.round(
                                  (item.OtherRevenue /
                                    Math.round(maxAndenOms * 1.1)) *
                                    100
                                ) + "%"
                              : "22%",
                          minWidth: item.OtherRevenue > 0 ? "25%" : "",
                          backgroundColor:
                            item.OtherRevenue > 0 ? "#f5bd45" : "none",
                        }}
                      ></div>
                      <div className="progressCon">
                        <div className="progressDato">
                          <span>
                            {" "}
                            {item.FromDate.slice(8, 10) +
                              "/" +
                              item.FromDate.slice(5, 7) +
                              "-" +
                              item.FromDate.slice(2, 4)}
                          </span>
                        </div>
                        <span className="progressDato" style={{ left: 90 }}>
                          {item.FromDate === item.ToDate
                            ? ""
                            : item.ToDate.slice(8, 10) +
                              "/" +
                              item.ToDate.slice(5, 7) +
                              "-" +
                              item.ToDate.slice(2, 4)}
                        </span>
                        {item.OtherRevenue === 0 ? (
                          ""
                        ) : radioCheched === "1" ? (
                          <>
                            {index === 0 && data[1].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[0].OtherRevenue -
                                        data[1].OtherRevenue) *
                                        100) /
                                        data[1].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[0].OtherRevenue -
                                    data[1].OtherRevenue) *
                                    100) /
                                    data[1].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[0].OtherRevenue -
                                    data[1].OtherRevenue) *
                                    100) /
                                    data[1].OtherRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 1 && data[2].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[1].OtherRevenue -
                                        data[2].OtherRevenue) *
                                        100) /
                                        data[2].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[1].OtherRevenue -
                                    data[2].OtherRevenue) *
                                    100) /
                                    data[2].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[1].OtherRevenue -
                                    data[2].OtherRevenue) *
                                    100) /
                                    data[2].OtherRevenue
                                ) + "%"}
                              </span>
                            )}
                            {index === 2 && data[3].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[2].OtherRevenue -
                                        data[3].OtherRevenue) *
                                        100) /
                                        data[3].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[2].OtherRevenue -
                                    data[3].OtherRevenue) *
                                    100) /
                                    data[3].TicketCount
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[2].OtherRevenue -
                                    data[3].OtherRevenue) *
                                    100) /
                                    data[3].TicketCount
                                ) + "%"}
                              </span>
                            )}
                            {index === 3 && data[4].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[3].OtherRevenue -
                                        data[4].OtherRevenue) *
                                        100) /
                                        data[4].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[3].OtherRevenue -
                                    data[4].OtherRevenue) *
                                    100) /
                                    data[4].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[3].OtherRevenue -
                                    data[4].OtherRevenue) *
                                    100) /
                                    data[4].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 4 && data[5].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[4].OtherRevenue -
                                        data[5].OtherRevenue) *
                                        100) /
                                        data[5].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[4].OtherRevenue -
                                    data[5].OtherRevenue) *
                                    100) /
                                    data[5].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[4].OtherRevenue -
                                    data[5].OtherRevenue) *
                                    100) /
                                    data[5].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 5 && data[6].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[5].OtherRevenue -
                                        data[6].OtherRevenue) *
                                        100) /
                                        data[6].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[5].OtherRevenue -
                                    data[6].OtherRevenue) *
                                    100) /
                                    data[6].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[5].OtherRevenue -
                                    data[6].OtherRevenue) *
                                    100) /
                                    data[6].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 6 && data[7].OtherRevenuet > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[6].OtherRevenue -
                                        data[7].OtherRevenue) *
                                        100) /
                                        data[7].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[6].OtherRevenue -
                                    data[7].OtherRevenue) *
                                    100) /
                                    data[7].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[6].OtherRevenue -
                                    data[7].OtherRevenue) *
                                    100) /
                                    data[7].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 7 && data[8].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[7].OtherRevenue -
                                        data[8].OtherRevenue) *
                                        100) /
                                        data[8].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[7].OtherRevenue -
                                    data[8].OtherRevenue) *
                                    100) /
                                    data[8].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[7].OtherRevenue -
                                    data[8].OtherRevenue) *
                                    100) /
                                    data[8].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 8 && data[9].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[8].OtherRevenue -
                                        data[9].OtherRevenue) *
                                        100) /
                                        data[9].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[8].OtherRevenue -
                                    data[9].OtherRevenue) *
                                    100) /
                                    data[9].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[8].OtherRevenue -
                                    data[9].OtherRevenue) *
                                    100) /
                                    data[9].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 9 && data[10].OtherRevenuet > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[9].OtherRevenue -
                                        data[10].OtherRevenue) *
                                        100) /
                                        data[10].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[9].OtherRevenue -
                                    data[10].OtherRevenue) *
                                    100) /
                                    data[10].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[9].OtherRevenue -
                                    data[10].OtherRevenue) *
                                    100) /
                                    data[10].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 10 && data[11].OtherRevenue > 0 && (
                              <span
                                className="progressProcent"
                                style={{
                                  backgroundColor:
                                    Math.round(
                                      ((data[10].OtherRevenue -
                                        data[11].OtherRevenue) *
                                        100) /
                                        data[11].OtherRevenue
                                    ) > 0
                                      ? "#40b22d"
                                      : "#cf4747",
                                }}
                              >
                                {Math.round(
                                  ((data[10].OtherRevenue -
                                    data[11].OtherRevenue) *
                                    100) /
                                    data[11].OtherRevenue
                                ) > 0
                                  ? "+"
                                  : ""}
                                {Math.round(
                                  ((data[10].OtherRevenue -
                                    data[11].OtherRevenue) *
                                    100) /
                                    data[11].OtherRevenue
                                ) + "%"}
                              </span>
                            )}

                            {index === 11 &&
                              data[12] &&
                              data[12].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].OtherRevenue -
                                          data[12].OtherRevenue) *
                                          100) /
                                          data[12].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].OtherRevenue -
                                      data[12].OtherRevenue) *
                                      100) /
                                      data[12].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].OtherRevenue -
                                      data[12].OtherRevenue) *
                                      100) /
                                      data[12].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 12 &&
                              data[13] &&
                              data[13].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].OtherRevenue -
                                          data[13].OtherRevenue) *
                                          100) /
                                          data[13].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].OtherRevenue -
                                      data[13].OtherRevenue) *
                                      100) /
                                      data[13].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].OtherRevenue -
                                      data[13].OtherRevenue) *
                                      100) /
                                      data[13].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 13 &&
                              data[14] &&
                              data[14].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].OtherRevenue -
                                          data[14].OtherRevenue) *
                                          100) /
                                          data[14].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].OtherRevenue -
                                      data[14].OtherRevenue) *
                                      100) /
                                      data[14].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].OtherRevenue -
                                      data[14].OtherRevenue) *
                                      100) /
                                      data[14].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 14 &&
                              data[15] &&
                              data[15].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].OtherRevenue -
                                          data[15].OtherRevenue) *
                                          100) /
                                          data[15].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].OtherRevenue -
                                      data[15].OtherRevenue) *
                                      100) /
                                      data[15].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].OtherRevenue -
                                      data[15].OtherRevenue) *
                                      100) /
                                      data[15].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 15 &&
                              data[16] &&
                              data[16].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].OtherRevenue -
                                          data[16].OtherRevenue) *
                                          100) /
                                          data[16].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].OtherRevenue -
                                      data[16].OtherRevenue) *
                                      100) /
                                      data[16].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].OtherRevenue -
                                      data[16].OtherRevenue) *
                                      100) /
                                      data[16].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 16 &&
                              data[17] &&
                              data[17].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].OtherRevenue -
                                          data[17].OtherRevenue) *
                                          100) /
                                          data[17].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].OtherRevenue -
                                      data[17].OtherRevenue) *
                                      100) /
                                      data[17].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].OtherRevenue -
                                      data[17].OtherRevenue) *
                                      100) /
                                      data[17].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 17 &&
                              data[18] &&
                              data[18].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].OtherRevenue -
                                          data[18].OtherRevenue) *
                                          100) /
                                          data[18].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].OtherRevenue -
                                      data[18].OtherRevenue) *
                                      100) /
                                      data[18].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].OtherRevenue -
                                      data[18].OtherRevenue) *
                                      100) /
                                      data[18].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 18 &&
                              data[19] &&
                              data[19].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].OtherRevenue -
                                          data[19].OtherRevenue) *
                                          100) /
                                          data[19].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].OtherRevenue -
                                      data[19].OtherRevenue) *
                                      100) /
                                      data[19].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].OtherRevenue -
                                      data[19].OtherRevenue) *
                                      100) /
                                      data[19].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 19 &&
                              data[20] &&
                              data[20].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].OtherRevenue -
                                          data[20].OtherRevenue) *
                                          100) /
                                          data[20].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].OtherRevenue -
                                      data[20].OtherRevenue) *
                                      100) /
                                      data[20].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].OtherRevenue -
                                      data[20].OtherRevenue) *
                                      100) /
                                      data[20].TOtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 20 &&
                              data[21] &&
                              data[21].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].OtherRevenue -
                                          data[21].OtherRevenue) *
                                          100) /
                                          data[21].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].OtherRevenue -
                                      data[21].OtherRevenue) *
                                      100) /
                                      data[21].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].OtherRevenue -
                                      data[21].OtherRevenue) *
                                      100) /
                                      data[21].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 21 &&
                              data[22] &&
                              data[22].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].OtherRevenue -
                                          data[22].OtherRevenue) *
                                          100) /
                                          data[22].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].OtherRevenue -
                                      data[22].OtherRevenue) *
                                      100) /
                                      data[22].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].OtherRevenue -
                                      data[22].OtherRevenue) *
                                      100) /
                                      data[22].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 22 &&
                              data[23] &&
                              data[23].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].OtherRevenue -
                                          data[23].OtherRevenue) *
                                          100) /
                                          data[23].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].OtherRevenue -
                                      data[23].OtherRevenue) *
                                      100) /
                                      data[23].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].OtherRevenue -
                                      data[23].OtherRevenue) *
                                      100) /
                                      data[23].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 23 &&
                              data[24] &&
                              data[24].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].OtherRevenue -
                                          data[24].OtherRevenue) *
                                          100) /
                                          data[24].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].OtherRevenue -
                                      data[24].OtherRevenue) *
                                      100) /
                                      data[24].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].OtherRevenue -
                                      data[24].OtherRevenue) *
                                      100) /
                                      data[24].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                            {index === 24 &&
                              data[25] &&
                              data[25].OtherRevenue > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].OtherRevenue -
                                          data[25].OtherRevenue) *
                                          100) /
                                          data[25].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].OtherRevenue -
                                      data[25].OtherRevenue) *
                                      100) /
                                      data[25].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].OtherRevenue -
                                      data[25].OtherRevenue) *
                                      100) /
                                      data[25].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}
                          </>
                        ) : (
                          radioCheched === "0" && (
                            <>
                              {index === 0 && (
                                <span className="thisYear">I år</span>
                              )}

                              {index === 1 && data[1].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[1].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[1].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[1].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 2 && data[2].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[2].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[2].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[2].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 3 && data[3].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[3].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[3].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[3].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 4 && data[4].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[4].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[4].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[4].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 5 && data[5].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[5].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[5].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[5].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 6 && data[6].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[6].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[6].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[6].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 7 && data[7].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[7].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[7].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[7].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 8 && data[8].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[8].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[8].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[8].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 9 && data[9].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[9].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[9].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[9].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 10 && data[10].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[10].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[10].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].TicketCount
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[10].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 11 && data[11].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[11].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[11].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[11].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 12 && data[12].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[12].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[12].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[12].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 13 && data[13].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[13].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[13].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[13].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 14 && data[14].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[14].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[14].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[14].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 15 && data[15].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[15].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[15].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[15].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 16 && data[16].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[16].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[16].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[16].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 17 && data[17].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[17].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[17].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[17].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 18 && data[18].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[18].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[18].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[18].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 19 && data[19].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[19].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[19].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[19].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 20 && data[20].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[20].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[20].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[20].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 21 && data[21].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[21].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[21].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[21].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 22 && data[22].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[22].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[22].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[22].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 23 && data[23].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[23].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[23].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[23].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 24 && data[24].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[24].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[24].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[24].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}

                              {index === 25 && data[25].OtherRevenue > 0 && (
                                <span
                                  className="thisYear"
                                  style={{
                                    color: "white",
                                    backgroundColor:
                                      Math.round(
                                        ((data[25].OtherRevenue -
                                          data[0].OtherRevenue) *
                                          100) /
                                          data[0].OtherRevenue
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((data[25].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((data[25].OtherRevenue -
                                      data[0].OtherRevenue) *
                                      100) /
                                      data[0].OtherRevenue
                                  ) + "%"}
                                </span>
                              )}
                            </>
                          )
                        )}
                      </div>

                      <p
                        className="progressValue"
                        style={{
                          paddingLeft: item.OtherRevenue > 0 ? "10px" : "",
                          marginLeft: item.OtherRevenue > 0 ? "0px" : "-45px",

                          color: "#cb8d06",
                        }}
                      >
                        {item.OtherRevenue.toLocaleString("da")}
                      </p>
                      {/* ):''} */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
           ) : ' ' }
           {/* anden omsætning slutter her */}
          <br />
          { pillarsPicked === false || (pillarsPicked === true && totalPiller === 'true') ? (
          <div>
            <p
              style={{
                color: theme === "dark" ? "#fff" : "#46484c",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Samlet omsætning
            </p>

            <div className="progressbarsContainer">
              <div style={{ margin: "20px 61px 20px 20px " }}>
               

                {data.length > 11 &&
                  data.map((item, index) => (
                    <div className="progressBars">
                      <div
                        className={`progressData progressBars_${index}`}
                        style={{
                          width:
                            Math.round(
                              item.TicketRevenue +
                                item.ConcessionRevenue +
                                item.OtherRevenue
                            ) > 0
                              ? Math.round(
                                  (Math.round(
                                    item.ConcessionRevenue +
                                      item.TicketRevenue +
                                      item.OtherRevenue
                                  ) /
                                    Math.round(maxSamletOms * 1.1)) *
                                    100
                                ) + "%"
                              : "22%",
                          minWidth:
                            Math.round(
                              item.TicketRevenue +
                                item.ConcessionRevenue +
                                item.OtherRevenue
                            ) > 0
                              ? "25%"
                              : "",
                          backgroundColor:
                            Math.round(
                              item.TicketRevenue +
                                item.ConcessionRevenue +
                                item.OtherRevenue
                            ) > 0
                              ? "#2c6b0f"
                              : "none",
                        }}
                      ></div>
                      <div className="progressCon">
                        <div className="progressDato">
                          <span>
                            {" "}
                            {item.FromDate.slice(8, 10) +
                              "/" +
                              item.FromDate.slice(5, 7) +
                              "-" +
                              item.FromDate.slice(2, 4)}
                          </span>
                        </div>
                        <span className="progressDato" style={{ left: 90 }}>
                          {item.FromDate === item.ToDate
                            ? ""
                            : item.ToDate.slice(8, 10) +
                              "/" +
                              item.ToDate.slice(5, 7) +
                              "-" +
                              item.ToDate.slice(2, 4)}
                        </span>
                        {Math.round(
                          item.TicketRevenue +
                            item.ConcessionRevenue +
                            item.OtherRevenue
                        ) === 0 ? (
                          ""
                        ) : radioCheched === "1" ? (
                          <>
                            {index === 0 &&
                              Math.round(
                                data[1].TicketRevenue +
                                  data[1].ConcessionRevenue +
                                  data[1].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[1].TicketRevenue +
                                              data[1].ConcessionRevenue +
                                              data[1].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[1].TicketRevenue +
                                              data[1].ConcessionRevenue +
                                              data[1].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[0].TicketRevenue +
                                        data[0].ConcessionRevenue +
                                        data[0].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[1].TicketRevenue +
                                          data[1].ConcessionRevenue +
                                          data[1].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[1].TicketRevenue +
                                          data[1].ConcessionRevenue +
                                          data[1].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[0].TicketRevenue +
                                        data[0].ConcessionRevenue +
                                        data[0].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[1].TicketRevenue +
                                          data[1].ConcessionRevenue +
                                          data[1].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[1].TicketRevenue +
                                          data[1].ConcessionRevenue +
                                          data[1].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 1 &&
                              Math.round(
                                data[2].TicketRevenue +
                                  data[2].ConcessionRevenue +
                                  data[2].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[1].TicketRevenue +
                                            data[1].ConcessionRevenue +
                                            data[1].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[2].TicketRevenue +
                                              data[2].ConcessionRevenue +
                                              data[2].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[2].TicketRevenue +
                                              data[2].ConcessionRevenue +
                                              data[2].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[1].TicketRevenue +
                                        data[1].ConcessionRevenue +
                                        data[1].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[2].TicketRevenue +
                                          data[2].ConcessionRevenue +
                                          data[2].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[2].TicketRevenue +
                                          data[2].ConcessionRevenue +
                                          data[2].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[1].TicketRevenue +
                                        data[1].ConcessionRevenue +
                                        data[1].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[2].TicketRevenue +
                                          data[2].ConcessionRevenue +
                                          data[2].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[2].TicketRevenue +
                                          data[2].ConcessionRevenue +
                                          data[2].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 2 &&
                              Math.round(
                                data[3].TicketRevenue +
                                  data[3].ConcessionRevenue +
                                  data[3].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[2].TicketRevenue +
                                            data[2].ConcessionRevenue +
                                            data[2].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[3].TicketRevenue +
                                              data[3].ConcessionRevenue +
                                              data[3].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[3].TicketRevenue +
                                              data[3].ConcessionRevenue +
                                              data[3].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[2].TicketRevenue +
                                        data[2].ConcessionRevenue +
                                        data[2].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[3].TicketRevenue +
                                          data[3].ConcessionRevenue +
                                          data[3].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[3].TicketRevenue +
                                          data[3].ConcessionRevenue +
                                          data[3].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[2].TicketRevenue +
                                        data[2].ConcessionRevenue +
                                        data[2].OtherRevenue
                                    ) -
                                      data[3].TicketCount) *
                                      100) /
                                      Math.round(
                                        data[3].TicketRevenue +
                                          data[3].ConcessionRevenue +
                                          data[3].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}
                            {index === 3 &&
                              Math.round(
                                data[4].TicketRevenue +
                                  data[4].ConcessionRevenue +
                                  data[4].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[3].TicketRevenue +
                                            data[3].ConcessionRevenue +
                                            data[3].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[4].TicketRevenue +
                                              data[4].ConcessionRevenue +
                                              data[4].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[4].TicketRevenue +
                                              data[4].ConcessionRevenue +
                                              data[4].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[3].TicketRevenue +
                                        data[3].ConcessionRevenue +
                                        data[3].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[4].TicketRevenue +
                                          data[4].ConcessionRevenue +
                                          data[4].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[4].TicketRevenue +
                                          data[4].ConcessionRevenue +
                                          data[4].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[3].TicketRevenue +
                                        data[3].ConcessionRevenue +
                                        data[3].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[4].TicketRevenue +
                                          data[4].ConcessionRevenue +
                                          data[4].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[4].TicketRevenue +
                                          data[4].ConcessionRevenue +
                                          data[4].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 4 &&
                              Math.round(
                                data[5].TicketRevenue +
                                  data[5].ConcessionRevenue +
                                  data[5].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[4].TicketRevenue +
                                            data[4].ConcessionRevenue +
                                            data[4].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[5].TicketRevenue +
                                              data[5].ConcessionRevenue +
                                              data[5].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[5].TicketRevenue +
                                              data[5].ConcessionRevenue +
                                              data[5].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[4].TicketRevenue +
                                        data[4].ConcessionRevenue +
                                        data[4].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[5].TicketRevenue +
                                          data[5].ConcessionRevenue +
                                          data[5].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[5].TicketRevenue +
                                          data[5].ConcessionRevenue +
                                          data[5].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[4].TicketRevenue +
                                        data[4].ConcessionRevenue +
                                        data[4].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[5].TicketRevenue +
                                          data[5].ConcessionRevenue +
                                          data[5].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[5].TicketRevenue +
                                          data[5].ConcessionRevenue +
                                          data[5].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 5 &&
                              Math.round(
                                data[6].TicketRevenue +
                                  data[6].ConcessionRevenue +
                                  data[6].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[5].TicketRevenue +
                                            data[5].ConcessionRevenue +
                                            data[5].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[6].TicketRevenue +
                                              data[6].ConcessionRevenue +
                                              data[6].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[6].TicketRevenue +
                                              data[6].ConcessionRevenue +
                                              data[6].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[5].TicketRevenue +
                                        data[5].ConcessionRevenue +
                                        data[5].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[6].TicketRevenue +
                                          data[6].ConcessionRevenue +
                                          data[6].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[6].TicketRevenue +
                                          data[6].ConcessionRevenue +
                                          data[6].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[5].TicketRevenue +
                                        data[5].ConcessionRevenue +
                                        data[5].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[6].TicketRevenue +
                                          data[6].ConcessionRevenue +
                                          data[6].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[6].TicketRevenue +
                                          data[6].ConcessionRevenue +
                                          data[6].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 6 &&
                              Math.round(
                                data[7].TicketRevenue +
                                  data[7].ConcessionRevenue +
                                  data[7].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[6].TicketRevenue +
                                            data[6].ConcessionRevenue +
                                            data[6].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[7].TicketRevenue +
                                              data[7].ConcessionRevenue +
                                              data[7].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[7].TicketRevenue +
                                              data[7].ConcessionRevenue +
                                              data[7].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[6].TicketRevenue +
                                        data[6].ConcessionRevenue +
                                        data[6].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[7].TicketRevenue +
                                          data[7].ConcessionRevenue +
                                          data[7].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[7].TicketRevenue +
                                          data[7].ConcessionRevenue +
                                          data[7].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[6].TicketRevenue +
                                        data[6].ConcessionRevenue +
                                        data[6].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[7].TicketRevenue +
                                          data[7].ConcessionRevenue +
                                          data[7].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[7].TicketRevenue +
                                          data[7].ConcessionRevenue +
                                          data[7].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 7 &&
                              Math.round(
                                data[8].TicketRevenue +
                                  data[8].ConcessionRevenue +
                                  data[8].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[7].TicketRevenue +
                                            data[7].ConcessionRevenue +
                                            data[7].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[8].TicketRevenue +
                                              data[8].ConcessionRevenue +
                                              data[8].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[8].TicketRevenue +
                                              data[8].ConcessionRevenue +
                                              data[8].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[7].TicketRevenue +
                                        data[7].ConcessionRevenue +
                                        data[7].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[8].TicketRevenue +
                                          data[8].ConcessionRevenue +
                                          data[8].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[8].TicketRevenue +
                                          data[8].ConcessionRevenue +
                                          data[8].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[7].TicketRevenue +
                                        data[7].ConcessionRevenue +
                                        data[7].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[8].TicketRevenue +
                                          data[8].ConcessionRevenue +
                                          data[8].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[8].TicketRevenue +
                                          data[8].ConcessionRevenue +
                                          data[8].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 8 &&
                              Math.round(
                                data[9].TicketRevenue +
                                  data[9].ConcessionRevenue +
                                  data[9].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[8].TicketRevenue +
                                            data[8].ConcessionRevenue +
                                            data[8].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[9].TicketRevenue +
                                              data[9].ConcessionRevenue +
                                              data[9].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[9].TicketRevenue +
                                              data[9].ConcessionRevenue +
                                              data[9].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[8].TicketRevenue +
                                        data[8].ConcessionRevenue +
                                        data[8].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[9].TicketRevenue +
                                          data[9].ConcessionRevenue +
                                          data[9].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[9].TicketRevenue +
                                          data[9].ConcessionRevenue +
                                          data[9].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[8].TicketRevenue +
                                        data[8].ConcessionRevenue +
                                        data[8].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[9].TicketRevenue +
                                          data[9].ConcessionRevenue +
                                          data[9].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[9].TicketRevenue +
                                          data[9].ConcessionRevenue +
                                          data[9].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 9 &&
                              Math.round(
                                data[10].TicketRevenue +
                                  data[10].ConcessionRevenue +
                                  data[10].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[9].TicketRevenue +
                                            data[9].ConcessionRevenue +
                                            data[9].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[10].TicketRevenue +
                                              data[10].ConcessionRevenue +
                                              data[10].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[10].TicketRevenue +
                                              data[10].ConcessionRevenue +
                                              data[10].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[9].TicketRevenue +
                                        data[9].ConcessionRevenue +
                                        data[9].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[10].TicketRevenue +
                                          data[10].ConcessionRevenue +
                                          data[10].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[10].TicketRevenue +
                                          data[10].ConcessionRevenue +
                                          data[10].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[9].TicketRevenue +
                                        data[9].ConcessionRevenue +
                                        data[9].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[10].TicketRevenue +
                                          data[10].ConcessionRevenue +
                                          data[10].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[10].TicketRevenue +
                                          data[10].ConcessionRevenue +
                                          data[10].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 10 &&
                              Math.round(
                                data[11].TicketRevenue +
                                  data[11].ConcessionRevenue +
                                  data[11].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[10].TicketRevenue +
                                            data[10].ConcessionRevenue +
                                            data[10].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[11].TicketRevenue +
                                              data[11].ConcessionRevenue +
                                              data[11].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[11].TicketRevenue +
                                              data[11].ConcessionRevenue +
                                              data[11].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[10].TicketRevenue +
                                        data[10].ConcessionRevenue +
                                        data[10].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[11].TicketRevenue +
                                          data[11].ConcessionRevenue +
                                          data[11].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[11].TicketRevenue +
                                          data[11].ConcessionRevenue +
                                          data[11].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[10].TicketRevenue +
                                        data[10].ConcessionRevenue +
                                        data[10].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[11].TicketRevenue +
                                          data[11].ConcessionRevenue +
                                          data[11].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[11].TicketRevenue +
                                          data[11].ConcessionRevenue +
                                          data[11].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 11 &&
                              data[12] &&
                              Math.round(
                                data[12].TicketRevenue +
                                  data[12].ConcessionRevenue +
                                  data[12].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[11].TicketRevenue +
                                            data[11].ConcessionRevenue +
                                            data[11].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[12].TicketRevenue +
                                              data[12].ConcessionRevenue +
                                              data[12].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[12].TicketRevenue +
                                              data[12].ConcessionRevenue +
                                              data[12].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[11].TicketRevenue +
                                        data[11].ConcessionRevenue +
                                        data[11].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[12].TicketRevenue +
                                          data[12].ConcessionRevenue +
                                          data[12].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[12].TicketRevenue +
                                          data[12].ConcessionRevenue +
                                          data[12].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[11].TicketRevenue +
                                        data[11].ConcessionRevenue +
                                        data[11].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[12].TicketRevenue +
                                          data[12].ConcessionRevenue +
                                          data[12].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[12].TicketRevenue +
                                          data[12].ConcessionRevenue +
                                          data[12].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 12 &&
                              data[13] &&
                              Math.round(
                                data[13].TicketRevenue +
                                  data[13].ConcessionRevenue +
                                  data[13].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[12].TicketRevenue +
                                            data[12].ConcessionRevenue +
                                            data[12].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[13].TicketRevenue +
                                              data[13].ConcessionRevenue +
                                              data[13].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[13].TicketRevenue +
                                              data[13].ConcessionRevenue +
                                              data[13].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[12].TicketRevenue +
                                        data[12].ConcessionRevenue +
                                        data[12].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[13].TicketRevenue +
                                          data[13].ConcessionRevenue +
                                          data[13].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[13].TicketRevenue +
                                          data[13].ConcessionRevenue +
                                          data[13].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[12].TicketRevenue +
                                        data[12].ConcessionRevenue +
                                        data[12].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[13].TicketRevenue +
                                          data[13].ConcessionRevenue +
                                          data[13].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[13].TicketRevenue +
                                          data[13].ConcessionRevenue +
                                          data[13].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 13 &&
                              data[14] &&
                              Math.round(
                                data[14].TicketRevenue +
                                  data[14].ConcessionRevenue +
                                  data[14].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[13].TicketRevenue +
                                            data[13].ConcessionRevenue +
                                            data[13].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[14].TicketRevenue +
                                              data[14].ConcessionRevenue +
                                              data[14].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[14].TicketRevenue +
                                              data[14].ConcessionRevenue +
                                              data[14].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[13].TicketRevenue +
                                        data[13].ConcessionRevenue +
                                        data[13].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[14].TicketRevenue +
                                          data[14].ConcessionRevenue +
                                          data[14].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[14].TicketRevenue +
                                          data[14].ConcessionRevenue +
                                          data[14].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[13].TicketRevenue +
                                        data[13].ConcessionRevenue +
                                        data[13].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[14].TicketRevenue +
                                          data[14].ConcessionRevenue +
                                          data[14].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[14].TicketRevenue +
                                          data[14].ConcessionRevenue +
                                          data[14].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 14 &&
                              data[15] &&
                              Math.round(
                                data[15].TicketRevenue +
                                  data[15].ConcessionRevenue +
                                  data[15].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[14].TicketRevenue +
                                            data[14].ConcessionRevenue +
                                            data[14].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[15].TicketRevenue +
                                              data[15].ConcessionRevenue +
                                              data[15].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[15].TicketRevenue +
                                              data[15].ConcessionRevenue +
                                              data[15].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[14].TicketRevenue +
                                        data[14].ConcessionRevenue +
                                        data[14].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[15].TicketRevenue +
                                          data[15].ConcessionRevenue +
                                          data[15].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[15].TicketRevenue +
                                          data[15].ConcessionRevenue +
                                          data[15].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[14].TicketRevenue +
                                        data[14].ConcessionRevenue +
                                        data[14].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[15].TicketRevenue +
                                          data[15].ConcessionRevenue +
                                          data[15].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[15].TicketRevenue +
                                          data[15].ConcessionRevenue +
                                          data[15].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 15 &&
                              data[16] &&
                              Math.round(
                                data[16].TicketRevenue +
                                  data[16].ConcessionRevenue +
                                  data[16].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[15].TicketRevenue +
                                            data[15].ConcessionRevenue +
                                            data[15].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[16].TicketRevenue +
                                              data[16].ConcessionRevenue +
                                              data[16].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[16].TicketRevenue +
                                              data[16].ConcessionRevenue +
                                              data[16].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[15].TicketRevenue +
                                        data[15].ConcessionRevenue +
                                        data[15].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[16].TicketRevenue +
                                          data[16].ConcessionRevenue +
                                          data[16].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[16].TicketRevenue +
                                          data[16].ConcessionRevenue +
                                          data[16].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[15].TicketRevenue +
                                        data[15].ConcessionRevenue +
                                        data[15].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[16].TicketRevenue +
                                          data[16].ConcessionRevenue +
                                          data[16].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[16].TicketRevenue +
                                          data[16].ConcessionRevenue +
                                          data[16].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 16 &&
                              data[17] &&
                              Math.round(
                                data[17].TicketRevenue +
                                  data[17].ConcessionRevenue +
                                  data[17].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[16].TicketRevenue +
                                            data[16].ConcessionRevenue +
                                            data[16].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[17].TicketRevenue +
                                              data[17].ConcessionRevenue +
                                              data[17].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[17].TicketRevenue +
                                              data[17].ConcessionRevenue +
                                              data[17].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[16].TicketRevenue +
                                        data[16].ConcessionRevenue +
                                        data[16].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[17].TicketRevenue +
                                          data[17].ConcessionRevenue +
                                          data[17].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[17].TicketRevenue +
                                          data[17].ConcessionRevenue +
                                          data[17].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[16].TicketRevenue +
                                        data[16].ConcessionRevenue +
                                        data[16].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[17].TicketRevenue +
                                          data[17].ConcessionRevenue +
                                          data[17].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[17].TicketRevenue +
                                          data[17].ConcessionRevenue +
                                          data[17].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 17 &&
                              data[18] &&
                              Math.round(
                                data[18].TicketRevenue +
                                  data[18].ConcessionRevenue +
                                  data[18].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[17].TicketRevenue +
                                            data[17].ConcessionRevenue +
                                            data[17].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[18].TicketRevenue +
                                              data[18].ConcessionRevenue +
                                              data[18].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[18].TicketRevenue +
                                              data[18].ConcessionRevenue +
                                              data[18].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[17].TicketRevenue +
                                        data[17].ConcessionRevenue +
                                        data[17].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[18].TicketRevenue +
                                          data[18].ConcessionRevenue +
                                          data[18].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[18].TicketRevenue +
                                          data[18].ConcessionRevenue +
                                          data[18].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[17].TicketRevenue +
                                        data[17].ConcessionRevenue +
                                        data[17].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[18].TicketRevenue +
                                          data[18].ConcessionRevenue +
                                          data[18].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[18].TicketRevenue +
                                          data[18].ConcessionRevenue +
                                          data[18].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 18 &&
                              data[19] &&
                              Math.round(
                                data[19].TicketRevenue +
                                  data[19].ConcessionRevenue +
                                  data[19].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[18].TicketRevenue +
                                            data[18].ConcessionRevenue +
                                            data[18].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[19].TicketRevenue +
                                              data[19].ConcessionRevenue +
                                              data[19].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[19].TicketRevenue +
                                              data[19].ConcessionRevenue +
                                              data[19].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[18].TicketRevenue +
                                        data[18].ConcessionRevenue +
                                        data[18].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[19].TicketRevenue +
                                          data[19].ConcessionRevenue +
                                          data[19].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[19].TicketRevenue +
                                          data[19].ConcessionRevenue +
                                          data[19].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[18].TicketRevenue +
                                        data[18].ConcessionRevenue +
                                        data[18].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[19].TicketRevenue +
                                          data[19].ConcessionRevenue +
                                          data[19].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[19].TicketRevenue +
                                          data[19].ConcessionRevenue +
                                          data[19].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 19 &&
                              data[20] &&
                              Math.round(
                                data[20].TicketRevenue +
                                  data[20].ConcessionRevenue +
                                  data[20].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[19].TicketRevenue +
                                            data[19].ConcessionRevenue +
                                            data[19].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[20].TicketRevenue +
                                              data[20].ConcessionRevenue +
                                              data[20].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[20].TicketRevenue +
                                              data[20].ConcessionRevenue +
                                              data[20].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[19].TicketRevenue +
                                        data[19].ConcessionRevenue +
                                        data[19].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[20].TicketRevenue +
                                          data[20].ConcessionRevenue +
                                          data[20].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[20].TicketRevenue +
                                          data[20].ConcessionRevenue +
                                          data[20].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[19].TicketRevenue +
                                        data[19].ConcessionRevenue +
                                        data[19].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[20].TicketRevenue +
                                          data[20].ConcessionRevenue +
                                          data[20].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[20].TicketRevenue +
                                          data[20].ConcessionRevenue +
                                          data[20].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 20 &&
                              data[21] &&
                              Math.round(
                                data[21].TicketRevenue +
                                  data[21].ConcessionRevenue +
                                  data[21].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[20].TicketRevenue +
                                            data[20].ConcessionRevenue +
                                            data[20].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[21].TicketRevenue +
                                              data[21].ConcessionRevenue +
                                              data[21].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[21].TicketRevenue +
                                              data[21].ConcessionRevenue +
                                              data[21].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[20].TicketRevenue +
                                        data[20].ConcessionRevenue +
                                        data[20].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[21].TicketRevenue +
                                          data[21].ConcessionRevenue +
                                          data[21].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[21].TicketRevenue +
                                          data[21].ConcessionRevenue +
                                          data[21].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[20].TicketRevenue +
                                        data[20].ConcessionRevenue +
                                        data[20].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[21].TicketRevenue +
                                          data[21].ConcessionRevenue +
                                          data[21].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[21].TicketRevenue +
                                          data[21].ConcessionRevenue +
                                          data[21].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 21 &&
                              data[22] &&
                              Math.round(
                                data[22].TicketRevenue +
                                  data[22].ConcessionRevenue +
                                  data[22].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[21].TicketRevenue +
                                            data[21].ConcessionRevenue +
                                            data[21].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[22].TicketRevenue +
                                              data[22].ConcessionRevenue +
                                              data[22].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[22].TicketRevenue +
                                              data[22].ConcessionRevenue +
                                              data[22].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[21].TicketRevenue +
                                        data[21].ConcessionRevenue +
                                        data[21].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[22].TicketRevenue +
                                          data[22].ConcessionRevenue +
                                          data[22].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[22].TicketRevenue +
                                          data[22].ConcessionRevenue +
                                          data[22].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[21].TicketRevenue +
                                        data[21].ConcessionRevenue +
                                        data[21].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[22].TicketRevenue +
                                          data[22].ConcessionRevenue +
                                          data[22].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[22].TicketRevenue +
                                          data[22].ConcessionRevenue +
                                          data[22].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 22 &&
                              data[23] &&
                              Math.round(
                                data[23].TicketRevenue +
                                  data[23].ConcessionRevenue +
                                  data[23].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[22].TicketRevenue +
                                            data[22].ConcessionRevenue +
                                            data[22].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[23].TicketRevenue +
                                              data[23].ConcessionRevenue +
                                              data[23].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[23].TicketRevenue +
                                              data[23].ConcessionRevenue +
                                              data[23].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[22].TicketRevenue +
                                        data[22].ConcessionRevenue +
                                        data[22].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[23].TicketRevenue +
                                          data[23].ConcessionRevenue +
                                          data[23].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[23].TicketRevenue +
                                          data[23].ConcessionRevenue +
                                          data[23].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[22].TicketRevenue +
                                        data[22].ConcessionRevenue +
                                        data[22].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[23].TicketRevenue +
                                          data[23].ConcessionRevenue +
                                          data[23].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[23].TicketRevenue +
                                          data[23].ConcessionRevenue +
                                          data[23].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 23 &&
                              data[24] &&
                              Math.round(
                                data[24].TicketRevenue +
                                  data[24].ConcessionRevenue +
                                  data[24].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[23].TicketRevenue +
                                            data[23].ConcessionRevenue +
                                            data[23].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[24].TicketRevenue +
                                              data[24].ConcessionRevenue +
                                              data[24].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[24].TicketRevenue +
                                              data[24].ConcessionRevenue +
                                              data[24].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[23].TicketRevenue +
                                        data[23].ConcessionRevenue +
                                        data[23].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[24].TicketRevenue +
                                          data[24].ConcessionRevenue +
                                          data[24].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[24].TicketRevenue +
                                          data[24].ConcessionRevenue +
                                          data[24].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[23].TicketRevenue +
                                        data[23].ConcessionRevenue +
                                        data[23].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[24].TicketRevenue +
                                          data[24].ConcessionRevenue +
                                          data[24].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[24].TicketRevenue +
                                          data[24].ConcessionRevenue +
                                          data[24].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}

                            {index === 24 &&
                              data[25] &&
                              Math.round(
                                data[25].TicketRevenue +
                                  data[25].ConcessionRevenue +
                                  data[25].OtherRevenue
                              ) > 0 && (
                                <span
                                  className="progressProcent"
                                  style={{
                                    backgroundColor:
                                      Math.round(
                                        ((Math.round(
                                          data[24].TicketRevenue +
                                            data[24].ConcessionRevenue +
                                            data[24].OtherRevenue
                                        ) -
                                          Math.round(
                                            data[25].TicketRevenue +
                                              data[25].ConcessionRevenue +
                                              data[25].OtherRevenue
                                          )) *
                                          100) /
                                          Math.round(
                                            data[25].TicketRevenue +
                                              data[25].ConcessionRevenue +
                                              data[25].OtherRevenue
                                          )
                                      ) > 0
                                        ? "#40b22d"
                                        : "#cf4747",
                                  }}
                                >
                                  {Math.round(
                                    ((Math.round(
                                      data[24].TicketRevenue +
                                        data[24].ConcessionRevenue +
                                        data[24].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[25].TicketRevenue +
                                          data[25].ConcessionRevenue +
                                          data[25].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[25].TicketRevenue +
                                          data[25].ConcessionRevenue +
                                          data[25].OtherRevenue
                                      )
                                  ) > 0
                                    ? "+"
                                    : ""}
                                  {Math.round(
                                    ((Math.round(
                                      data[24].TicketRevenue +
                                        data[24].ConcessionRevenue +
                                        data[24].OtherRevenue
                                    ) -
                                      Math.round(
                                        data[25].TicketRevenue +
                                          data[25].ConcessionRevenue +
                                          data[25].OtherRevenue
                                      )) *
                                      100) /
                                      Math.round(
                                        data[25].TicketRevenue +
                                          data[25].ConcessionRevenue +
                                          data[25].OtherRevenue
                                      )
                                  ) + "%"}
                                </span>
                              )}
                          </>
                        ) : (
                          radioCheched === "0" && (
                            <>
                              {index === 0 && (
                                <span className="thisYear">I år</span>
                              )}

                              {index === 1 &&
                                Math.round(
                                  data[1].TicketRevenue +
                                    data[1].ConcessionRevenue +
                                    data[1].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[1].TicketRevenue +
                                              data[1].ConcessionRevenue +
                                              data[1].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[1].TicketRevenue +
                                          data[1].ConcessionRevenue +
                                          data[1].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[1].TicketRevenue +
                                          data[1].ConcessionRevenue +
                                          data[1].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 2 &&
                                Math.round(
                                  data[2].TicketRevenue +
                                    data[2].ConcessionRevenue +
                                    data[2].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[2].TicketRevenue +
                                              data[2].ConcessionRevenue +
                                              data[2].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[2].TicketRevenue +
                                          data[2].ConcessionRevenue +
                                          data[2].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[2].TicketRevenue +
                                          data[2].ConcessionRevenue +
                                          data[2].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 3 &&
                                Math.round(
                                  data[3].TicketRevenue +
                                    data[3].ConcessionRevenue +
                                    data[3].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[3].TicketRevenue +
                                              data[3].ConcessionRevenue +
                                              data[3].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[3].TicketRevenue +
                                          data[3].ConcessionRevenue +
                                          data[3].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[3].TicketRevenue +
                                          data[3].ConcessionRevenue +
                                          data[3].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 4 &&
                                Math.round(
                                  data[4].TicketRevenue +
                                    data[4].ConcessionRevenue +
                                    data[4].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[4].TicketRevenue +
                                              data[4].ConcessionRevenue +
                                              data[4].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[4].TicketRevenue +
                                          data[4].ConcessionRevenue +
                                          data[4].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[4].TicketRevenue +
                                          data[4].ConcessionRevenue +
                                          data[4].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 5 &&
                                Math.round(
                                  data[5].TicketRevenue +
                                    data[5].ConcessionRevenue +
                                    data[5].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[5].TicketRevenue +
                                              data[5].ConcessionRevenue +
                                              data[5].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[5].TicketRevenue +
                                          data[5].ConcessionRevenue +
                                          data[5].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[5].TicketRevenue +
                                          data[5].ConcessionRevenue +
                                          data[5].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 6 &&
                                Math.round(
                                  data[6].TicketRevenue +
                                    data[6].ConcessionRevenue +
                                    data[6].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[6].TicketRevenue +
                                              data[6].ConcessionRevenue +
                                              data[6].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[6].TicketRevenue +
                                          data[6].ConcessionRevenue +
                                          data[6].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[6].TicketRevenue +
                                          data[6].ConcessionRevenue +
                                          data[6].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 7 &&
                                Math.round(
                                  data[7].TicketRevenue +
                                    data[7].ConcessionRevenue +
                                    data[7].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[7].TicketRevenue +
                                              data[7].ConcessionRevenue +
                                              data[7].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[7].TicketRevenue +
                                          data[7].ConcessionRevenue +
                                          data[7].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[7].TicketRevenue +
                                          data[7].ConcessionRevenue +
                                          data[7].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 8 &&
                                Math.round(
                                  data[8].TicketRevenue +
                                    data[8].ConcessionRevenue +
                                    data[8].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[8].TicketRevenue +
                                              data[8].ConcessionRevenue +
                                              data[8].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[8].TicketRevenue +
                                          data[8].ConcessionRevenue +
                                          data[8].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[8].TicketRevenue +
                                          data[8].ConcessionRevenue +
                                          data[8].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 9 &&
                                Math.round(
                                  data[9].TicketRevenue +
                                    data[9].ConcessionRevenue +
                                    data[9].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[9].TicketRevenue +
                                              data[9].ConcessionRevenue +
                                              data[9].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[9].TicketRevenue +
                                          data[9].ConcessionRevenue +
                                          data[9].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[9].TicketRevenue +
                                          data[9].ConcessionRevenue +
                                          data[9].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 10 &&
                                Math.round(
                                  data[10].TicketRevenue +
                                    data[10].ConcessionRevenue +
                                    data[10].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[10].TicketRevenue +
                                              data[10].ConcessionRevenue +
                                              data[10].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[10].TicketRevenue +
                                          data[10].ConcessionRevenue +
                                          data[10].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[10].TicketRevenue +
                                          data[10].ConcessionRevenue +
                                          data[10].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 11 &&
                                Math.round(
                                  data[11].TicketRevenue +
                                    data[11].ConcessionRevenue +
                                    data[11].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[11].TicketRevenue +
                                              data[11].ConcessionRevenue +
                                              data[11].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[11].TicketRevenue +
                                          data[11].ConcessionRevenue +
                                          data[11].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[11].TicketRevenue +
                                          data[11].ConcessionRevenue +
                                          data[11].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 12 &&
                                Math.round(
                                  data[12].TicketRevenue +
                                    data[12].ConcessionRevenue +
                                    data[12].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[12].TicketRevenue +
                                              data[12].ConcessionRevenue +
                                              data[12].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[12].TicketRevenue +
                                          data[12].ConcessionRevenue +
                                          data[12].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[12].TicketRevenue +
                                          data[12].ConcessionRevenue +
                                          data[12].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 13 &&
                                Math.round(
                                  data[13].TicketRevenue +
                                    data[13].ConcessionRevenue +
                                    data[13].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[13].TicketRevenue +
                                              data[13].ConcessionRevenue +
                                              data[13].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[13].TicketRevenue +
                                          data[13].ConcessionRevenue +
                                          data[13].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[13].TicketRevenue +
                                          data[13].ConcessionRevenue +
                                          data[13].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 14 &&
                                Math.round(
                                  data[14].TicketRevenue +
                                    data[14].ConcessionRevenue +
                                    data[14].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[14].TicketRevenue +
                                              data[14].ConcessionRevenue +
                                              data[14].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[14].TicketRevenue +
                                          data[14].ConcessionRevenue +
                                          data[14].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[14].TicketRevenue +
                                          data[14].ConcessionRevenue +
                                          data[14].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 15 &&
                                Math.round(
                                  data[15].TicketRevenue +
                                    data[15].ConcessionRevenue +
                                    data[15].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[15].TicketRevenue +
                                              data[15].ConcessionRevenue +
                                              data[15].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[15].TicketRevenue +
                                          data[15].ConcessionRevenue +
                                          data[15].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[15].TicketRevenue +
                                          data[15].ConcessionRevenue +
                                          data[15].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 16 &&
                                Math.round(
                                  data[16].TicketRevenue +
                                    data[16].ConcessionRevenue +
                                    data[16].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[16].TicketRevenue +
                                              data[16].ConcessionRevenue +
                                              data[16].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[16].TicketRevenue +
                                          data[16].ConcessionRevenue +
                                          data[16].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[16].TicketRevenue +
                                          data[16].ConcessionRevenue +
                                          data[16].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 17 &&
                                Math.round(
                                  data[17].TicketRevenue +
                                    data[17].ConcessionRevenue +
                                    data[17].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[17].TicketRevenue +
                                              data[17].ConcessionRevenue +
                                              data[17].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[17].TicketRevenue +
                                          data[17].ConcessionRevenue +
                                          data[17].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[17].TicketRevenue +
                                          data[17].ConcessionRevenue +
                                          data[17].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 18 &&
                                Math.round(
                                  data[18].TicketRevenue +
                                    data[18].ConcessionRevenue +
                                    data[18].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[18].TicketRevenue +
                                              data[18].ConcessionRevenue +
                                              data[18].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[18].TicketRevenue +
                                          data[18].ConcessionRevenue +
                                          data[18].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[18].TicketRevenue +
                                          data[18].ConcessionRevenue +
                                          data[18].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 19 &&
                                Math.round(
                                  data[19].TicketRevenue +
                                    data[19].ConcessionRevenue +
                                    data[19].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[19].TicketRevenue +
                                              data[19].ConcessionRevenue +
                                              data[19].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[19].TicketRevenue +
                                          data[19].ConcessionRevenue +
                                          data[19].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[19].TicketRevenue +
                                          data[19].ConcessionRevenue +
                                          data[19].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 20 &&
                                Math.round(
                                  data[20].TicketRevenue +
                                    data[20].ConcessionRevenue +
                                    data[20].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[20].TicketRevenue +
                                              data[20].ConcessionRevenue +
                                              data[20].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[20].TicketRevenue +
                                          data[20].ConcessionRevenue +
                                          data[20].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[20].TicketRevenue +
                                          data[20].ConcessionRevenue +
                                          data[20].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 21 &&
                                Math.round(
                                  data[21].TicketRevenue +
                                    data[21].ConcessionRevenue +
                                    data[21].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[21].TicketRevenue +
                                              data[21].ConcessionRevenue +
                                              data[21].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[21].TicketRevenue +
                                          data[21].ConcessionRevenue +
                                          data[21].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[21].TicketRevenue +
                                          data[21].ConcessionRevenue +
                                          data[21].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 22 &&
                                Math.round(
                                  data[22].TicketRevenue +
                                    data[22].ConcessionRevenue +
                                    data[22].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[22].TicketRevenue +
                                              data[22].ConcessionRevenue +
                                              data[22].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[22].TicketRevenue +
                                          data[22].ConcessionRevenue +
                                          data[22].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[22].TicketRevenue +
                                          data[22].ConcessionRevenue +
                                          data[22].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 23 &&
                                Math.round(
                                  data[23].TicketRevenue +
                                    data[23].ConcessionRevenue +
                                    data[23].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[23].TicketRevenue +
                                              data[23].ConcessionRevenue +
                                              data[23].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[23].TicketRevenue +
                                          data[23].ConcessionRevenue +
                                          data[23].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[23].TicketRevenue +
                                          data[23].ConcessionRevenue +
                                          data[23].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 24 &&
                                Math.round(
                                  data[24].TicketRevenue +
                                    data[24].ConcessionRevenue +
                                    data[24].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[24].TicketRevenue +
                                              data[24].ConcessionRevenue +
                                              data[24].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[24].TicketRevenue +
                                          data[24].ConcessionRevenue +
                                          data[24].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[24].TicketRevenue +
                                          data[24].ConcessionRevenue +
                                          data[24].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}

                              {index === 25 &&
                                Math.round(
                                  data[25].TicketRevenue +
                                    data[25].ConcessionRevenue +
                                    data[25].OtherRevenue
                                ) > 0 && (
                                  <span
                                    className="thisYear"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        Math.round(
                                          ((Math.round(
                                            data[25].TicketRevenue +
                                              data[25].ConcessionRevenue +
                                              data[25].OtherRevenue
                                          ) -
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )) *
                                            100) /
                                            Math.round(
                                              data[0].TicketRevenue +
                                                data[0].ConcessionRevenue +
                                                data[0].OtherRevenue
                                            )
                                        ) > 0
                                          ? "#40b22d"
                                          : "#cf4747",
                                    }}
                                  >
                                    {Math.round(
                                      ((Math.round(
                                        data[25].TicketRevenue +
                                          data[25].ConcessionRevenue +
                                          data[25].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) > 0
                                      ? "+"
                                      : ""}
                                    {Math.round(
                                      ((Math.round(
                                        data[25].TicketRevenue +
                                          data[25].ConcessionRevenue +
                                          data[25].OtherRevenue
                                      ) -
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )) *
                                        100) /
                                        Math.round(
                                          data[0].TicketRevenue +
                                            data[0].ConcessionRevenue +
                                            data[0].OtherRevenue
                                        )
                                    ) + "%"}
                                  </span>
                                )}
                            </>
                          )
                        )}
                      </div>

                      <p
                        className="progressValue"
                        style={{
                          paddingLeft:
                            Math.round(
                              item.ConcessionRevenue +
                                item.TicketRevenue +
                                item.OtherRevenue
                            ) > 0
                              ? "10px"
                              : "",
                          marginLeft:
                            Math.round(
                              item.ConcessionRevenue +
                                item.TicketRevenue +
                                item.OtherRevenue
                            ) > 0
                              ? "0px"
                              : "-45px",

                          color: "#2a8102",
                        }}
                      >
                        {Math.round(
                          item.ConcessionRevenue +
                            item.TicketRevenue +
                            item.OtherRevenue
                        ).toLocaleString("da")}
                      </p>
                      {/* ):''} */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          ) : ' ' }
           {/* samlet omsætning slutter her */}
        </section>
      </main>
    </>
  );
}

export default PeriodeSam;
