import React, { useEffect, useState, useContext } from "react";
import Header from "../components/header/Header";
import "../components/css/messages.css";
import { keyContext } from "../components/KeyContext";
// import { Link } from "react-router-dom";

// import FooterMenu from "../components/FooterMenu/FooterMenu";
// import downArrow from "../images/arrow-down.svg";
// import { useNavigate } from "react-router-dom";
// import Incidents from "../components/Incidents/Incidents";
// import moment from "moment/moment";
import DriftSms from "./DriftSms";
import AdminIncidents from "./AdminIncidents";
// import format from "date-fns/format";

export default function Messages() {
  const { open, isChecked } = useContext(keyContext);

  const [rights, setRights] = useState([{}]);

  const key = sessionStorage.getItem("key");
  let orgID = sessionStorage.getItem("orgID");
  
  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getaccessrights?Key=${key}&LoginNo=${orgID}`;
    if (key && orgID) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          // if(result.result === 0) {
          // navigate('/')
          // }
          setRights(result);
        });
    }
  }, [key, orgID]);

  const [pageKey, setPageKey] = React.useState("a");

  let page;
  switch (pageKey) {
    case "a":
      page = <DriftSms />;
      break;

    case "b":
      page = <AdminIncidents />;
      break;

    default:
      page =  <DriftSms />;
  }

  const DriftMenu = (props) => {
    return (
      <button
        style={{
          border: "none",
          marginBottom: 12 + "px",
          textAlign: "left",
          background: "transparent",
          color: pageKey === "a" ? "#982f2f" : "#101010",
          letterSpacing: '0.16px'
        }}
        onClick={() => {
          props.onClick("a");
        }}
      >
        Tilmeld/afmeld Drift SMS
      </button>
    );
  };

  const IncidentsMenu = (props) => {
    return (
      <button
        style={{
          border: "none",
          marginBottom: 6 + "px",
          textAlign: "left",
          background: "transparent",
          color: pageKey === "b" ? "#982f2f" : "#101010",
          // fontWeight : 500,
          // lineHeight:1.25 ,
          letterSpacing: '0.16px'
        }}
        onClick={() => {
          props.onClick("b");
        }}
      >
        Hændelser
      </button>
    );
  };

  return (
    <main className="containers ">
      <Header smsActive="active" />

      <div
        className="sms-container"
        style={{ backgroundColor: open ? "rgba(0,0,0,0.5" : "", display: isChecked === true ? 'block' : '' }}
      >
        <aside className="aside" style={{display: isChecked === true ? 'none' : ''}}>
         
          <div className="aside-men">
            <DriftMenu onClick={setPageKey} />
          </div>
          <div className="aside-men">
            {rights &&
              rights.map((right, index) => {
                if (right.name === "ACC_EDITINCIDENT")
                  return (
                    <div className="meu" key={index}>
                      <IncidentsMenu onClick={setPageKey} />
                    </div>
                  );
              })}
          </div>
        </aside>
        <div style={{ width: 100 + "%", padding: 10 + "px"}}>{page}</div>
      </div>
    </main>
  );
}
