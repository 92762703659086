import React, { useContext, useState, useEffect } from "react";

import "../components/logind.css";
import { keyContext } from "../components/KeyContext";

import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";

import logo from '../images/ebillet_logo 1.png'

function Login(props) {
  const { setUserName, setPassword, errorMessage } = useContext(keyContext);
  //  console.log(errorMessage)
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [kode, setKode] = useState("");
  

  

  function submitData(e) {
    e.preventDefault();

    // console.log(kode);
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_login?User=${user}&PW=${encodeURIComponent(kode)}`;
    fetch(endpoint, {
      method: "GET",

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.result === 0) {
          setErrors(result.Message);
        }
        else {
          setUserName(e.target.text.value);
          setPassword(e.target.password.value);

          navigate("/admin");
        sessionStorage.setItem('orgID',( result && result.data[0].No))
        sessionStorage.setItem("key", result.key);
        // sessionStorage.setItem('orgID',( result && result.data[0].No))
                
        // sessionStorage.setItem("key", result.key);
        sessionStorage.setItem("user", user)
        sessionStorage.setItem("password", kode)

        }
                
                // sessionStorage.setItem("user", userName)
      });

   
  }

  useEffect(() => {
    document.title = "Log ind - ebillet_admin";
  }, []);

  return (
    <main className="containers">
      {/* <Header /> */}
      <header className="header-menu">
      <img src={logo} alt="" />

      </header>
    <section className="login-page">
      <aside className="aside"></aside>

    
    <div className="login">
      <h1 className="login-title">Log ind</h1>
      <br />
      <p className="login-desc">Indtast brugernavn og adgangskode</p>
      <br />

      <Form onSubmit={(e) => submitData(e)}>
        <Form.Group className="mb-3" controlId="user">
          <Form.Label style={{fontWeight:600, letterSpacing:'0.32px'}}>Brugernavn</Form.Label>
          <Form.Control
            type="text"
            name="text" style={{border:'1px solid #101010'}}
            //value={form.user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
            // isInvalid={!!errors.user }
          />
        </Form.Group>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: "block", fontSize: "1em" }}
        >
          {/* {errors.user}  */}
        </Form.Control.Feedback>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label  style={{fontWeight:600, letterSpacing:'0.32px',marginTop:5}}>Adgangskode</Form.Label>
          <Form.Control
            type="password"
            name="password" style={{border:'1px solid #101010'}}
            onChange={(e) => {
              setKode(e.target.value);
            }}
            // isInvalid={!!errors.password }
          />

          <Form.Control.Feedback
            type="invalid"
            style={{ display: "block", fontSize: "1em" }}
          >
            {/* {errors.password}  */}
          </Form.Control.Feedback>
        </Form.Group>

        <p className="error" style={{marginBottom : errors ?    -10 : '' }}>
          {errors ? " Forkert brugernavn eller adgangskode." : ""}
        </p>
       <br/>
        <button className=" text-light button" type="submit">
          LOG IND
        </button>
      </Form>
    </div>
    </section>
    </main>
  );
}

export default Login;
