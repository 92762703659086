import React from "react";
import { useContext, useState } from "react";
import { keyContext } from "./KeyContext";
import moment from "moment";
import ScrollToTop from "react-scroll-to-top";


function DayTable() {
  const { dagsData } = useContext(keyContext);

  let arr = [];

  dagsData && dagsData.filter((i) => arr.push(i.ArrTime.slice(0, 10)));

  //find duplicates
  function toFindDuplicates(arr) {
    const uniqueElements = new Set(arr);
    
    arr.filter((item) => {
      if (uniqueElements.has(item)) {
        
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }

  const duplicateElements = toFindDuplicates(arr);

  var days = [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
  ];

  let forsCount = 0;
  let Reserveret = 0;
  let solgte = 0;
  let total = 0;
  let totalSeat = 0;
  let oms = 0;
 

  function FindSumRes(filmname, startTime, endTime) {
    let res = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        res = res + item.TotalResCount;
      }
    });
    return res;
  }
  function FindSumSold(filmname, startTime, endTime) {
    let sold = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        sold = sold + item.TotalSoldCount;
      }
    });
    return sold;
  }

  function FindSumOms(filmname, startTime, endTime) {
    let oms = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        oms = oms + item.TotalSoldAmount;
      }
    });
    return oms;
  }
  function FindSumTotal(filmname, startTime, endTime) {
    let total = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        total = total + item.TotalResCount + item.TotalSoldCount;
      }
    });
    return total;
  }

  function totalSeatCount(filmname, startTime, endTime) {
    let total = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        total = total + item.TotalSeatCount;
      }
    });
    return total;
  }

  function findBel(filmname, startTime, endTime) {
    let tot = 0;
    let res = 0;
    let sold = 0;
    let seat = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        res = res + item.TotalResCount;
      }
    });
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        sold = sold + item.TotalSoldCount;
      }
    });
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        seat = seat + item.TotalSeatCount;
      }
    });
    tot = Math.round(((res + sold) * 100) / seat);
    return tot;

    
  }

  //formiddag forestillinger
  let startTim = moment("9:00", "hh:mm");
  let endTim = moment("11:59", "hh:mm");
  let fr = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(startTim, endTim)) {
        fr.push(i);
      }
    });
 
  let farr = [];
  fr && fr.filter((i) => farr.push(i.HomeTeamName));

  function FilmDuplicates(farr) {
    const uniqueElements = new Set(farr);

    farr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicatefar = FilmDuplicates(farr);

  let formiddag = [];
  for (let index = 0; index < duplicatefar.length; index++) {
    formiddag.push({
      id: index,
      name: duplicatefar[index],
      sumRes: FindSumRes(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      belag: findBel(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
    });
  }

  // 1 eftermiddag forestillinger
  let starTime = moment("11:59", "hh:mm");
  let endime = moment("14:59", "hh:mm");
  let er = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(starTime, endime)) {
        er.push(i);
      }
    });
 
  let err = [];
  er && er.filter((i) => err.push(i.HomeTeamName));

  function FilmDuplicates(err) {
    const uniqueElements = new Set(err);

    err.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateerr = FilmDuplicates(err);

  let firstEftermiddag = [];
  for (let index = 0; index < duplicateerr.length; index++) {
    firstEftermiddag.push({
      id: index,
      name: duplicateerr[index],
      sumRes: FindSumRes(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      belag: findBel(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
    });
  }

  // 2 eftermiddag forestillinger
  let startTime = moment("14:59", "hh:mm");
  let endTime = moment("17:59", "hh:mm");
  let ar = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(startTime, endTime)) {
        ar.push(i);
      }
    });

  let earr = [];
  ar && ar.filter((i) => earr.push(i.HomeTeamName));

  function FilmDuplicates(earr) {
    const uniqueElements = new Set(earr);

    earr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateear = FilmDuplicates(earr);
  // below are the 5 functions which sums reservation, belæg, oms, sold and total

  let andenEftermiddag = [];
  for (let index = 0; index < duplicateear.length; index++) {
    andenEftermiddag.push({
      id: index,
      name: duplicateear[index],
      sumRes: FindSumRes(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:59", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:59", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:59", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:59", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:59", "hh:mm")
      ),
      belag: findBel(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:59", "hh:mm")
      ),
    });
  }
 

  // 1 aften forestillinger
  let startT = moment("17:59", "hh:mm");
  let endT = moment("20:29", "hh:mm");
  let afr = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(startT, endT)) {
        afr.push(i);
      }
    });
 
  let afrr = [];
  afr && afr.filter((i) => afrr.push(i.HomeTeamName));

  function FilmDuplicates(afrr) {
    const uniqueElements = new Set(afrr);

    afrr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateafr = FilmDuplicates(afrr);

  let firstAften = [];
  for (let index = 0; index < duplicateafr.length; index++) {
    firstAften.push({
      id: index,
      name: duplicateafr[index],
      sumRes: FindSumRes(
        duplicateafr[index],
        moment("17:59", "hh:mm"),
        moment("20:29", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateafr[index],
        moment("17:59", "hh:mm"),
        moment("20:29", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateafr[index],
        moment("17:59", "hh:mm"),
        moment("20:29", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateafr[index],
        moment("17:59", "hh:mm"),
        moment("20:29", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateafr[index],
        moment("17:59", "hh:mm"),
        moment("20:29", "hh:mm")
      ),
      belag: findBel(
        duplicateafr[index],
        moment("17:59", "hh:mm"),
        moment("20:29", "hh:mm")
      ),
    });
  }


  // 2 aften forestiliinger
  let start = moment("20:30", "hh:mm");
  let end = moment("24:00", "hh:mm");
  let af = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(start, end)) {
        af.push(i);
      }
    });
  
  let afarr = [];
  af && af.filter((i) => afarr.push(i.HomeTeamName));

  function FilmDuplicates(afarr) {
    const uniqueElements = new Set(afarr);

    afarr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateaften = FilmDuplicates(afarr);

  let andenAften = [];
  for (let index = 0; index < duplicateaften.length; index++) {
    andenAften.push({
      id: index,
      name: duplicateaften[index],
      sumRes: FindSumRes(
        duplicateaften[index],
        moment("20:30", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateaften[index],
        moment("20:30", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateaften[index],
        moment("20:30", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateaften[index],
        moment("20:30", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateaften[index],
        moment("20:30", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      belag: findBel(
        duplicateaften[index],
        moment("20:30", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
    });
  }

  // nat forestillinger
  let star = moment("00:00", "hh:mm");
  let ende = moment("05:00", "hh:mm");
  let nf = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(star, ende)) {
        nf.push(i);
      }
    });

  let narr = [];
  nf && nf.filter((i) => narr.push(i.HomeTeamName));

  function FilmDuplicates(narr) {
    const uniqueElements = new Set(narr);

    narr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateanat = FilmDuplicates(narr);

  let nat = [];
  for (let index = 0; index < duplicateanat.length; index++) {
    nat.push({
      id: index,
      name: duplicateanat[index],
      sumRes: FindSumRes(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      belag: findBel(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
    });
  }

  // FINDING REPEATED FILM NAMES
  let filmArr = [];
  dagsData && dagsData.filter((i) => filmArr.push(i.HomeTeamName));

  function FilmDuplicates(filmArr) {
    const uniqueElements = new Set(filmArr);

    filmArr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateFilm = FilmDuplicates(filmArr);
 

  let resSum = 0;
  let soldSum = 0;
  let totalSum = 0;
  let omsSum = 0;
  let belSum = 0;
  let totalBelSum = 0;
  let filmCount = 0;
  let totalBelagSum = 0;

  

  //sorting of columns in first eftermiddag  periode
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    function requestSort(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort } = useSortableData(firstEftermiddag);

//  console.log(formiddag, 'formiddags for')
  //sorting of columns in formiddag  periode
  const useSortableDataFor = (itemsFor, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsFor];
      
        sortableItems.sort((a,b) => 
        a.sumTotal > b.sumTotal ? -1 : 1
      )

      if (sortConfig !== null) {
        
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          
          return 0;
          
        });
       
      }
      

      return sortableItems;
    }, [itemsFor, sortConfig]);

    function requestSortFor(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsFor: sortedItems, requestSortFor, sortConfig };
  };

  const { itemsFor, requestSortFor } = useSortableDataFor(formiddag);
  


  // sorting of columns in  anden eftermiddag periode
  const useSortableDataAnden = (itemsAnden, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAnden];

      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAnden, sortConfig]);

    function requestSortAnden(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAnden: sortedItems, requestSortAnden, sortConfig };
  };

  const { itemsAnden, requestSortAnden } =
    useSortableDataAnden(andenEftermiddag);

  // sorting of columns in først aften  periode
  const useSortableDataAften = (itemsAften, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAften];

      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAften, sortConfig]);

    function requestSortAften(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAften: sortedItems, requestSortAften, sortConfig };
  };

  const { itemsAften, requestSortAften } = useSortableDataAften(firstAften);

  // sorting of columns in anden aften  periode
  const useSortableDataAften2 = (itemsAften2, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAften2];

      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAften2, sortConfig]);

    function requestSortAften2(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAften2: sortedItems, requestSortAften2, sortConfig };
  };

  const { itemsAften2, requestSortAften2 } = useSortableDataAften2(andenAften);

  // sorting of columns in nat  periode
  const useSortableDataNat = (itemsNat, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsNat];

      sortableItems.sort((a,b) => 
        a.sumOms > b.sumOms ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsNat, sortConfig]);

    function requestSortNat(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsNat: sortedItems, requestSortNat, sortConfig };
  };

  const { itemsNat, requestSortNat } = useSortableDataNat(nat);

  
  //enkelte dags formiddag fors.
  let strt = moment("9:00", "hh:mm");
  let endt = moment("11:59", "hh:mm");
  let ef = [];

 dagsData&& dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(strt, endt))
        ef.push(i);
    });
  });

  let filmsPerPeriode = [];

  for (let index = 0; index < ef.length; index++) {
    filmsPerPeriode.push({
      id: index,
      date: ef[index].ArrTime.slice(0, 10),
      name: ef[index].HomeTeamName,
      sal: ef[index].LocName,
      starTime: ef[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(ef[index].ArrTime.slice(11, 13))
        .minute(ef[index].ArrTime.slice(14, 16))
        .add(Math.floor(ef[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (ef[index].DurationSecs -
              Math.floor(ef[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: ef[index].TotalSoldAmount,
      res: ef[index].TotalResCount,
      sold: ef[index].TotalSoldCount,
      total: ef[index].TotalResCount + ef[index].TotalSoldCount,
      seatCount: ef[index].TotalSeatCount,
      bel: Math.round(
        ((ef[index].TotalResCount + ef[index].TotalSoldCount) * 100) /
          ef[index].TotalSeatCount
      ),
    });
  }

  //enkelte dags 1 eftermiddag forstillinger
  let strT = moment("11:59", "hh:mm");
  let enT = moment("14:59", "hh:mm");
  let efa = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(strT, enT))
        efa.push(i);
    });
  });

  let firstEFterFors = [];
  for (let index = 0; index < efa.length; index++) {
    firstEFterFors.push({
      id: index,
      date: efa[index].ArrTime.slice(0, 10),
      name: efa[index].HomeTeamName,
      sal: efa[index].LocName,
      starTime: efa[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(efa[index].ArrTime.slice(11, 13))
        .minute(efa[index].ArrTime.slice(14, 16))
        .add(Math.floor(efa[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (efa[index].DurationSecs -
              Math.floor(efa[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: efa[index].TotalSoldAmount,
      res: efa[index].TotalResCount,
      sold: efa[index].TotalSoldCount,
      total: efa[index].TotalResCount + efa[index].TotalSoldCount,
      seatCount: efa[index].TotalSeatCount,
      bel: Math.round(
        ((efa[index].TotalResCount + efa[index].TotalSoldCount) * 100) /
          efa[index].TotalSeatCount
      ),
    });
  }
  // enkelte dags 2 eftermiddag forstilinger
  let stTime = moment("14:59", "hh:mm");
  let enTime = moment("17:59", "hh:mm");
  let aef = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTime, enTime))
        aef.push(i);
    });
  });

  let andenEFterFors = [];
  for (let index = 0; index < aef.length; index++) {
    andenEFterFors.push({
      id: index,
      date: aef[index].ArrTime.slice(0, 10),
      name: aef[index].HomeTeamName,
      sal: aef[index].LocName,
      starTime: aef[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(aef[index].ArrTime.slice(11, 13))
        .minute(aef[index].ArrTime.slice(14, 16))
        .add(Math.floor(aef[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (aef[index].DurationSecs -
              Math.floor(aef[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: aef[index].TotalSoldAmount,
      res: aef[index].TotalResCount,
      sold: aef[index].TotalSoldCount,
      total: aef[index].TotalResCount + aef[index].TotalSoldCount,
      seatCount: aef[index].TotalSeatCount,
      bel: Math.round(
        ((aef[index].TotalResCount + aef[index].TotalSoldCount) * 100) /
          aef[index].TotalSeatCount
      ),
    });
  }
  
  // eneklt dags first aften forstllinger
  let stTim = moment("17:59", "hh:mm");
  let enTim = moment("20:29", "hh:mm");
  let faf = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTim, enTim))
        faf.push(i);
    });
  });

  let firstAftenFors = [];
  for (let index = 0; index < faf.length; index++) {
    firstAftenFors.push({
      id: index,
      date: faf[index].ArrTime.slice(0, 10),
      name: faf[index].HomeTeamName,
      sal: faf[index].LocName,
      starTime: faf[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(faf[index].ArrTime.slice(11, 13))
        .minute(faf[index].ArrTime.slice(14, 16))
        .add(Math.floor(faf[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (faf[index].DurationSecs -
              Math.floor(faf[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: faf[index].TotalSoldAmount,
      res: faf[index].TotalResCount,
      sold: faf[index].TotalSoldCount,
      total: faf[index].TotalResCount + faf[index].TotalSoldCount,
      seatCount: faf[index].TotalSeatCount,
      bel: Math.round(
        ((faf[index].TotalResCount + faf[index].TotalSoldCount) * 100) /
          faf[index].TotalSeatCount
      ),
    });
  }
  // enkelte dags anden aften fors.
  let stTm = moment("20:30", "hh:mm");
  let enTm = moment("24:00", "hh:mm");
  let aaf = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTm, enTm))
        aaf.push(i);
    });
  });

  let andenAftenFors = [];
  for (let index = 0; index < aaf.length; index++) {
    andenAftenFors.push({
      id: index,
      date: aaf[index].ArrTime.slice(0, 10),
      name: aaf[index].HomeTeamName,
      sal: aaf[index].LocName,
      starTime: aaf[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(aaf[index].ArrTime.slice(11, 13))
        .minute(aaf[index].ArrTime.slice(14, 16))
        .add(Math.floor(aaf[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (aaf[index].DurationSecs -
              Math.floor(aaf[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: aaf[index].TotalSoldAmount,
      res: aaf[index].TotalResCount,
      sold: aaf[index].TotalSoldCount,
      total: aaf[index].TotalResCount + aaf[index].TotalSoldCount,
      seatCount: aaf[index].TotalSeatCount,
      bel: Math.round(
        ((aaf[index].TotalResCount + aaf[index].TotalSoldCount) * 100) /
          aaf[index].TotalSeatCount
      ),
    });
  }
 
  // enkelte dags nat fors.
  let stTme = moment("00:00", "hh:mm");
  let enTme = moment("05:00", "hh:mm");
  let naf = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTme, enTme))
        naf.push(i);
    });
  });

  let natFors = [];
  for (let index = 0; index < naf.length; index++) {
    natFors.push({
      id: index,
      date: naf[index].ArrTime.slice(0, 10),
      name: naf[index].HomeTeamName,
      sal: naf[index].LocName,
      starTime: naf[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(naf[index].ArrTime.slice(11, 13))
        .minute(naf[index].ArrTime.slice(14, 16))
        .add(Math.floor(naf[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (naf[index].DurationSecs -
              Math.floor(naf[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: naf[index].TotalSoldAmount,
      res: naf[index].TotalResCount,
      sold: naf[index].TotalSoldCount,
      total: naf[index].TotalResCount + naf[index].TotalSoldCount,
      seatCount: naf[index].TotalSeatCount,
      bel: Math.round(
        ((naf[index].TotalResCount + naf[index].TotalSoldCount) * 100) /
          naf[index].TotalSeatCount
      ),
    });
  }

  // sorting af formiddag forstiliinger per enkelte dag
  const useSortSingleDayFormiddag = (itemsforDay, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsforDay];
      sortableItems.sort((a,b) => 
        a.total > b.total ? -1 : 1
      );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsforDay, sortConfig]);

    function requestSortForDay(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsforDay: sortedItems, requestSortForDay, sortConfig };
  };

  const { itemsforDay, requestSortForDay } =
    useSortSingleDayFormiddag(filmsPerPeriode);

  //sorting of first eftermiddag forstiliinger per enkelte dag
  const useSortSingleDayFirstEF = (itemsFirstEfDay, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsFirstEfDay];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1
    );
      if (sortConfig !== null) {
        
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsFirstEfDay, sortConfig]);

    function requestSortFirstEfDay(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsFirstEfDay: sortedItems, requestSortFirstEfDay, sortConfig };
  };

  const { itemsFirstEfDay, requestSortFirstEfDay } =
    useSortSingleDayFirstEF(firstEFterFors);

  // sorting of 2 eftermiddag fors. per enkelte dag
  const useSortSingleDayAndenEF = (itemsAndenEfDay, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAndenEfDay];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1

    );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAndenEfDay, sortConfig]);

    function requestSortAndenEfDay(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAndenEfDay: sortedItems, requestSortAndenEfDay, sortConfig };
  };

  const { itemsAndenEfDay, requestSortAndenEfDay } =
    useSortSingleDayAndenEF(andenEFterFors);

  // sorting of 1 aften fors. per enkelte dag
  const useSortSingleDayFirstAften = (itemsFirstAften, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsFirstAften];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1

    );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsFirstAften, sortConfig]);

    function requestSortFirstAften(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsFirstAften: sortedItems, requestSortFirstAften, sortConfig };
  };

  const { itemsFirstAften, requestSortFirstAften } =
    useSortSingleDayFirstAften(firstAftenFors);

  // sorting of 2 aften fors. per enkelte dag
  const useSortSingleDayAndenAften = (itemsAndenAften, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAndenAften];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1

    );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAndenAften, sortConfig]);

    function requestSortAndenAften(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAndenAften: sortedItems, requestSortAndenAften, sortConfig };
  };

  const { itemsAndenAften, requestSortAndenAften } =
    useSortSingleDayAndenAften(andenAftenFors);

  // sorting of nat fors. per enkelte dag
  const useSortSingleDayNat = (itemNat, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemNat];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemNat, sortConfig]);

    function requestSortNatFors(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemNat: sortedItems, requestSortNatFors, sortConfig };
  };

  const { itemNat, requestSortNatFors } = useSortSingleDayNat(natFors);

  // variabler til summering af perioden per period
  // til first eftermiddags period
  let resA = 0;
  let soldA = 0;
  let belA = 0;
  let totalA = 0;
  let totalBelA = 0;
  let omsA = 0;
 // til anden eftermiddags period
  let resB = 0;
  let soldB = 0;
  let belB = 0;
  let totalB = 0;
  let totalBelB = 0;
  let omsB = 0;
 //til first aftens period
  let resC = 0;
  let soldC = 0;
  let belC = 0;
  let totalC = 0;
  let totalBelC = 0;
  let omsC = 0;
 //til anden aftens period
  let resD = 0;
  let soldD = 0;
  let belD = 0;
  let totalD = 0;
  let totalBelD = 0;
  let omsD = 0;
 //til nat period
  let resE = 0;
  let soldE = 0;
  let belE = 0;
  let totalE = 0;
  let totalBelE = 0;
  let omsE = 0;
 
  

  return dagsData && dagsData.length ? (
    <>
      <div className="table-responsive" style={{marginTop:50}}>
        <h3>Summerede film for perioden:</h3>

        <table className="table myTable table-bordered">
          <thead>
            <tr>
              <th>Formiddag</th>
              <th style={{ cursor: "pointer" }}
                onClick={() => {
                  requestSort("name");
                  requestSortAnden("name");
                  requestSortFor("name");
                  requestSortAften("name");
                  requestSortAften2("name");
                  requestSortNat("name");
                }}>Film</th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => {
                  requestSort("sumRes");
                  requestSortAnden("sumRes");
                  requestSortFor("sumRes");
                  requestSortAften("sumRes");
                  requestSortAften2("sumRes");
                  requestSortNat("sumRes");
                }}
              >
                {" "}
                Res.
              </th>

              <th
                style={{ cursor: "pointer" }}
                onClick={() => {
                  requestSort("sumSold");
                  requestSortAnden("sumSold");
                  requestSortFor("sumSold");
                  requestSortAften("sumSold");
                  requestSortAften2("sumSold");
                  requestSortNat("sumSold");
                }}
              >
                Solgte
              </th>

              <th
                style={{ cursor: "pointer" }}
                onClick={() => {
                  requestSort("sumTotal");
                  requestSortAnden("sumTotal");
                  requestSortFor("sumTotal");
                  requestSortAften("sumTotal");
                  requestSortAften2("sumTotal");
                  requestSortNat("sumTotal");
                }}
              >
                Total
              </th>

              <th
                style={{ cursor: "pointer" }}
                onClick={() => {
                  requestSort("belag");
                  requestSortAnden("belag");
                  requestSortFor("belag");
                  requestSortAften("belag");
                  requestSortAften2("belag");
                  requestSortNat("belag");
                  
                }}
              >
                Belæg
              </th>

              <th
                style={{ cursor: "pointer" }}
                className="order-by-desc"
                onClick={() => {
                  requestSort("sumOms");
                  requestSortAnden("sumOms");
                  requestSortFor("sumOms");
                  requestSortAften("sumOms");
                  requestSortAften2("sumOms");
                  requestSortNat("sumOms");
                }}
              >
                {" "}
                Oms.
              </th>
            </tr>
          </thead>
          <tbody>
            
            {itemsFor
           
              .map((i) => {
               
                filmCount = filmCount + 1;
                resSum = resSum + i.sumRes;
                soldSum = soldSum + i.sumSold;
                totalSum = totalSum + i.sumTotal;
                omsSum = omsSum + i.sumOms;
                belSum = belSum + i.sumBel;
                totalBelSum = totalBelSum + i.sumSeatCount;
                totalBelagSum = Math.round(
                  ((i.sumRes + i.sumSold) * 100) / i.sumSeatCount
                );
                return (
                  <>
                  <tr key={i.id}>
                    <td></td>
                    <td>{i.name}</td>
                    <td className="rightAlign">{i.sumRes}</td>
                    <td className="rightAlign">{i.sumSold}</td>
                    <td className="rightAlign">{i.sumTotal}</td>
                    <td className="rightAlign">{i.belag} %</td>
                    <td className="rightAlign">{Math.round(i.sumOms).toLocaleString('da')}</td>
                  </tr>
                 
                  </>
                );
              })
              
              }

          {itemsFor.length > 0  ? ( 
          <tr>
            <th className="rightAlign" style={{fontWeight: '900'}}>  Summering </th>
            <th className="rightAlign" style={{fontWeight: '900'}}> {filmCount}  forestilinger </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{resSum}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{soldSum}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{totalSum}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round((totalSum * 100) / totalBelSum)}%</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round(omsSum).toLocaleString('da')}</th>
           </tr>
           ) : ('')
}
          
            <tr>
              <th colSpan={10}>1. Eftermiddag</th>
            </tr>
            
            {items
              
              .map((i) => {
                filmCount = filmCount + 1;

               resA = resA + i.sumRes;
               soldA = soldA + i.sumSold;
               totalA = totalA + i.sumTotal;
               omsA = omsA + i.sumOms;
               totalBelA = totalBelA + i.sumSeatCount;
               belA = Math.round(((resA + soldA) * 100) / totalBelA );

                resSum = resSum + i.sumRes;
                soldSum = soldSum + i.sumSold;
                totalSum = totalSum + i.sumTotal;
                omsSum = omsSum + i.sumOms;
                belSum = belSum + i.sumBel;
                totalBelSum = totalBelSum + i.sumSeatCount;
                totalBelagSum = Math.round(
                  ((i.sumRes + i.sumSold) * 100) / i.sumSeatCount
                );

                return (
                  <>
                  <tr>
                    <td></td>
                    <td>{i.name}</td>
                    <td className="rightAlign">{i.sumRes}</td>
                    <td className="rightAlign">{i.sumSold}</td>
                    <td className="rightAlign">{i.sumTotal}</td>
                    <td className="rightAlign">{i.belag} %</td>
                    <td className="rightAlign">{Math.round(i.sumOms).toLocaleString('da')}</td>
                  </tr>
                 
                  </>
                );
              })
              }

         {items.length > 0  ? (
            <tr>
            <th className="rightAlign" style={{fontWeight: '900'}}>  Summering </th>
            <th className="rightAlign" style={{fontWeight: '900'}}> {items.length}  forestilinger </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{resA}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{soldA}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{totalA}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{belA}%</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round(omsA).toLocaleString('da')}</th>
           </tr>
           ) : ('')
         }
            <tr>
              <th colSpan={10}>2. Eftermiddag</th>
            </tr>
            {itemsAnden
              
              .map((i) => {
                filmCount = filmCount + 1;

                resB = resB + i.sumRes;
               soldB = soldB + i.sumSold;
               totalB = totalB + i.sumTotal;
               omsB = omsB + i.sumOms;
               totalBelB = totalBelB + i.sumSeatCount;
               belB = Math.round(((resB + soldB) * 100) / totalBelB );


                resSum = resSum + i.sumRes;
                soldSum = soldSum + i.sumSold;
                totalSum = totalSum + i.sumTotal;
                omsSum = omsSum + i.sumOms;
                belSum = belSum + i.sumBel;
                totalBelSum = totalBelSum + i.sumSeatCount;

                return (
                  <tr>
                    <td></td>
                    <td>{i.name}</td>
                    <td className="rightAlign">{i.sumRes}</td>
                    <td className="rightAlign">{i.sumSold}</td>
                    <td className="rightAlign">{i.sumTotal}</td>
                    <td className="rightAlign">{i.belag} %</td>
                    <td className="rightAlign">{Math.round(i.sumOms).toLocaleString('da')}</td>
                  </tr>
                );
              })}
               
          
           {itemsAnden.length > 0  ? (
            <tr>
            <th className="rightAlign" style={{fontWeight: '900'}}> Summering </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{itemsAnden.length}  forestilinger </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{resB}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{soldB}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{totalB}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{belB}%</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round(omsB).toLocaleString('da')}</th>
           </tr>
           ) : ('')
         }
            <tr>
              <th colSpan={10}>1. Aften</th>
            </tr>
            {itemsAften
              
              .map((i) => {
                filmCount = filmCount + 1;

                resC = resC + i.sumRes;
               soldC = soldC + i.sumSold;
               totalC = totalC + i.sumTotal;
               omsC = omsC + i.sumOms;
               totalBelC = totalBelC + i.sumSeatCount;
               belC = Math.round(((resC + soldC) * 100) / totalBelC );

                resSum = resSum + i.sumRes;
                soldSum = soldSum + i.sumSold;
                totalSum = totalSum + i.sumTotal;
                omsSum = omsSum + i.sumOms;
                belSum = belSum + i.sumBel;
                totalBelSum = totalBelSum + i.sumSeatCount;

                return (
                  <tr>
                    <td></td>
                    <td>{i.name}</td>
                    <td className="rightAlign">{i.sumRes}</td>
                    <td className="rightAlign">{i.sumSold}</td>
                    <td className="rightAlign">{i.sumTotal}</td>
                    <td className="rightAlign">{i.belag} %</td>
                    <td className="rightAlign order-by-desc">
                      {Math.round(i.sumOms).toLocaleString('da')}
                    </td>
                  </tr>
                );
              })}

        {itemsAften.length > 0  ? (
            <tr>
            <th className="rightAlign" style={{fontWeight: '900'}}>  Summering </th>
            <th className="rightAlign" style={{fontWeight: '900'}}> {itemsAften.length}  forestilinger </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{resC}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{soldC}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{totalC}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{belC}%</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round(omsC).toLocaleString('da')}</th>
           </tr>
           ) : ('')
         }

            <tr>
              <th colSpan={10}>2. Aften</th>
            </tr>
            {itemsAften2
             
              .map((i) => {
                filmCount = filmCount + 1;

                resD = resD + i.sumRes;
                soldD = soldD + i.sumSold;
                totalD = totalD + i.sumTotal;
                omsD = omsD + i.sumOms;
                totalBelD = totalBelD + i.sumSeatCount;
                belD = Math.round(((resD + soldD) * 100) / totalBelD ); 

                resSum = resSum + i.sumRes;
                soldSum = soldSum + i.sumSold;
                totalSum = totalSum + i.sumTotal;
                omsSum = omsSum + i.sumOms;
                belSum = belSum + i.sumBel;
                totalBelSum = totalBelSum + i.sumSeatCount;

                return (
                  <tr>
                    <td></td>
                    <td>{i.name}</td>
                    <td className="rightAlign">{i.sumRes}</td>
                    <td className="rightAlign">{i.sumSold}</td>
                    <td className="rightAlign">{i.sumTotal}</td>
                    <td className="rightAlign">{i.belag} %</td>
                    <td className="rightAlign order-by-desc">
                      {Math.round(i.sumOms).toLocaleString('da')}
                    </td>
                  </tr>
                );
              })}
 {itemsAften2.length > 0  ? (
            <tr>
            <th className="rightAlign" style={{fontWeight: '900'}}>  Summering </th>
            <th className="rightAlign" style={{fontWeight: '900'}}> {itemsAften2.length}  forestilinger </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{resD}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{soldD}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{totalD}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{belD}%</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round(omsD).toLocaleString('da')}</th>
           </tr>
           ) : ('')
         }

            <tr>
              <th colSpan={10}>Nat</th>
            </tr>
            {itemsNat
              
              .map((i) => {
                filmCount = filmCount + 1;

                resE = resE + i.sumRes;
               soldE = soldE + i.sumSold;
               totalE = totalE + i.sumTotal;
               omsE = omsE + i.sumOms;
               totalBelE = totalBelE + i.sumSeatCount;
               belE = Math.round(((resE + soldE) * 100) / totalBelE );

                resSum = resSum + i.sumRes;
                soldSum = soldSum + i.sumSold;
                totalSum = totalSum + i.sumTotal;
                omsSum = omsSum + i.sumOms;
                belSum = belSum + i.sumBel;
                totalBelSum = totalBelSum + i.sumSeatCount;

                return (
                  <tr>
                    <td></td>
                    <td>{i.name}</td>
                    <td className="rightAlign">{i.sumRes}</td>
                    <td className="rightAlign">{i.sumSold}</td>
                    <td className="rightAlign">{i.sumTotal}</td>
                    <td className="rightAlign">{i.belag} %</td>
                    <td className="rightAlign">{Math.round(i.sumOms).toLocaleString('da')}</td>
                  </tr>
                );
              })}
              {itemsNat.length > 0  ? (
            <tr>
            <th className="rightAlign" style={{fontWeight: '900'}}> Summering </th>
            <th className="rightAlign" style={{fontWeight: '900'}}> {itemsNat.length}  forestilinger </th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{resE}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{soldE}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{totalE}</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{belE}%</th>
            <th className="rightAlign" style={{fontWeight: '900'}}>{Math.round(omsE).toLocaleString('da')}</th>
           </tr>
           ) : ('')
         }
          </tbody>

          <tfoot>
            <tr>
              <td></td>
              <td className="rightAlign"> </td>
              <td className="rightAlign">
                {" "}
                <b>{resSum}</b>
              </td>
              <td className="rightAlign">
                <b>{soldSum}</b>
              </td>
              <td className="rightAlign">
                <b>{totalSum}</b>
              </td>
              <td className="rightAlign">
                <b>{Math.round((totalSum * 100) / totalBelSum)}%</b>
              </td>
              <td className="rightAlign">
                <b>{Math.round(omsSum).toLocaleString('da')}</b>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="table-responsive">
        <br />
        <br />
        {duplicateElements
          .sort((a, b) => (a > b ? 1 : -1))
          .map((dates) => (
            <>
             
              <table key={dates} className="table myTable table-bordered">
                <>
                  <thead>
                    <tr>
                      <th style={{ fontSize: "1.4em" }}>
                        {days[new Date(new Date(dates).toUTCString()).getDay()]}
                      </th>
                      <th style={{ fontSize: "1.4em" }} colSpan={10}>
                        {" "}
                        {dates.slice(8, 11)}{" "}
                        {new Date(dates).toLocaleString("default", {
                          month: "long",
                        })}{" "}
                      </th>
                    </tr>

                    <tr>
                      <th className="order-by-desc">formiddag</th>
                      <th style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("name");
                          requestSortFirstEfDay("name");
                          requestSortAndenEfDay("name");
                          requestSortFirstAften("name");
                          requestSortAndenAften("name");
                          requestSortNatFors("name");
                        }}>Film</th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("starTime");
                          requestSortFirstEfDay("starTime");
                          requestSortAndenEfDay("starTime");
                          requestSortFirstAften("starTime");
                          requestSortAndenAften("starTime");
                          requestSortNatFors("starTime");
                        }}
                      >
                        Start
                      </th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("sal");
                          requestSortFirstEfDay("sal");
                          requestSortAndenEfDay("sal");
                          requestSortFirstAften("sal");
                          requestSortAndenAften("sal");
                          requestSortNatFors("sal");
                        }}
                      >
                        Sal
                      </th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("endT");
                          requestSortFirstEfDay("endT");
                          requestSortAndenEfDay("endT");
                          requestSortFirstAften("endT");
                          requestSortAndenAften("endT");
                          requestSortNatFors("endT");
                        }}
                      >
                        Slut
                      </th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("res");
                          requestSortFirstEfDay("res");
                          requestSortAndenEfDay("res");
                          requestSortFirstAften("res");
                          requestSortAndenAften("res");
                          requestSortNatFors("res");
                        }}
                      >
                        Res.
                      </th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("sold");
                          requestSortFirstEfDay("sold");
                          requestSortAndenEfDay("sold");
                          requestSortFirstAften("sold");
                          requestSortAndenAften("sold");
                          requestSortNatFors("sold");
                        }}
                      >
                        Solgte
                      </th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("total");
                          requestSortFirstEfDay("total");
                          requestSortAndenEfDay("total");
                          requestSortFirstAften("total");
                          requestSortAndenAften("total");
                          requestSortNatFors("total");
                        }}
                      >
                        Total
                      </th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("bel");
                          requestSortFirstEfDay("bel");
                          requestSortAndenEfDay("bel");
                          requestSortFirstAften("bel");
                          requestSortAndenAften("bel");
                          requestSortNatFors("bel");
                        }}
                      >
                        Belæg
                      </th>
                      <th
                        id="oms"
                        className="order-by-desc"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          requestSortForDay("oms");
                          requestSortFirstEfDay("oms");
                          requestSortAndenEfDay("oms");
                          requestSortFirstAften("oms");
                          requestSortAndenAften("oms");
                          requestSortNatFors("oms");
                        }}
                      >
                        Oms.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                    {itemsforDay
                      .map((item) => {
                        if (item.date === dates) {
                          forsCount = forsCount + 1;
                          Reserveret = Reserveret + item.res;
                          solgte = solgte + item.sold;
                          total = total + item.res + item.sold;
                          oms = oms + item.oms;
                          totalSeat = totalSeat + item.seatCount;

                          return (
                            <>
                              <tr key={item}>
                                <td className="rightAlign">
                                  {" "}
                                  {Math.round(item.oms)}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.name}
                                </td>
                                <td className="rightAlign">{item.starTime}</td>
                                <td className="rightAlign">{item.sal}</td>
                                <td className="rightAlign"> {item.endT}</td>
                                <td className="rightAlign">{item.res}</td>
                                <td className="rightAlign">{item.sold}</td>
                                <td className="rightAlign">{item.total}</td>
                                <td className="rightAlign">{item.bel} %</td>
                                <td className="rightAlign">
                                  {Math.round(item.oms).toLocaleString('da')}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                  <tbody>
                    <tr>
                      <th colSpan={10}>1. Eftermiddag</th>
                    </tr>
                    {itemsFirstEfDay
                      .map((item) => {
                        if (item.date === dates) {
                          forsCount = forsCount + 1;
                          Reserveret = Reserveret + item.res;
                          solgte = solgte + item.sold;
                          total = total + item.res + item.sold;
                          oms = oms + item.oms;
                          totalSeat = totalSeat + item.seatCount;

                          return (
                            <>
                              <tr key={item}>
                                <td className="rightAlign">
                                  {" "}
                                  {Math.round(item.oms)}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.name}
                                </td>
                                <td className="rightAlign">{item.starTime}</td>
                                <td className="rightAlign">{item.sal}</td>
                                <td className="rightAlign"> {item.endT}</td>
                                <td className="rightAlign">{item.res}</td>
                                <td className="rightAlign">{item.sold}</td>
                                <td className="rightAlign">{item.total}</td>
                                <td className="rightAlign">{item.bel} %</td>
                                <td className="rightAlign">
                                  {Math.round(item.oms).toLocaleString('da')}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                  <tbody>
                    <tr>
                      <th colSpan={10}>2. Eftermiddag</th>
                    </tr>
                    {itemsAndenEfDay
                      .map((item) => {
                        if (item.date === dates) {
                          forsCount = forsCount + 1;
                          Reserveret = Reserveret + item.res;
                          solgte = solgte + item.sold;
                          total = total + item.res + item.sold;
                          oms = oms + item.oms;
                          totalSeat = totalSeat + item.seatCount;

                          return (
                            <>
                              <tr key={item}>
                                <td className="rightAlign">
                                  {" "}
                                  {Math.round(item.oms)}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.name}
                                </td>
                                <td className="rightAlign">{item.starTime}</td>
                                <td className="rightAlign">{item.sal}</td>
                                <td className="rightAlign"> {item.endT}</td>
                                <td className="rightAlign">{item.res}</td>
                                <td className="rightAlign">{item.sold}</td>
                                <td className="rightAlign">{item.total}</td>
                                <td className="rightAlign">{item.bel} %</td>
                                <td className="rightAlign">
                                  {Math.round(item.oms).toLocaleString('da')}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                  <tbody>
                    <tr>
                      <th colSpan={10}>1.Aften</th>
                    </tr>
                    {itemsFirstAften
                      .map((item) => {
                        if (item.date === dates) {
                          forsCount = forsCount + 1;
                          Reserveret = Reserveret + item.res;
                          solgte = solgte + item.sold;
                          total = total + item.res + item.sold;
                          oms = oms + item.oms;
                          totalSeat = totalSeat + item.seatCount;

                          return (
                            <>
                              <tr key={item}>
                                <td className="rightAlign">
                                  {" "}
                                  {Math.round(item.oms)}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.name}
                                </td>
                                <td className="rightAlign">{item.starTime}</td>
                                <td className="rightAlign">{item.sal}</td>
                                <td className="rightAlign"> {item.endT}</td>
                                <td className="rightAlign">{item.res}</td>
                                <td className="rightAlign">{item.sold}</td>
                                <td className="rightAlign">{item.total}</td>
                                <td className="rightAlign">{item.bel} %</td>
                                <td className="rightAlign">
                                  {Math.round(item.oms).toLocaleString('da')}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                  <tbody>
                    <tr>
                      <th colSpan={10}>2.Aften</th>
                    </tr>
                    {itemsAndenAften
                      .map((item) => {
                        if (item.date === dates) {
                          forsCount = forsCount + 1;
                          Reserveret = Reserveret + item.res;
                          solgte = solgte + item.sold;
                          total = total + item.res + item.sold;
                          oms = oms + item.oms;
                          totalSeat = totalSeat + item.seatCount;

                          return (
                            <>
                              <tr key={item}>
                                <td className="rightAlign">
                                  {" "}
                                  {Math.round(item.oms)}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.name}
                                </td>
                                <td className="rightAlign">{item.starTime}</td>
                                <td className="rightAlign">{item.sal}</td>
                                <td className="rightAlign"> {item.endT}</td>
                                <td className="rightAlign">{item.res}</td>
                                <td className="rightAlign">{item.sold}</td>
                                <td className="rightAlign">{item.total}</td>
                                <td className="rightAlign">{item.bel} %</td>
                                <td className="rightAlign">
                                  {Math.round(item.oms).toLocaleString('da')}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Nat </th>
                    </tr>
                    {itemNat
                      .map((item) => {
                        if (item.date === dates) {
                          forsCount = forsCount + 1;
                          Reserveret = Reserveret + item.res;
                          solgte = solgte + item.sold;
                          total = total + item.res + item.sold;
                          oms = oms + item.oms;
                          totalSeat = totalSeat + item.seatCount;

                          return (
                            <>
                              <tr key={item}>
                                <td className="rightAlign">
                                  {" "}
                                  {Math.round(item.oms)}
                                </td>
                                <td
                                  className="text-truncate"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.name}
                                </td>
                                <td className="rightAlign">{item.starTime}</td>
                                <td className="rightAlign">{item.sal}</td>
                                <td className="rightAlign"> {item.endT}</td>
                                <td className="rightAlign">{item.res}</td>
                                <td className="rightAlign">{item.sold}</td>
                                <td className="rightAlign">{item.total}</td>
                                <td className="rightAlign">{item.bel}%</td>
                                <td className="rightAlign">
                                  {Math.round(item.oms).toLocaleString('da')}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="rightAlign">
                        <b>{forsCount} forestillinger</b>
                      </td>
                      <td colSpan={3}></td>
                      <td className="rightAlign">
                        <b>{Reserveret} </b>
                      </td>
                      <td className="rightAlign">
                        <b>{solgte}</b>
                      </td>
                      <td className="rightAlign">
                        <b>{total}</b>
                      </td>
                      <td className="rightAlign">
                        <b> {Math.round((total * 100) / totalSeat)} %</b>
                      </td>
                      <td className="rightAlign">
                        <b>{Math.round(oms).toLocaleString('da')}</b>
                      </td>
                    </tr>
                  </tfoot>
                  <p className="text-dark hide">
                    {(forsCount = 0)}
                    {(Reserveret = 0)}
                    {(solgte = 0)}
                    {(total = 0)}
                    {(oms=0)}
                    {/* {[tt = 0]} */}
                  </p>
                </>
              </table>
              <br />
              <br />
            </>
          ))}
        <ScrollToTop smooth color="#000" style={{marginBottom: 40 + 'px'}}/>
      </div>
    </>
  ) : (
    <p></p>
  );
}

export default DayTable;
