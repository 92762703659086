import React, {useState} from 'react'


function DriftSms() {
  const key = sessionStorage.getItem("key");
  const [code, setCode] = useState("45");
  const [tlfNumber, setTlfNumber] = useState("");
  const [name, setName] = useState("");
 
  const [msgFrmApi, setMsgFrmApi] = useState('');
  let theme = localStorage.getItem("theme")
  const handleCodeChange = (e) => {
    setCode(e.target.value);
    
  };
// console.log(code)
  const handleNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTlfNumber(value);
  };

  const handleNameChange = (e) => {
    const navn = e.target.value.replace(/[^a-z]/gi, "");
    setName(navn);
  };
  function tilmeldSMS(e) {
    e.preventDefault();
    const phone = code + tlfNumber;
    
     fetch(`https://biograf.ebillet.dk/systemnative/incident.asmx/man_addphone?Key=${key}&Phone=%2B${phone}&Name=${name}`, {
      method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
     })
     .then((res) =>   res.json() )
     .then((result) => {
      // console.log(result) 
      setName('');
        setTlfNumber('')
      if(result.Result === true) {
        
          alert('Du har tilmeldt vores drift SMS')
       
      }
      if(result.Result === false) {
        
          alert('Du har allerede tilmeldt vores drift SMS')

      }
    })
  }

  function afmeldSMS(e) {
    e.preventDefault();
    const phone = code + tlfNumber;
    // console.log(phone);

     fetch(`https://biograf.ebillet.dk/systemnative/incident.asmx/man_deletephone?Key=${key}&Phone=%2B${phone}`, {
      method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
     })
     .then((res) => res.json())
     .then((result) => {
      // console.log(result)
      setTlfNumber('')
      if(result.Result === true) {
        
        alert('Du har nu frameldt vores drift SMS')
     
    }
     })
  };


  return (
    <div className="messages">
    <p className="sms">Tilmeld/afmeld Drift SMS </p> <br /> 
   
    <p className="sms-desc" style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>
      Når du er tilmeldt Drift SMS, får du en SMS, når der er ændringer i
      driften på vores systemer.
    </p>{" "}
    <br />
    <form className="sms-form" style={{}}>
      <label htmlFor="" className='sms-labels' style={{color: theme === 'dark' ? '#fff' : '#46484c'}} >Landekode</label>
      <select
        className="select-country inputs"
        value={code}
        onChange={handleCodeChange}>
        <option value="45">Denmark(+45)</option>
        <option value="299">Grønland(+299)</option>
        <option value="298">Færøerne(+298)</option>
      </select> 
      <br />
      <label htmlFor="" className='sms-labels' style={{color: theme === 'dark' ? '#fff' : '#46484c'}} >Dit mobilnummer</label>
      <input
        type="text"
        // placeholder="dit mobilnummer"
        className="inputs"
        onChange={handleNumberChange}
        value={tlfNumber}
        maxLength={code === "45" ? 8 : 6}
      />{" "} 
      <br />
      <label htmlFor="" className='sms-labels' style={{color: theme === 'dark' ? '#fff' : '#46484c'}} >Dit navn</label>
      <input
        type="text"
        className="inputs"
        // placeholder="dit navn.."
        value={name} 
        onChange={handleNameChange}
      />
      
      <span style={{fontSize: 12 + 'px', marginTop: 10 + 'px', marginBottom: 25 + 'px' }}>  Navn behøves ikke indtastes ved framelding</span>
      <div className="buttons">
        <button className="btns" onClick={tilmeldSMS}>
          TILMELD
        </button>
        <button className="btns" onClick={afmeldSMS}>
          FRAMELD
        </button>
      </div>
      <p style={{textAlign:'left'}}>{msgFrmApi} </p>
    </form>
    <br />
   
  </div>
  )
}

export default DriftSms