import React, {useContext}   from 'react'
import { keyContext } from "./KeyContext";
import moment from "moment";


function DagenTable({selectedDate, dag}) {
  const { dagensData } = useContext(keyContext);
  let theme = localStorage.getItem('theme')
// console.log(dagensData)

  let filmPerFors = [];
  let filmName = [];
  let start = [];
  let sal = [];
  let end = [];
  let res = [];
  let sold = [];
  let totl = [];
  let bel = [];
  let oms = [];
  let datoer = [];
  let seatCount = [];
  let Reserveret = 0;
  let solgte = 0;
  let total = 0;
  let totalBel = 0;
  let totalOms = 0;

  dagensData&&
  dagensData.map((item) => {
      filmName.push(item.HomeTeamName);
      seatCount.push(item.TotalSeatCount);
      datoer.push(item.ArrTime.slice(0, 10));
      oms.push(Math.round(item.TotalSoldAmount));
      bel.push(
        Math.round(
          ((item.TotalResCount + item.TotalSoldCount) * 100) /
            item.TotalSeatCount
        )
      );
      totl.push(item.TotalResCount + item.TotalSoldCount);
      res.push(item.TotalResCount);
      sold.push(item.TotalSoldCount);
      start.push(item.ArrTime.slice(11, 16));
      sal.push(item.LocName);
      end.push(
        moment()
          .hour(item.ArrTime.slice(11, 13))
          .minute(item.ArrTime.slice(14, 16))
          .add(Math.floor(item.DurationSecs / 3600), "hours")
          .add(
            Math.floor(
              (item.DurationSecs -
                Math.floor(item.DurationSecs / 3600) * 3600) /
                60
            ),
            "minutes"
          )
          .format("HH:mm")
      );
    });

    if (dagensData) {
      for (let index = 0; index < dagensData.length; index++) {
        filmPerFors.push({
          id: index,
          name: filmName[index],
          start: start[index],
          end: end[index],
          sal: sal[index],
          res: res[index],
          sold: sold[index],
          total: totl[index],
          belaeg: bel[index],
          oms: oms[index],
          dato: datoer[index],
          seat_count: seatCount[index],
        });
      }
    // setDagensData(filmPerFors)
  
    }
  // sorting of tables (works on billetsalg per forestilling table)
  const useSortTableData = (filmsItems, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...filmsItems];
      sortableItems.sort((a,b) => 
        a.start > b.start ? 1 : -1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
         
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [filmsItems, sortConfig]);

    const requestFilmSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { filmsItems: sortedItems, requestFilmSort, sortConfig };
  };
  
  const { filmsItems, requestFilmSort } = useSortTableData(filmPerFors);
  
      //  console.log(filmsItems)
  return   dagensData.length ? ( 
        <main>

        <h1 className='dagensTile' style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>Billetsalg pr. forestilling</h1> 
        <br />
        
        <div className="table-responsive">
       <table className="table myTable table-bordered table-responsive">
        <thead> 
          <tr>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("name")}>{dag}, den {selectedDate}</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("start")}>Start</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("sal")}>Sal</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("end")}>Slut</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("res")}>Reserveret </th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("sold")}>Solgte</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("total")}>Total</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("belaeg")}>Belæg</th>
            <th style={{cursor:'pointer'}} onClick={() => requestFilmSort("oms")}>Oms.</th>
          </tr>
        </thead>
        <tbody>
          {dagensData && filmsItems.map(item => {
            if(item) {
              Reserveret = Reserveret + item.res;
              solgte = solgte + item.sold;
              total = total + item.res + item.sold;
              totalOms = totalOms + item.oms;
              totalBel = totalBel +  item.seat_count;
              
              return (
              <>
            <tr key={item.StatArrNo}>
              <td className="text-truncate" style={{ maxWidth: "200px" }}> {item.name}</td> 
              <td className="rightAlign">{item.start}</td>
              <td className="rightAlign">{item.sal}</td>
              <td className="rightAlign">{item.end  }</td>
              <td className="rightAlign">{item.res}</td>
              <td className="rightAlign">{item.sold}</td>
              <td className="rightAlign">{item.total}</td>
              <td className="rightAlign">{item.belaeg}%</td>
              <td className="rightAlign">{item.oms.toLocaleString('da')}</td>
            </tr>

            </>
            ) }
         }
          )}
        </tbody>
        <tfoot>
          <tr>
            <td className="rightAlign"><b>{dagensData && dagensData.length} forestillinger</b></td>
            <td colSpan={3}></td>
            <td className="rightAlign">{Reserveret}</td>
            <td className="rightAlign">{solgte}</td>
            <td className="rightAlign">{total}</td>
            <td className="rightAlign">{Math.round((total * 100) / totalBel)}%</td>
            <td className="rightAlign">{Math.round(totalOms).toLocaleString('da')}</td>
          </tr>
        </tfoot>
       </table>
        </div>
        </main>
        ) : (<p></p>) 
    
  
}

export default DagenTable