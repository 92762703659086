import React, { useEffect, useContext, useState } from "react";
import "./header.css";

import logo from "../../images/ebillet_logo 1.png";
import home from "../../images/-home.png";
import docs from "../../images/-documents.png";
import sms from "../../images/-sms.png";
import settings from "../../images/-settings.png";
import arrow from "../../images/icon-arrow.png";
import arrowBlack from "../../images/icon-arrow-black.png";

import { Link } from "react-router-dom";
import { keyContext } from "../../components/KeyContext";
import { useNavigate } from "react-router-dom";

// import BilletsalgPerFilm from "../../views/BilletsalgPerFilm";
import UgesRapport from "../../views/UgesRapport";
import Dagens from "../../views/Dagens";
import Dagperiode from "../../views/Dagperiode";
// import AdminIncidents from "../../views/AdminIncidents";
// import Test from "../../views/PeriodeSam";
import PeriodeSam from "../../views/PeriodeSam";
import WorkPlan from "../../views/WorkPlan";
// import Navbar from "../Navbar";

export default function Header({
  isActive,
  smsActive,
  setsActive,
  homeActive,
  pageView,
}) {
  const navigate = useNavigate();

  const { userName, setData, setDagsData, setDagensData, setOpen, open, isChecked } =
    useContext(keyContext);
    // console.log(isChecked)
  localStorage.setItem('burgerMenuChecked', isChecked)

  const user = sessionStorage.getItem("user");
  const [arrowActive, setArrowActive] = useState(false);
  const [show, setShow] = useState(false);
  const [rights, setRights] = useState([{}]);

  const [ugesRapportIsActive, setUgesRapportIsActive] = useState(false);
  const [showUgesRapport, setShowUgesRapport] = useState(false);
  const [dagsperiodeIsActive, setDagsperiodeIsActive] = useState(false);
  const [showDagsperiode, setShowDagsperiode] = useState(false);

  let key = sessionStorage.getItem("key");
  let orgID = sessionStorage.getItem("orgID");
  const burgerMenuDisplay = localStorage.getItem('burgerMenuChecked');
  // console.log(burgerMenuDisplay)

  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getaccessrights?Key=${key}&LoginNo=${orgID}`;
    if (key && orgID) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          if (result.result === 0) {
            navigate("/");
          }
          setRights(result);
        });
    }
  }, [key, orgID]);

  function logOut() {
    sessionStorage.removeItem("key");
   // sessionStorage.removeItem("user");
    sessionStorage.removeItem("period");
    sessionStorage.removeItem("film");
    sessionStorage.removeItem("default-filmID");
    sessionStorage.removeItem("orgID");
    setData("");
    setDagsData("");
    setDagensData("");
    navigate("/");
    window.location.reload();
  }

  function handleArrowClick() {
    setArrowActive((current) => !current);
    setShow((current) => !current);
  }

  function handleClick() {
    setOpen(!open);
  }

  // function handleUgeRapportClick() {
  //   setUgesRapportIsActive((current) => !current);
  //   setShowUgesRapport((current) => !current);
  // }

  // function handleDagsPeriodeClick() {
  //   setDagsperiodeIsActive((current) => !current);
  //   setShowDagsperiode((current) => !current);
  // }

  const [pageKey, setPageKey] = React.useState("z");

const Menu = (props) => {
    return (
      <div id="meu" className="has-scrollbar">
        <button
          style={{
            border: "none",
            background: "transparent",
            color: pageKey === "a" ? "#982f2f" : "black",
            marginBottom: 6 + "px",
          }}
          onClick={() => {
            props.onClick("a");
          }}
        >
          Ugestatestik{" "}
        </button>
        {/* <button   style={{border:'none', marginBottom: 6 + 'px', background:'transparent', color: pageKey === 'b'  ? "#982f2f" : "black",  marginLeft:'8px'}} onClick={() => {props.onClick("b") }}>Billetsalg pr. forestilling</button>   */}
      </div>
    );
  };
  // const Menu = (props) => {
  //   return (
  //     <div id="meu" className="has-scrollbar">
  //       <button
  //         style={{
  //           border: "none",
  //           background: "transparent",
  //           color: pageKey === "a" ? "#982f2f" : "black",
  //           marginLeft: "8px",
  //         }}
  //         onClick={() => {
  //           props.onClick("a");
  //         }}
  //       >
  //         Billetsalg pr.film/dag{" "}
  //       </button>
  //       <button
  //         style={{
  //           border: "none",
  //           marginBottom: 6 + "px",
  //           background: "transparent",
  //           color: pageKey === "b" ? "#982f2f" : "black",
  //           marginLeft: "8px",
  //         }}
  //         onClick={() => {
  //           props.onClick("b");
  //         }}
  //       >
  //         Billetsalg pr. forestilling
  //       </button>
  //     </div>
  //   );
  // };
  const DagsMenu = (props) => {
    return (
      <div>
        <button
          style={{
            border: "none",
            marginBottom: 6 + "px",
            background: "transparent",
            color: pageKey === "c" ? "#982f2f" : "black",
            marginLeft: "8px",
          }}
          onClick={() => {
            props.onClick("c");
          }}
        >
          {" "}
          Dagsperioder
        </button>

        {/* <button style={{border:'none',marginBottom: 6 + 'px',  background:'transparent' , color: pageKey === 'd'  ? "#982f2f" : "black", marginLeft:'8px'}} onClick={() => {props.onClick("d") } }> Dagsperioder pr. dag</button>  */}
      </div>
    );
  };
  let page;
  switch (pageKey) {
    case "a":
      page = <UgesRapport />;
      break;

    // case "b":
    //   page = <UgesRapport />;
    //   break;

    case "c":
      page = <Dagperiode />;
      break;

    // case "d":
    //   page = <Dagperiode />;
    //   break;

    case "e":
      page = <Dagens />;
      break;

    case "f":
      page = <PeriodeSam />;
      break;

    case "x":
      page = <WorkPlan />
      break;

    default:
      page = "";
      break;
  }
  // page.push(pageView)

  return (
    <>
      <header className="header-menu" 
      style={{height: isChecked === true  ? '50px' : '70px',
      position: isChecked === true ? 'relative' : ''
       }}>
        <img src={logo} alt="" />

        <div className="menus" style={{ display: isChecked === true ? 'none' : ''}}>
          <a className={homeActive} href>
            <Link className="menu-item" to="/admin">
              <img src={home} alt="" />
              <p className="menus-text">Hjem</p>
            </Link>
          </a>

          <a className={isActive} href>
            <Link className="menu-item" to="/reports">
              <img src={docs} alt="" />
              <p className="menus-text">Rapporter</p>
            </Link>
          </a>

          <a className={smsActive} href>
            <Link className="menu-item" to="/messages">
              <img src={sms} alt="" />
              <p className="menus-text">Drift SMS</p>
            </Link>
          </a>

          <a className={setsActive} href>
            <Link className="menu-item" to="/configuration">
              <img src={settings} alt="" />
              <p className="menus-text">Opsætning</p>
            </Link>
          </a>
        </div>

        <div className="logInfo" style={{ display: isChecked === true  ? 'none' : ''}}>
          <p className="user">{userName ? userName : user}</p>
          <span className="Rectangle-13"></span>
          <button className="logout" onClick={logOut}>
            Log ud
          </button>
        </div>

        <div className="mobil-menu" style={{ display: isChecked === true ? 'flex' : ''}}>
          <button className="logud" onClick={logOut} 
          style={{color: isChecked === true ? 'white' : '', borderLeft : isChecked === true ? '0px' : ''}}
          >
            {" "}
            Log ud
          </button>

          <div className="Rectangle-2" onClick={handleClick} 
          style={{position: isChecked === true ? 'relative' : '' ,
           width: isChecked === true? '50px' : '', 
           backgroundColor: isChecked === true ? '#982f2f' : '',
          //  border: burgerMenuDisplay ? '1px solid white' : '',
           margin : isChecked === true ? '3px' : '',
           padding :isChecked === true ? '12px' : ''
           }}>
            <div class="Rectangle" 
            style={{backgroundColor: isChecked === true ? 'white' : '',
            height : isChecked === true ? '3px' : '', 
            // marginTop : burgerMenuDisplay ? '4px' :''
          }}
            ></div>
            <div class="Rectangle" style={{backgroundColor: isChecked === true ? 'white' : '',
            height : isChecked === true ? '3px' : '',
            marginTop : isChecked === true ? '4px' :''

          }}></div>
            <div class="Rectangle" style={{backgroundColor: isChecked === true ? 'white' : '',
            height : isChecked === true ? '3px' : '',
            marginTop : isChecked === true ? '4px' :''

          }}></div>
          </div>
        </div>
        {open && (
          <aside className="burgerMenu" style={{
             position: isChecked === true ? 'absolute' : '',
             top: isChecked === true ? '50px' : '',
             display: isChecked === true ? 'flex' : '',
             right: isChecked === true ? 0 : '',
             width: isChecked === true ? '40%' : '',
             minHeight: isChecked === true ? '954px' : '',
             backgroundColor : isChecked === true ? '#ececec' : '',
             flexDirection : isChecked === true ? 'column' : '',
             zIndex : isChecked === true ? 99 : ''
             }}>
            <div
              className="burgerMenuItems"
              onClick={handleClick}
              style={{ marginTop: 2 , 
                display: isChecked === true ? 'flex' : '',
                height: isChecked === true ? '42px' : '',
                margin: isChecked === true ? '3px' : '',
                padding: isChecked === true ? '7px' : '',
                backgroundColor : isChecked === true ? '#810101' : ''
              }}
            >
              <figure>
                <img src={home} alt="" />
              </figure>
              <Link
                to="/admin"
                style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}
              >
                <p className="menus-text">Hjem</p>
              </Link>
            </div>

            <div className="burgerMenuItems" onClick={handleArrowClick} 
             style={{ marginTop: 2 , 
              display: isChecked === true ? 'flex' : '',
              height: isChecked === true ? '42px' : '',
              margin: isChecked === true ? '3px' : '',
              padding: isChecked === true ? '7px' : '',
              backgroundColor : isChecked === true ? '#810101' : '',
              color: isChecked === true ? 'white' : ''
            }}
            >
              <figure>
                <img src={docs} alt="" />
              </figure>
              <p style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}>
                {" "}
                Rapporter
              </p>

              <button className="arrow" style={{
                border: isChecked === true ? 'none' : '',
                background : isChecked === true ? 'transparent' : '',
                position : isChecked === true ? 'absolute' : '',
                right : isChecked === true ? '10px' : ''
                }}>
                <p className={arrowActive ? "rotated" : ""}>
                  {" "}
                  <img src={arrow} alt="" />{" "}
                </p>
              </button>
            </div>

            {arrowActive && (
              <>
              {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_COMPAREPERIODS")
                      return (
                     <div className="meu" key={index} onClick={handleClick}>
                          <Link
                            to="/perioder"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>Periodesammenligninger</p>{" "}
                          </Link>
                        </div>
);
})}
              
              

                {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_DAYPERIODS")
                      return (
                        <div className="meu" key={index} onClick={handleClick}>
                          <Link
                            to="/dagsrapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {" "}
                            <p style={{ paddingLeft: 10 }}>Dagsperioder</p>
                          </Link>

                        
                        </div>
                      );
                  })}

               

                {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_DAYREVENUE")
                      return (
                        <div className="meu" key={index} onClick={handleClick}>
                          <Link
                            to="/dagenrapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>Dagens omsætning </p>{" "}
                          </Link>
                        </div>
                      );
                  })}

{rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_WEEKSTAT")
                      return (
                        <div className="meu" key={index} onClick={handleClick}>
                          <Link
                            to="/ugesrapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>
                              Ugestatestik
                            </p>{" "}
                          </Link>

                         
                        </div>
                      );
                  })}

{rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_WORKPLAN")
                      return (
                        <div className="meu" key={index} onClick={handleClick}>
                          <Link
                            to="/workplans"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>Arbejdsplan</p>{" "}
                          </Link>
                        </div>
                        );
                  })}
              </>
            )} 

            <div className="burgerMenuItems" onClick={handleClick}  style={{ marginTop: 2 , 
                display: isChecked === true ? 'flex' : '',
                height: isChecked === true ? '42px' : '',
                margin: isChecked === true? '3px' : '',
                padding: isChecked === true ? '7px' : '',
                backgroundColor : isChecked === true ? '#810101' : ''
              }}>
              <figure>
                <img src={sms} alt="" />
              </figure>

              <Link
                to="/messages"
                style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}
              >
                <p className="menus-text">Drift SMS</p>
              </Link>
            </div>

            <div className="burgerMenuItems" onClick={handleClick}  style={{ marginTop: 2 , 
                display: isChecked === true ? 'flex' : '',
                height: isChecked === true ? '42px' : '',
                margin: isChecked === true ? '3px' : '',
                padding: isChecked === true ? '7px' : '',
                backgroundColor : isChecked === true ? '#810101' : ''
              }}>
              <figure>
                <img src={settings} alt="" />
              </figure>
              <Link
                style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}
                to="/configuration"
              >
                <p className="menus-text">Opsætning</p>
              </Link>
            </div>
          </aside>
        )}
      </header>
      {page}
    </>
  );
}
