import { createContext, useState, useEffect } from "react";

export const keyContext = createContext();

function KeyContextProvider(props) {
  const [data, setData] = useState([]);
  const [dagsData, setDagsData] = useState([]);
  const [dagensData, setDagensData] = useState([]);
  const [open, setOpen] = useState(false);
  const [dagensSelectedDate, setDagensSelectedDate] = useState("");
  const [dagensNextDay, setDagensNextDay] = useState("");
  const [dagsFilm, setDagsFilm] = useState("");
  const [defaultFilm, setDefaultFilm] = useState("");
  const [filmId, setFilmId] = useState("");
  const [networkError, setNetworkError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isChecked, setIsChecked] = useState(localStorage.getItem("burgerMenuChecked") === "true" ? true : false );

   const [isSoldChecked, setIsSoldChecked] = useState(true);
  // const [isSoldChecked, setIsSoldChecked] = useState(sessionStorage.getItem("antalSoldChecked") === "true" ? true : false);
  const [isTicketChecked, setIsTicketChecked] = useState( true );
  const [isKioskChecked, setIsKioskChecked] = useState(true );
  const [isAndenChecked, setIsAndenChecked] = useState(true );
  const [isSamletChecked, setIsSamletChecked] = useState(true);
  const [isKioskOmsChecked, setIsKioskOmsChecked] = useState(true );

  // const [key, setKey] = useState('');
  // const [wrongUser, setWrongUser] = useState('');
  const [movieNumber, setMovieNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  

  return (
    <keyContext.Provider
      value={{
       
        isKioskChecked, setIsKioskChecked,
        isKioskOmsChecked, setIsKioskOmsChecked,
        isSamletChecked, setIsSamletChecked,
        isAndenChecked, setIsAndenChecked,
        isTicketChecked, setIsTicketChecked,
        isSoldChecked, setIsSoldChecked,
        isChecked,
        setIsChecked,
        dagensNextDay,
        setDagensNextDay,
        dagensSelectedDate,
        setDagensSelectedDate,
        open,
        setOpen,
        dagensData,
        setDagensData,
        defaultFilm,
        setDefaultFilm,
        networkError,
        dagsFilm,
        setDagsFilm,
        dagsData,
        setDagsData,
        data,
        setData,
        userName,
        setUserName,
        password,
        setPassword,
        movieNumber,
        setMovieNumber,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        filmId,
        setFilmId,
        errorMessage,
      }}
    >
      {props.children}
    </keyContext.Provider>
  );
}

export default KeyContextProvider;
