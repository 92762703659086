import React, { useState, useEffect } from "react";
import Incidents from "../components/Incidents/Incidents";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

function AdminIncidents() {
  const key = sessionStorage.getItem("key");
 
  const [incident, setIncident] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [incidentAdded, setIncidentAdded] = useState(false);

  const [incidentsList, setIncidentsList] = useState("");
 

  const navigate = useNavigate();

  let tomorrow = moment().add(1, "days").format("YYYY-MM-DD");

  

  function handleIncident() {
                 setIsShown(true)
 }

 useEffect(() => {
    
  fetch(`https://biograf.ebillet.dk/systemnative/incident.asmx/man_getincident?Key=${key}&IncidentNo=7`, {
    method: "GET",
    headers: {
      ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
    },
  })
    .then((res) => res.json())
    .then((result) => {
      // console.log(result);
      if (!result) {
        navigate("/");
      }
    });
     
}, [key]);

function addIncident(e) {
  e.preventDefault();
 
  fetch(
    `https://biograf.ebillet.dk/systemnative/incident.asmx/man_addincident?Key=${key}&IncidentName=${incident}`,
    {
      method: "GET",
      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    }
  )
    .then((res) => res.json())
    .then((result) => {
      // console.log(result)
      if(result.Result === true) {
        // navigate('/messages')
        setIncidentAdded(true);
        setIncident('')
        //  window.location.reload()

      }
    });
}

// const [users, setUsers] = useState([]);

useEffect(() => {
  const endpoint = `https://biograf.ebillet.dk/systemnative/incident.asmx/man_listincident?Key=${key}&FromDate=2022-10-01&ToDate=${tomorrow}`;
  // console.log(tomorrow)
  fetch(endpoint, {
    method: "GET",
    headers: {
      ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
    },
  })
    .then((res) => res.json())
    .then((result) => {
      
      setIncidentsList(result);
    });
}, [key, tomorrow, incidentAdded]);
// console.log(incidentsList)

  return (
    <main className="incident-container">
    <div >
      <section className="incident-header">
        <p className="title"> Hændelser </p>
        <form action="">
           <input  type="text" className="incident-name"
            placeholder="Indtast navn på hændelse"
            value={incident}
            onChange={(e) => setIncident(e.target.value)}/>
           <button className=" addInciBtn"  onClick={ addIncident}>OPRET HÆNDELSE</button>
       </form>
      </section>
   <br />
      <div className="incidents-menu ">
        <div className="incidentLeftMenu">
          <p>Navn</p>
          <p>Nummer</p>
        </div>

        <div className="incidentRightMenu">
       
          <p>Oprettet</p>
          <p>Status</p>
        </div>
      </div>

      {incidentsList  &&

incidentsList.sort( (a,b) => a.No < b.No ? 1 : -1).map((item, index) => {
  return <Incidents item={item} key={index} />;

})}


<br /> <br /> 
    </div>
    </main>
  );
}

export default AdminIncidents;
